import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getRegionZone } from '@helpers/geo-location'
import { Divider, Stack } from '@mui/material'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import { useTest } from '@hooks/useTest'

//  Relationship Variation components
import ButtonAccordion from '../../../@rtg2022/components/molecules/PDP/RightRail/ButtonAccordion'
import SwatchAccordion from '../../../@rtg2022/components/molecules/PDP/RightRail/SwatchAccordion'
import ImageAccordion from '../../../@rtg2022/components/molecules/PDP/RightRail/ImageAccordion'

const Relationships = ({ sku, enhancedRelationships = {}, variations = {}, colors = [] }) => {
  const { region, zone } = getRegionZone()
  const { generateTestId } = useTest()
  //  Merge variations and enhancedRelationships
  const mergedVariations = useMemo(() => {
    const merged = { ...variations }
    if (!enhancedRelationships?.length) return merged
    Object.entries(enhancedRelationships?.[0]).forEach(([key, value]) => {
      merged[key] = value
    })
    return merged
  }, [enhancedRelationships, variations])

  const composedOptions = {
    height:
      mergedVariations.height?.length > 0
        ? mergedVariations.height.filter(item => item?.availability?.[region] ?? false)
        : [],
    'chair color':
      mergedVariations?.['chair color']?.length > 0
        ? mergedVariations?.['chair color']?.filter(item => item?.availability?.[region] ?? false)
        : [],
    'table type':
      mergedVariations?.['table type']?.length > 0
        ? mergedVariations?.['table type']?.filter(item => item.availability?.[region] ?? false)
        : [],
    'number of chairs':
      mergedVariations?.['number of chairs']?.length > 0
        ? mergedVariations?.['number of chairs']?.filter(item => item.availability?.[region] ?? false)
        : [],
    'side chair style':
      mergedVariations?.['side chair style']?.length > 0
        ? mergedVariations?.['side chair style']?.filter(item => item.availability?.[region] ?? false)
        : [],
    arrangement:
      mergedVariations.arrangement?.length > 0
        ? mergedVariations.arrangement.filter(item => item.availability?.[region] ?? false)
        : [],
    finish:
      mergedVariations.finish?.length > 0
        ? mergedVariations.finish
            .map(item => ({
              ...item,
              availability: item?.catalog_availability || item?.availability,
            }))
            .filter(item => item.availability?.[region] ?? false)
            .map(item => ({
              ...item,
              swatchImage: item.swatchImage || item.image,
              label: item.label || item.variation_value,
            }))
        : [],
    size:
      mergedVariations.size?.length > 0
        ? mergedVariations.size
            .map(item => ({
              ...item,
              availability: item?.catalog_availability || item?.availability,
            }))
            .filter(item => item.availability?.[region] ?? false)
            .map(item => ({
              ...item,
              label: item.label || item.variation_value,
            }))
        : [],
    // ensure that color has a swatchImage
    color:
      mergedVariations.color?.length > 0
        ? mergedVariations.color
            .map(item => ({
              ...item,
              availability: item?.catalog_availability || item?.availability,
            }))
            .filter(item => item.availability?.[region] ?? false)
            .map(item => ({
              ...item,
              swatchImage: item?.swatchImage || item?.image,
              label: item?.label || item?.variation_value,
            }))
        : [],
  }

  return (
    <>
      <Stack divider={<Divider />}>
        <Stack divider={<Divider />}>
          <Stack divider={<Divider />}>
            {/* If theres no color variations or relationships, we still show 1 color from the root product data, in this case we are grabbing from a prop useProduct hook */}
            {!composedOptions?.color?.length &&
              !composedOptions?.['chair color']?.length &&
              !composedOptions?.finish?.length &&
              colors?.length &&
              colors?.[0]?.source === 'Color:' && (
                <SwatchAccordion
                  title={colors?.[0]?.source}
                  subtitle={colors?.[0]?.title}
                  options={colors}
                  sku={sku}
                  LinkComponent={StrapiLink}
                  data-testid={generateTestId('ProductRelationship', colors?.[0]?.source)}
                />
              )}

            {composedOptions?.color?.length && !composedOptions?.['chair color']?.length && (
              <SwatchAccordion
                title="Color:"
                subtitle={composedOptions?.color?.find(item => item?.sku === sku)?.label}
                options={composedOptions?.color}
                sku={sku}
                LinkComponent={StrapiLink}
                data-testid={generateTestId('ProductRelationship', 'Color')}
              />
            )}
            {composedOptions?.finish?.length && (
              <SwatchAccordion
                title="Finish:"
                subtitle={composedOptions?.finish?.find(item => item?.sku === sku)?.label}
                options={composedOptions?.finish}
                sku={sku}
                LinkComponent={StrapiLink}
                data-testid={generateTestId('ProductRelationship', 'Finish')}
              />
            )}
            {composedOptions?.['table type']?.length > 1 && (
              <ImageAccordion
                title="Table:"
                subTitle={composedOptions?.['table type']?.find(item => item?.sku === sku)?.label}
                additionalTitle={`${composedOptions?.['table type']?.length} ${
                  composedOptions?.['table type']?.length > 1 ? 'Options' : 'Option'
                }`}
                options={composedOptions?.['table type']}
                sku={sku}
                LinkComponent={StrapiLink}
                additionalTextType="html"
                data-testid={generateTestId('ProductRelationship', 'TableType')}
              />
            )}
            {composedOptions?.['side chair style']?.length > 1 && (
              <ImageAccordion
                title="Chair Style:"
                subTitle={composedOptions?.['side chair style']?.find(item => item?.sku === sku)?.label}
                additionalTitle={`${composedOptions?.['side chair style']?.length} ${
                  composedOptions?.['side chair style']?.length > 1 ? 'Options' : 'Option'
                }`}
                options={composedOptions?.['side chair style']}
                sku={sku}
                LinkComponent={StrapiLink}
                data-testid={generateTestId('ProductRelationship', 'ChairStyle')}
                variant="chair-style"
              />
            )}
            {composedOptions?.['chair color']?.length && (
              <SwatchAccordion
                title="Chair Color:"
                subtitle={composedOptions?.['chair color']?.find(item => item?.sku === sku)?.label}
                options={composedOptions?.['chair color']}
                sku={sku}
                LinkComponent={StrapiLink}
                data-testid={generateTestId('ProductRelationship', 'ChairColor')}
              />
            )}
            {composedOptions?.['number of chairs']?.length > 1 && (
              <ButtonAccordion
                defaultExpanded
                options={composedOptions?.['number of chairs']}
                sku={sku}
                additionalTitle={`${composedOptions?.['number of chairs']?.length} ${
                  composedOptions?.['number of chairs']?.length > 1 ? 'Options' : 'Option'
                }`}
                title="Seating:"
                subTitle={composedOptions?.['number of chairs']?.find(item => item?.sku === sku)?.label}
                LinkComponent={StrapiLink}
                region={region}
                zone={zone}
                data-testid={generateTestId('ProductRelationship', 'NumberOfChairs')}
              />
            )}
            {composedOptions?.size?.length > 1 && (
              <ButtonAccordion
                options={composedOptions?.size}
                sku={sku}
                title="Size:"
                subTitle={composedOptions?.size?.find(item => item?.sku === sku)?.label}
                LinkComponent={StrapiLink}
                region={region}
                zone={zone}
                variant="size"
                data-testid={generateTestId('ProductRelationship', 'Size')}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

Relationships.propTypes = {
  sku: PropTypes.any,
  enhancedRelationships: PropTypes.any,
  variations: PropTypes.any,
  colors: PropTypes.any,
}

export default Relationships
