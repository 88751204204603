import React from 'react'
import { styled } from '@mui/material'

const Parent = styled('svg')({
  width: 20,
  height: 13,
  marginLeft: 10,
})

const Stroke = styled('path')({
  fill: 'none',
  stroke: '#000000',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeDasharray: '0, 0',
})

const Wheels = styled('path')({
  fill: 'none',
  stroke: '#000000',
  strokeWidth: 1.0143,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeDasharray: '0, 0',
})

const DeliveryTruckIcon = () => (
  <Parent>
    <g transform="translate(160)">
      <Stroke d="M-152.4 10.4h5.2m-8.1-9.9h9.2c.8 0 .7.6.6 1.1-.1.4-.5 2.1-1.3 5.3h3.6l1.4-.7 1.4.7v2.8c0 .5-.2.7-.7.7h-2.6m-14.4 0h2.3" />
      <Stroke d="M-151.7 6.1h-7.8m13.6-2.8h1.9l2.3 2.8m-8.6-2.8h-7.8" />
      <Wheels d="M-153.3 12c-.4.3-.9.5-1.4.5-1 0-1.5-.8-1.2-1.8.1-.5.4-.9.8-1.2.4-.3.9-.5 1.4-.5.9 0 1.5.8 1.2 1.8 0 .4-.3.8-.8 1.2zm8.6 0c-.4.3-.9.5-1.4.5-1 0-1.5-.8-1.2-1.8.1-.5.4-.9.8-1.2.4-.3.9-.5 1.4-.5.9 0 1.5.8 1.2 1.8-.1.4-.4.8-.8 1.2z" />
    </g>
  </Parent>
)

export default DeliveryTruckIcon
