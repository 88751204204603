// dependencies
import React, { useEffect, useMemo, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { string, any, array, bool, number, shape, func, object } from 'prop-types'
import { styled } from '@mui/material'
import { validateCouponFromLocalStorage, checkCoupon } from '@services/coupon'

// components
import StrapiBanner from '@templates/strapi-cms/content-types/Banner'

const PromoBannersQuery = graphql`
  query PromoBannersQuery {
    allStrapiPdpPromoBanner(filter: { strapi_id: { ne: 0 } }) {
      edges {
        node {
          __typename
          SKUs {
            strapi_json_value
          }
          RequireValidCoupon
          Default {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          SE {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          FL {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          TX {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          OOM {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          Categories {
            filters {
              category
              subcategory
            }
          }
          Collections {
            Filters
          }
          BannerVariant
        }
      }
    }
  }
`

/**
 * Filters and Maps PDP banners based on a given SKU
 * @param {string} productSku
 * @param {'SE' | 'FL' |'TX' | 'OOM'} region
 * @param {object} data
 * @returns {object[]}
 */
const getPromoBanners = (productSku, region, data) => {
  if (!data || !productSku) return []

  const result = []
  const edges = data?.allStrapiPdpPromoBanner?.edges || []
  for (let i = 0; i < edges.length; i++) {
    const { node } = edges[i]
    const regionBasedContent = region && node[region]
    const Banner = regionBasedContent?.Banner || node.Default?.Banner
    if (
      (Array.isArray(node.SKUs?.strapi_json_value) || node?.Categories?.length > 0 || node?.Collections?.length > 0) &&
      Banner
    ) {
      result.push({
        SKUs: node?.SKUs?.strapi_json_value,
        Banner,
        RequireValidCoupon: node?.RequireValidCoupon,
        Categories: node?.Categories?.length > 0 ? node.Categories : [],
        BannerVariant: node?.BannerVariant,
        Collections: node?.Collections?.length > 0 ? node.Collections : [],
      })
    }
  }
  return result
}

const StyledTopBannerContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'block',
  height: 'auto',
  margin: 0,
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    margin: '10px -15px 20px -15px',
  },
  '@media (min-width: 768px)': {
    paddingBottom: theme.spacing(1),
  },
}))

const StyledTopImageGalleryBannerContainer = styled('div')(({ theme }) => ({
  width: 'auto !important',
  display: 'block',
  height: 'auto',
  margin: '0 0 0 20px',
  '@media (min-width: 768px)': {
    paddingBottom: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    padding: '0px',
    margin: 0,
  },
}))

const StyledBottomImageGalleryBannerContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'block',
  height: 'auto',
  margin: 0,
  '@media (min-width: 768px)': {
    paddingBottom: theme.spacing(1),
    paddingLeft: '20px',
    width: 'auto',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0px',
    margin: '15px',
    width: 'auto',
  },
}))

function PDPBanner({ RequireValidCoupon, Banner, index }) {
  const [render, setRender] = useState(!RequireValidCoupon)

  useEffect(() => {
    if (RequireValidCoupon) {
      checkCoupon().then(() => {
        if (validateCouponFromLocalStorage()) {
          setRender(true)
        } else {
          setRender(false)
        }
      })
    }
  }, [RequireValidCoupon])

  if (render) {
    return <StrapiBanner contentIndex={index} data={{ Banner }} />
  }

  return null
}

const PDPPromoBanner = ({
  sku,
  className,
  region,
  productCategory,
  productSubcategory,
  bannerVariant,
  productCollection,
}) => {
  const [displayedBanner, setDisplayBanner] = useState({})
  const [hasBanner, setBanner] = useState(false)
  const data = useStaticQuery(PromoBannersQuery)
  const banners = useMemo(() => getPromoBanners(sku, region, data), [sku, data, region])

  useEffect(() => {
    const displayedSKU = banners.find(
      skubanner =>
        ((!skubanner?.BannerVariant && bannerVariant === 'TopBanner') || skubanner?.BannerVariant === bannerVariant) &&
        skubanner?.SKUs.includes(sku),
    )
    if (displayedSKU) {
      setBanner(true)
      setDisplayBanner(displayedSKU)
    } else {
      banners.forEach(banner => {
        if (
          ((!banner?.BannerVariant && bannerVariant === 'TopBanner') || banner?.BannerVariant === bannerVariant) &&
          !hasBanner
        ) {
          const hasCollection = banner?.Collections.find(collection => collection.Filters === productCollection)
          if (bannerVariant === 'BottomImageGalleryBanner' && hasCollection) {
            setBanner(true)
            setDisplayBanner(banner)
          } else {
            const categoryBanner = banner?.Categories?.find(
              category =>
                category?.filters?.category === productCategory &&
                (!category?.filters?.subcategory || category?.filters?.subcategory === productSubcategory),
            )
            if (categoryBanner?.filters?.category) {
              setBanner(true)
              setDisplayBanner(banner)
            }
          }
        }
      })
    }
  }, [banners, sku, bannerVariant, hasBanner, productCategory, productSubcategory, productCollection])

  if (hasBanner) {
    switch (bannerVariant) {
      case 'TopImageGalleryBanner':
        return (
          <StyledTopImageGalleryBannerContainer className={className}>
            <PDPBanner
              {...displayedBanner}
              key={displayedBanner?.Banner?.id}
              sku={sku}
              pdpCategory={productCategory}
              pdpSubcategory={productSubcategory}
            />
          </StyledTopImageGalleryBannerContainer>
        )
      case 'BottomImageGalleryBanner':
        return (
          <StyledBottomImageGalleryBannerContainer className={className}>
            <PDPBanner
              {...displayedBanner}
              key={displayedBanner?.Banner?.id}
              sku={sku}
              pdpCategory={productCategory}
              pdpSubcategory={productSubcategory}
            />
          </StyledBottomImageGalleryBannerContainer>
        )
      default:
        return (
          <StyledTopBannerContainer className={className}>
            <PDPBanner
              {...displayedBanner}
              key={displayedBanner?.Banner?.id}
              sku={sku}
              pdpCategory={productCategory}
              pdpSubcategory={productSubcategory}
            />
          </StyledTopBannerContainer>
        )
    }
  }

  return null
}

export const TopImageGalleryPDPPromoBanner = ({ sku, className, region, productCategory, productSubcategory }) => (
  <PDPPromoBanner
    sku={sku}
    className={className}
    region={region}
    productCategory={productCategory}
    productSubcategory={productSubcategory}
    bannerVariant="TopImageGalleryBanner"
  />
)

export const BottomImageGalleryPDPPromoBanner = ({
  sku,
  className,
  region,
  productCategory,
  productSubcategory,
  productCollection,
}) => (
  <PDPPromoBanner
    sku={sku}
    className={className}
    region={region}
    productCategory={productCategory}
    productSubcategory={productSubcategory}
    bannerVariant="BottomImageGalleryBanner"
    productCollection={productCollection || 'NoCollection'}
  />
)

const TopPDPPromoBanner = ({ sku, className, region, productCategory, productSubcategory }) => (
  <PDPPromoBanner
    sku={sku}
    className={className}
    region={region}
    productCategory={productCategory}
    productSubcategory={productSubcategory}
    bannerVariant="TopBanner"
  />
)

PDPBanner.propTypes = {
  RequireValidCoupon: bool,
  Banner: any,
  index: number,
}

PDPPromoBanner.propTypes = {
  sku: string,
  className: string,
  region: string,
  productCategory: string,
  productSubcategory: string,
  bannerVariant: string,
  productCollection: bool,
}

TopPDPPromoBanner.propTypes = {
  sku: string,
  className: string,
  region: string,
  productCategory: string,
  productSubcategory: string,
}

TopImageGalleryPDPPromoBanner.propTypes = {
  sku: string,
  className: string,
  region: string,
  productCategory: string,
  productSubcategory: string,
}

BottomImageGalleryPDPPromoBanner.propTypes = {
  sku: string,
  className: string,
  region: string,
  productCategory: string,
  productSubcategory: string,
  productCollection: string,
}

export default TopPDPPromoBanner
