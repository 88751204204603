import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { formatRelationshipsStrings } from '../../../../../lib/helpers/string-helper'
import Accordion from '../../../organisms/PDP/Accordion'
import BoxButton from '../../../atoms/BoxButton/BoxButton'

const productUrl = route => `/furniture/product${route}`

const ImageAccordion = ({
  title,
  subTitle,
  additionalTitle,
  options,
  sku,
  LinkComponent,
  additionalComponentStyles,
  additionalTextType,
  variant = 'arrangement',
}) => (
  <Accordion
    title={title}
    subTitle={options.length < 2 ? null : subTitle}
    additionalTitle={options.length < 2 ? subTitle : additionalTitle}
    disabled={options.length < 2}
    defaultExpanded={options.length >= 2}
    selectionCount={options.length}
  >
    <Grid container spacing={1}>
      {options.map(option => (
        <Grid item xs={6} key={`image_accordion_option_${option.sku}`}>
          <LinkComponent
            data={{
              InternalUrl: productUrl(option?.route),
              id: `product-tile-link:${sku}`,
              Title: option?.label,
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              ...(option.sku === sku && { pointerEvents: 'none' }),
            }}
          >
            <BoxButton
              label={formatRelationshipsStrings(option.label, variant === 'chair-style')}
              selected={option.sku === sku}
              imageSrc={option.icon_image}
              variant={variant}
              additionalText={
                option?.width && !option?.dimensions?.includes('extends')
                  ? `${option?.length} x ${option?.width} ${option?.height && 'x'} ${option?.height}`
                  : option?.dimensions?.split(',').reduce((agg, line) => `${agg}<p>${line?.trim()}</p>`, '')
              }
              additionalTextType={additionalTextType || 'string'}
              additionalComponentStyles={additionalComponentStyles}
            />
          </LinkComponent>
        </Grid>
      ))}
    </Grid>
  </Accordion>
)

ImageAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  additionalTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon_image: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    }),
  ).isRequired,
  sku: PropTypes.string.isRequired,
  LinkComponent: PropTypes.elementType.isRequired,
  additionalComponentStyles: PropTypes.object,
  additionalTextType: PropTypes.string,
  variant: PropTypes.oneOf(['arrangement', 'card-horizontal', 'card-vertical']),
}

export default ImageAccordion
