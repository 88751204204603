import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, FormControl, FormControlLabel, Typography, styled, css } from '@mui/material'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { useTest } from '@hooks/useTest'
import PriceAtom from '../../atoms/Price/Price'
import Button from '../../atoms/Button/Button'

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.dark};
    font-weight: 500;
    & > span {
      font-weight: 600;
      margin-right: 8px;
    }
    .price-atom {
      margin: 0;
    }
  `,
)

const StyledButton = styled(Button)`
  font-weight: 400;
`

const StyledFormControlLabel = styled(FormControlLabel)({
  alignItems: 'flex-start',
  '.MuiCheckbox-root': {
    paddingTop: '2px',
  },
})

function CheckboxFieldQuestion({ label, checked, extraLabel, additionalLink, onChange }) {
  const { generateTestId } = useTest()
  return (
    <FormControl data-testid={generateTestId('protectionplan', 'checkbox')}>
      <StyledFormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={event => {
              addToDataLayer_nextgen(`protection_plan_${event.target.checked ? 'select' : 'deselect'}`)
              if (typeof onChange === 'function') onChange(event.target.checked)
            }}
          />
        }
        label={
          <StyledTypography>
            {label} (<PriceAtom className="price-atom" price={extraLabel} />)
            {additionalLink && (
              <>
                {' '}
                <StyledButton
                  {...{
                    ...(additionalLink.onClick
                      ? { onClick: additionalLink.onClick }
                      : { href: additionalLink.path, target: '_blank' }),
                  }}
                  sx={{
                    textTransform: 'capitalize',
                    textDecoration: 'underline',
                    lineHeight: 1.5,
                    fontSize: '14px',
                    padding: 0,
                    alignSelf: 'baseline',
                  }}
                  variant="text"
                  label={additionalLink.title}
                  gtm-category="pdp"
                  gtm-action="link click"
                  gtm-label={`Link - ${additionalLink.title}`}
                  role="button"
                  aria-disabled="false"
                  data-testid={generateTestId('checkboxfield', 'link')}
                />
              </>
            )}
          </StyledTypography>
        }
      />
    </FormControl>
  )
}

export const CheckboxFieldQuestionTypes = {
  label: PropTypes.string.isRequired,
  extraLabel: PropTypes.string,
  additionalLink: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    onClick: PropTypes.func,
  }),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}

CheckboxFieldQuestion.propTypes = CheckboxFieldQuestionTypes

export default CheckboxFieldQuestion
