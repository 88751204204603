import React, { useState, useEffect } from 'react'
import { arrayOf, func } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { currencyFormatUS } from '@helpers/string-helper'
import InfoModal from '@shared/info-modal'
import { graphql, StaticQuery } from 'gatsby'
import StrapiHtml from '@templates/strapi-cms/content-types/HTML'
import styled from 'styled-components'
import { getRequiredAddon } from '@helpers/product'
import { AddonModelPropType } from '@models/api/products/addon-model'
import PriceAtom from '../../../@rtg2022/components/atoms/Price/Price'

const RequiredAddonsContainer = styled(Grid)`
  margin: 0;
  padding: 0;
`
const StyledSpan = styled.span`
  color: #333333;
  font-size: 1rem;
  font-weight: 600;
`
const Addon = styled.div`
  align-items: center;
  color: #333;
  display: flex;
  margin-bottom: 15px;
`
const RedPrice = styled.span`
  color: #e31c3d;
  font-weight: 600;
`
const WrapperSpan = styled.span`
  font-size: 1rem;
  line-height: 1rem;
  @media only screen and (max-width: 639px) {
    font-size: 14px !important;
  }
`
const BunkieSpan = styled.span`
  color: #0053a0;
  display: block;
  margin-left: 5px;
  text-decoration: underline;
  font-size: 1rem;
  line-height: 1rem;
  @media only screen and (max-width: 639px) {
    font-size: 14px !important;
  }
`

const RequiredAddons = props => {
  const { requiredAddons = [], userSelectedAddons = [], setRequiredAddonsSelected, setUserSelectedAddons } = props
  const formattedAddon = getRequiredAddon(requiredAddons)
  const [isAddonSelected, setIsAddonSelected] = useState(false)
  const [shouldShowModal, setShouldShowModal] = useState(false)
  const radioName = requiredAddons?.title?.replace(/ /g, '')

  const handleAccept = () => {
    setRequiredAddonsSelected(requiredAddons)
    setUserSelectedAddons([...userSelectedAddons, ...requiredAddons])
    setIsAddonSelected(true)
  }

  const handleDecline = () => {
    setRequiredAddonsSelected([])
    setUserSelectedAddons(userSelectedAddons.filter(addon => !addon?.addon_required))
    setIsAddonSelected(false)
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleAccept()
  }, [])

  return (
    <RequiredAddonsContainer container id="required_addons">
      <Grid container item>
        {formattedAddon?.title && (
          <Grid container item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <StyledSpan>Items required for proper assembly:</StyledSpan>
            </Grid>
            <Grid container item xs={12}>
              <fieldset>
                <legend className="hide508">{`Please accept or decline "${formattedAddon.title}"`}</legend>
                {/* ADD Bunkie Board -- Selected by Default */}
                <Addon>
                  <input
                    required="required"
                    type="radio"
                    name={`required addon ${formattedAddon.title}`}
                    id={radioName}
                    checked={isAddonSelected}
                    onChange={handleAccept}
                    style={{ minWidth: 20, minHeight: 20, marginLeft: 0 }}
                  />
                  <label data-testid="addBunkieBoard" htmlFor={radioName} style={{ marginLeft: '13px' }}>
                    <WrapperSpan>
                      <span dangerouslySetInnerHTML={{ __html: formattedAddon.title }} />
                      {' - '}
                      <RedPrice>
                        <PriceAtom price={currencyFormatUS(formattedAddon.price)} />
                      </RedPrice>
                      {formattedAddon?.title?.indexOf('Bunkie') > -1 && (
                        <button
                          aria-describedby={radioName}
                          gtm-category="bunkie-board-modal"
                          gtm-action="click"
                          gtm-label="cart"
                          onClick={() => setShouldShowModal(true)}
                          type="button"
                        >
                          <BunkieSpan>What is a bunkie board?</BunkieSpan>
                        </button>
                      )}
                    </WrapperSpan>
                  </label>
                </Addon>
                {/* DECLINE Bunkie Board */}
                <Addon>
                  <input
                    required="required"
                    type="radio"
                    name={radioName}
                    id={`Decline${radioName}`}
                    checked={isAddonSelected === false}
                    onChange={handleDecline}
                    style={{ minWidth: 20, minHeight: 20, marginLeft: 0 }}
                  />
                  <label
                    data-testid="declineBunkieBoard"
                    htmlFor={`Decline${radioName}`}
                    style={{ marginLeft: '13px' }}
                  >
                    <WrapperSpan>No, I decline {formattedAddon.decline}</WrapperSpan>
                  </label>
                </Addon>
              </fieldset>
            </Grid>
          </Grid>
        )}
      </Grid>
      {shouldShowModal && (
        <InfoModal
          label="What is a Bunkie Board?"
          mdlClass="rtg-bunkie-board-modal"
          shouldShowModal={shouldShowModal}
          closeModal={() => setShouldShowModal(false)}
        >
          <StaticQuery
            query={graphql`
              query BunkieBoardModalInfo {
                strapiHtml(Title: { eq: "HTML - Bunkie Board Modal" }) {
                  ...StrapiHtmlFragment
                }
              }
            `}
            render={data => <StrapiHtml data={data.strapiHtml} />}
          />
        </InfoModal>
      )}
    </RequiredAddonsContainer>
  )
}

RequiredAddons.propTypes = {
  requiredAddons: arrayOf(AddonModelPropType),
  userSelectedAddons: arrayOf(AddonModelPropType),
  setRequiredAddonsSelected: func,
  setUserSelectedAddons: func,
}

export default RequiredAddons
