// dependencies
import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { styled, Grid, Paper, Hidden, Box, Divider } from '@mui/material'
import elementResizeDetectorMaker from 'element-resize-detector'

import { SlideTypeEnum, SmartSwiper } from '@rtgdev/design-system'
// helpers
import { getRequiredAddon, getProductImages } from '@helpers/product'
import { analyticsProduct } from '@helpers/google-tag-manager'
import { useUpdateFavorites, useIsFavorited } from '@hooks/auth'
import { currencyFormatUS } from '@helpers/string-helper'

// components
import Breadcrumb from '@templates/strapi-cms/content-types/Breadcrumbs'
import TopPDPPromoBanner, {
  TopImageGalleryPDPPromoBanner,
  BottomImageGalleryPDPPromoBanner,
} from '@templates/strapi-cms/content-types/PDPPromoBanner'
import SaleFlag from '@shared/sale-flag'
import NoImage from '@shared/no-image'
import ProductLIAStoreInfo from './product-lia-store-info'
import DesktopTitle from '../../../@rtg2022/components/organisms/PDP/DesktopTitle'
import TitleBar from '../../../@rtg2022/components/molecules/PDP/TitleBar/TitleBar'
import RightRail from '../ProductDetail/RightRail'
import { useProduct, FETCH_PROPERTIES } from '../hooks'
import { useCart } from '../../cart/hooks'

import DescriptionMattressTab from '../ProductDetail/DescriptionMattressTab'
import DescriptionTab from '../ProductDetail/DescriptionTab'
import ProductInfo from '../product-info'
import ShopTheRoom from '../views/ShopTheRoom'
import RequiredAddons from '../views/RequiredAddons'
import ProductJsonLd from '../product-json-ld'

import '../css/shop-the-room.sass'

const SaleFlagWrapper = styled(Box)(({ theme }) => ({
  '& > span > div': {
    top: 16,
    left: 16,
  },
  [theme.breakpoints.down('sm')]: {
    '& > span > div': {
      padding: '4px 8px',

      p: {
        fontSize: '12px',
      },
    },
  },
}))

const SmartSwiperWrapper = styled(SmartSwiper)(({ theme }) => ({
  '& .swiper-zoom-container': {
    height: 'calc(100vw / 3.8)',
    justifyContent: 'center',
    maxHeight: '600px',
  },
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '250px',
    '& .swiper-zoom-container': {
      height: 'calc(100vw / 1.8)',
    },
  },
}))

const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: '0 !important',
  position: 'relative',

  '.swiper-container-thumbs': {
    height: '100% !important',
    marginLeft: '20px',
  },

  '.swiper-container': {
    'button.swiper-button-prev': {
      left: '20px',
    },
    '.swiper-slide .swiper-zoom-container': {
      img: {
        position: 'static !important',
        padding: '12px 0px 0px 20px',

        [theme.breakpoints.down('sm')]: {
          padding: '30px 16px',
        },
      },
      video: {
        padding: '12px 0px 0px 20px',
      },
    },
  },
}))

const StyledLeftColumnContainer = styled(Grid)(({ theme, isLivingRoom, headerHeight = 0 }) => ({
  height: 'fit-content',
  padding: 0,
  zIndex: 1,
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    height: 'fit-content',
    paddingBottom: '0',
    position: 'relative',
    display: 'list-item',
  },
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: isLivingRoom ? headerHeight + 75 : headerHeight + 5,
    gridColumn: 1,
    width: '60vw',
    maxWidth: '1200px',
    maxHeight: 'fit-content',
  },
  '@media (max-width: 768px)': {
    '& > div:nth-child(3)': {
      height: '100% !important',
    },
  },
  '.swiper-scrollbar': {
    background: 'transparent',
  },
}))

const StyledSmartSwipperWithLabelsWrapper = styled(Grid)(({ theme, isLivingRoom, headerHeight = 0 }) => ({
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    height: '100%',
    paddingBottom: '0',
    position: 'relative',
  },
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    gridColumn: 1,
    width: '60vw',
    maxWidth: '1200px',
  },
  '@media (max-width: 768px)': {
    '& > div:nth-of-type(3)': {
      height: 'calc(100vw / 1.9)',
    },
  },
}))

const StyledRightColumnContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    gridColumn: 2,
  },
  '@media (max-width: 768px)': {
    marginTop: 30,
  },
  '& > div': {
    padding: 15,
  },
}))

const StyledAddonsContainer = styled(Grid)(({ theme }) => ({
  padding: '0 15px',
  backgroundColor: theme.palette.common.white,
}))

const PaperWrapper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'grid',
  },
  '& > div': {
    padding: 0,
  },
}))

const StyledDesktopTitle = styled(DesktopTitle)(({ theme, headerHeight }) => ({
  padding: '0.6rem 0.6rem',
  backgroundColor: theme.palette.common.white,
  position: 'sticky',
  top: headerHeight,
}))

const StyledPDPPromoBanner = styled(TopPDPPromoBanner)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}))

const StyledMobileTitleBar = styled(TitleBar)({
  marginTop: 20,
  padding: '40px 15px 15px',
})

const getUpgradesWithAnalytics = ({ room_package_upgrades, sku }) => {
  const filteredUpgrades = room_package_upgrades?.filter(u => u.sku && u.title && u.pricing)
  const upgradesWithAnalytics = filteredUpgrades?.map((upgrade, index) => ({
    ...upgrade,
    analyticsProduct: analyticsProduct(upgrade, 1, index + 1),
  }))
  const selectedUpgrade = upgradesWithAnalytics.find(item => item?.sku === sku) || null
  const nonSelectedUpgrades = upgradesWithAnalytics.filter(item => item?.sku !== sku)
  return { upgradesWithAnalytics, selectedUpgrade, nonSelectedUpgrades }
}

const getProductMediaUrls = product => {
  const thumbnailVideo = `${product.video_thumbnail ||
    product?.primary_image_room ||
    (product?.primary_image ? `${product?.primary_image}&h=52px` : `${product?.alternate_images?.[0]}&h=52`)}`

  const productVideos =
    product?.video?.map(url => ({
      url,
      alt: product?.displayNamePDP || product.title,
      originalSrc: url,
      type: SlideTypeEnum.htmlvideo,
      thumbnail: thumbnailVideo,
    })) || []

  const productAssets =
    getProductImages(product)?.reduce((assets, url) => {
      const slide = {
        url: url?.includes?.('youtube') || url?.includes?.('video') ? url : `${url}&h=1190&w=1190`,
        alt: product?.displayNamePDP || product.title,
        // originalSrc would contain the original src/url of the image without controlling height and width
        originalSrc: url,
      }
      if (url?.includes?.('youtube')) {
        if (!productVideos?.length > 0) {
          assets.push({
            ...slide,
            type: SlideTypeEnum.youtube,
            thumbnail: thumbnailVideo,
          })
        }
      } else if (url?.includes?.('video')) {
        assets.push({
          ...slide,
          type: SlideTypeEnum.htmlvideo,
          thumbnail: thumbnailVideo,
        })
      } else {
        assets.push({
          ...slide,
          type: SlideTypeEnum.image,
          thumbnail: `${url}&h=52`,
        })
      }
      return assets
    }, []) || []

  return [...productAssets, ...productVideos]
}

/**
 * ProductDetail controller
 * -  Renders the PDP presentational component (refactored)
 * -  Documents business and implementation logic for all PDP components?
 * @param {*} props
 */
const ProductLIAPDP = ({
  product,
  items_in_room,
  promotions,
  availabilityDate,
  room_package_upgrades = [],
  see_in_room,
  foundationsMattressBanner,
  store,
  region,
  zone,
  available,
}) => {
  const addons = product?.addon_items || []
  const availableAddons = addons?.filter(addon => !!addon.catalog_availability[region])
  const requiredAddons = availableAddons?.filter(addon => addon.addon_required)
  const formattedAddon = getRequiredAddon(requiredAddons)
  const [quantity, setQuantity] = useState(1)
  const [warrantyEnabled, setWarrantyEnabled] = useState(false)
  const [showWarrantyModal, setShowWarrantyModal] = useState(false)
  const [userSelectedAddons, setUserSelectedAddons] = useState([])
  const [requiredAddonsSelected, setRequiredAddonsSelected] = useState([])
  const [headerHeight, setHeaderHeight] = useState(0)
  const [isCategoriesPromoBannerAvailable, setCategoriesPromoBannerAvailable] = useState(false)
  const productMediaUrls = getProductMediaUrls(product)
  const { selectedUpgrade, nonSelectedUpgrades } = getUpgradesWithAnalytics({
    room_package_upgrades,
    sku: product.sku,
  })

  useEffect(() => {
    const resizeListener = elementResizeDetectorMaker({
      strategy: 'scroll', // <- For ultra performance.
    })
    if (typeof document !== 'undefined' && document.getElementsByClassName('desktop-only')?.[0]) {
      resizeListener.listenTo(document.getElementsByClassName('desktop-only')?.[0], element => {
        setHeaderHeight(element?.offsetHeight)
      })
    }
  })

  //  *************  Favorites Logic *****************************************************************
  const isFavorited = useIsFavorited(product.sku)
  const handleFavoriteClick = useUpdateFavorites(product)

  const getDimensionImage = () => {
    if (!foundationSelection?.dimension_image) return [product?.dimension_image]
    if (product?.dimension_image) return [product?.dimension_image, foundationSelection?.dimension_image]
    return []
  }

  //  *************  Mattress PDP Logic *****************************************************************
  const [foundationSelection, setFoundationSelection] = useState({})
  const {
    isMattressWithFoundations,
    isLivingRoom,
    price,
    stockMessage,
    availability,
    sale,
    isStrikeThrough,
    priceStrikeThrough,
    routeToSimilarItems,
    category,
    subCategory,
  } = useProduct(
    {
      product,
    },
    [FETCH_PROPERTIES.AVAILABILITY_STOCKMESSAGE],
  )
  const { renderAddToCartModal, onAddToCart } = useCart({
    quantity,
    availability,
    product,
    price,
    componentPage: 'pdp',
    stockMessage,
    warrantyEnabled,
    showLocation: false,
    moreInfoButton: false,
    region,
    source: 'product-lia-pdp',
    index: 0,
  })

  const onAddToCartActions = useMemo(() => {
    if (!availability)
      return {
        action: 'SHOP SIMILAR PRODUCTS',
        onClick: routeToSimilarItems,
      }
    return {
      action: 'ADD TO CART',
      onClick: onAddToCart,
    }
  }, [availability, onAddToCart, routeToSimilarItems])

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={7} lg={8}>
          <Breadcrumb
            productBreadcrumbs={product.breadCrumbs}
            productTitle={product?.displayNamePDP || product.title}
            isPDP
          />
        </Grid>
        <Grid container item xs={12} sm={6} md={5} lg={4} justifyContent="flex-end">
          <ShopTheRoom product={product} single_item_room={product.single_item_room} see_in_room={see_in_room} />
        </Grid>
      </Grid>
      {/* TODO: Sticky Header */}
      {isLivingRoom && (
        <Hidden mdDown>
          {renderAddToCartModal()}
          <StyledDesktopTitle
            availability={availability}
            headerHeight={headerHeight}
            title={product?.displayNamePDP || product?.title}
            price={currencyFormatUS(price)}
            isFavorited={isFavorited}
            addToCartLabel={onAddToCartActions.action}
            addToCartClick={onAddToCartActions.onClick}
            sale={sale}
            strikethrough={isStrikeThrough}
            strikeThroughPrice={priceStrikeThrough}
            handleFavoriteClick={handleFavoriteClick}
          />
        </Hidden>
      )}
      <StyledPDPPromoBanner
        sku={product.sku}
        region={region}
        productCategory={category}
        productSubcategory={subCategory}
      />
      <BoxWrapper>
        <PaperWrapper square elevation={0} wrap="nowrap">
          <StyledLeftColumnContainer isLivingRoom={isLivingRoom} headerHeight={headerHeight}>
            <StyledSmartSwipperWithLabelsWrapper headerHeight={headerHeight}>
              <TopImageGalleryPDPPromoBanner
                sku={product.sku}
                region={region}
                productCategory={category}
                productSubcategory={subCategory}
              />
              <SaleFlagWrapper>
                <SaleFlag product={product} availabilityDate={availabilityDate} />
              </SaleFlagWrapper>
              {productMediaUrls.length > 0 ? (
                <SmartSwiperWrapper>
                  {productMediaUrls.map(el => (
                    <SmartSwiper.Slide
                      key={el.url}
                      type={el.type}
                      url={el.url}
                      alt={el.alt}
                      thumbnail={el.thumbnail}
                      originalSrc={el.originalSrc}
                    />
                  ))}
                </SmartSwiperWrapper>
              ) : (
                <NoImage />
              )}
              <BottomImageGalleryPDPPromoBanner
                sku={product?.sku}
                region={region}
                productCategory={category}
                productSubcategory={subCategory}
                productCollection={product?.collection}
              />
            </StyledSmartSwipperWithLabelsWrapper>
          </StyledLeftColumnContainer>
          <StyledRightColumnContainer>
            <Hidden mdUp>
              <StyledMobileTitleBar
                title={product.displayNamePDP || product?.title}
                price={currencyFormatUS(price)}
                strikeThrough={isStrikeThrough}
                strikeThroughPrice={currencyFormatUS(priceStrikeThrough)}
                sale={sale}
                availability={availability}
              />
              <Divider />
            </Hidden>
            <ProductLIAStoreInfo product={product} store={store} region={region} zone={zone} available={availability} />
            <Divider />
            {isLivingRoom ? (
              <RightRail
                availability={availability}
                stockMessage={stockMessage}
                product={product}
                quantity={quantity}
                setQuantity={setQuantity}
                warrantyEnabled={warrantyEnabled}
                setWarrantyEnabled={setWarrantyEnabled}
                requiredAddonsSelected={requiredAddonsSelected}
                showWarrantyModal={showWarrantyModal}
                setShowWarrantyModal={setShowWarrantyModal}
                roomSavings={selectedUpgrade?.room_savings}
                hideTitleBar
                setUserSelectedAddons={setUserSelectedAddons}
                userSelectedAddons={userSelectedAddons}
                foundationSelection={foundationSelection}
                handleFavoriteClick={handleFavoriteClick}
                isFavorited={isFavorited}
              />
            ) : (
              <ProductInfo
                fullWidth
                viewType="list"
                data={product}
                items_in_room={items_in_room}
                promotions={promotions}
                requiredAddonsSelected={requiredAddonsSelected}
                setUserSelectedAddons={setUserSelectedAddons}
                userSelectedAddons={userSelectedAddons}
                room_savings={selectedUpgrade?.room_savings}
                foundationsMattressBanner={foundationsMattressBanner}
                foundationSelection={foundationSelection}
                setFoundationSelection={setFoundationSelection}
              />
            )}
          </StyledRightColumnContainer>
        </PaperWrapper>
        {formattedAddon?.title && (
          <StyledAddonsContainer item container xs={12}>
            <RequiredAddons
              requiredAddons={requiredAddons}
              setRequiredAddonsSelected={setRequiredAddonsSelected}
              setUserSelectedAddons={setUserSelectedAddons}
              userSelectedAddons={userSelectedAddons}
            />
          </StyledAddonsContainer>
        )}
      </BoxWrapper>
      {/* => PRODUCT DESCRIPTION */}
      <Grid item p={2} sx={{ margin: '0 -0.9375em' }}>
        {isMattressWithFoundations ? (
          <DescriptionMattressTab
            description={product.description}
            dimensionInfo={{
              value: product.dimensions,
              image: getDimensionImage(),
            }}
          />
        ) : (
          <DescriptionTab
            assemblyInstructions={product.assembly_instructions}
            dimensionImage={getDimensionImage()}
            description={product.description}
            dimensions={product.dimensions}
            warrantyLink={product.warrantyLink}
            warrantyDescription={product.warranty_description}
            product={product}
          />
        )}
      </Grid>
      <ProductJsonLd product={product} />
    </>
  )
}

ProductLIAPDP.propTypes = {
  items_in_room: PropTypes.array,
  see_in_room: PropTypes.objectOf(PropTypes.array),
  product: PropTypes.shape({
    addon_items: PropTypes.array,
    alternate_images: PropTypes.array,
    brand: PropTypes.string,
    breadcrumb: PropTypes.shape({
      breadcrumb_label: PropTypes.string,
      breadcrumb_url: PropTypes.string,
    }),
    description: PropTypes.string,
    dimensions: PropTypes.string,
    dimension_image: PropTypes.string,
    selections: PropTypes.shape({
      foundation: PropTypes.array,
    }),
    breadCrumbs: PropTypes.any,
    isAvailable: PropTypes.bool,
    on_promotion: PropTypes.object,
    primary_image_item: PropTypes.string,
    primary_image_room: PropTypes.string,
    primary_image: PropTypes.string,
    promotions: PropTypes.objectOf(PropTypes.any),
    room_configurations: PropTypes.object,
    saleFlag: PropTypes.bool,
    see_in_room: PropTypes.objectOf(PropTypes.array),
    single_item_room: PropTypes.bool,
    sku: PropTypes.string,
    title: PropTypes.string,
    displayNamePDP: PropTypes.string,
    assembly_instructions: PropTypes.array,
    warrantyLink: PropTypes.string,
    warranty_description: PropTypes.string,
    collection: PropTypes.string,
  }),
  room_package_upgrades: PropTypes.arrayOf(PropTypes.any),
  availabilityDate: PropTypes.instanceOf(Date),
  promotions: PropTypes.any,
  foundationsMattressBanner: PropTypes.objectOf(PropTypes.any),
  store: PropTypes.any,
  region: PropTypes.any,
  zone: PropTypes.any,
  available: PropTypes.any,
}

export default ProductLIAPDP
