import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardMedia,
  CardContent,
  Button,
  Typography,
  Drawer,
  Radio,
  styled,
  CircularProgress,
  ClickAwayListener,
  Link,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { productOnSale, productAvailability } from '@helpers/product'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { productUrl } from '@helpers/route'
import storageConsoleImg from '../../../../assets/images/modular-one-storage-console.png'

const StyledCard = styled(Card)({
  display: 'flex',
  justifyContent: 'center',
})

const HeaderContainer = styled('div')({
  display: 'grid',
  gridAutoColumns: '3fr 1fr',
  gridAutoFlow: 'column',
})
const StyledCloseIcon = styled(CloseOutlinedIcon)({
  position: 'absolute',
  right: '5%',
  cursor: 'pointer',
})

const StyledCardMedia = styled(CardMedia)(({ loaded }) => ({
  width: loaded ? '100%' : 0,
  margin: loaded ? 'auto' : 0,
}))

const LoadingWrapper = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  [`@media only screen and (min-width: 1080px)`]: {
    minWidth: '22.5rem',
  },
  maxWidth: '22.5rem',
  height: 240,
  margin: 'auto',
})

const ValueGrid = styled('div')(({ options }) => ({
  [`@media only screen and (max-height: 760px)`]: {
    minHeight: '2.5rem',
  },
  minHeight: '3.5rem',
  display: 'grid',
  gridAutoColumns: options?.length > 1 ? '0.55fr' : '0.55fr 1.10fr',
  gridAutoFlow: 'column',
}))

const LabelContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginInlineEnd: 'auto',
})

const RowLabel = styled(Typography)({
  [`@media only screen and (max-height: 760px)`]: {
    fontSize: '12px',
  },
  textTransform: 'capitalize',
  fontSize: '14px',
  maxWidth: '',
  color: '#003566',
})

const ValueContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
})

const ButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
})

const DashIcon = styled(Typography)({
  color: '#333333',
  fontSize: '24px',
})

const SelectionButton = styled(Button)(({ selected }) => ({
  backgroundColor: selected ? '#0053A01F' : '#F6F6F6',
  color: '#003566',
  fontSize: '0.875rem',
  fontWeight: selected ? 600 : 500,
  whiteSpace: 'nowrap',
  textAlign: 'center',
  width: '90%',
}))

const ConfirmContainer = styled('div')({
  display: 'flex',
  width: '90%',
  padding: '1.25rem 0 1.25rem 0',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.9375rem',
  margin: 'auto',
})

const ConfirmButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#0053A0',
  color: 'white',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}))

const LearnMoreDrawer = ({ type, open, toggleDrawer, options, selectedProductSku }) => {
  const defaultOptionIndex = options.findIndex(op => op.sku === selectedProductSku)

  const optionLabels = options?.map(op => op.label)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [selectedOption, setSelectedOption] = useState(optionLabels[defaultOptionIndex])

  const rows = [
    {
      type: 'Console',
      rowLabels: [
        'customizable placement',
        'storage drawer',
        'tabletop',
        'built in bluetooth speakers',
        'wireless/USB charging station',
      ],
    },
    {
      type: 'Recliner',
      rowLabels: [
        'manual control',
        'power adjustable recliner',
        'power adjustable headrest',
        'power adjustable lumbar support',
      ],
    },
  ]

  const { rowLabels } = rows.find(row => row.type === type)

  const ConfirmSelectionLink = () => {
    let option = options?.find(op => op.label.includes(selectedOption))
    if (option === undefined) option = options?.find(op => op.label === 'power reclining')
    return (
      <Link
        key={option}
        onClick={() =>
          addToDataLayer_nextgen('ee_click', {
            ecommerce: {
              click: {
                products: [
                  {
                    ...option,
                    price: option?.pdp_price,
                    on_sale: productOnSale(option),
                    availability: productAvailability(option),
                  },
                ],
                list: 'movement',
              },
            },
            selection_group: 'movement',
            selection_clicked: option.label,
            current_selection: option.label,
            selection_count: options?.length,
          })
        }
        href={productUrl(option?.route)}
        style={{ width: '100%' }}
      >
        <ConfirmButton
          onClick={toggleDrawer(false)}
          sx={[
            {
              '&:hover': {
                boxShadow: 3,
              },
            },
          ]}
        >
          Confirm Selection
        </ConfirmButton>
      </Link>
    )
  }

  const toggleOption = label => {
    setSelectedOption(label)
  }

  const valueIcon = (label, value) => {
    const icon = {
      'non-power': () => (value.includes('manual') ? <CheckIcon color="success" /> : <DashIcon> - </DashIcon>),
      'single power': () => (value.includes('recliner') ? <CheckIcon color="success" /> : <DashIcon> - </DashIcon>),
      'dual power': () =>
        ['recliner', 'headrest'].some(el => value.includes(el)) ? (
          <CheckIcon color="success" />
        ) : (
          <DashIcon> - </DashIcon>
        ),
      'triple power': () =>
        ['recliner', 'headrest', 'lumbar'].some(el => value.includes(el)) ? (
          <CheckIcon color="success" />
        ) : (
          <DashIcon> - </DashIcon>
        ),
      'storage console': () =>
        ['customizable', 'storage', 'tabletop'].some(el => value.includes(el)) ? (
          <CheckIcon color="success" />
        ) : (
          <DashIcon> - </DashIcon>
        ),
      'media console': () =>
        ['customizable', 'storage', 'tabletop', 'bluetooth', 'wireless'].some(el => value.includes(el)) ? (
          <CheckIcon color="success" />
        ) : (
          <DashIcon> - </DashIcon>
        ),
      default: () => <DashIcon> - </DashIcon>,
    }
    return (icon[label] || icon.default)()
  }

  const toUrl = label => {
    switch (label) {
      case 'non-power':
        return `${process.env.GATSBY_S3_IMAGE_URL}video/manual-power-recliner.mp4`
      case 'single power':
        return `${process.env.GATSBY_S3_IMAGE_URL}video/single-power-recliner.mp4`
      case 'dual power':
        return `${process.env.GATSBY_S3_IMAGE_URL}video/dual-power-recliner.mp4`
      case 'triple power':
        return `${process.env.GATSBY_S3_IMAGE_URL}video/triple-power-recliner.mp4`
      case 'media console':
        return `${process.env.GATSBY_S3_IMAGE_URL}video/RTG_M3_FINAL_nologos_071323.mp4`
      case 'storage console':
        return storageConsoleImg
      default:
        return label
    }
  }

  const ReclinerVideo = ({ label }) => {
    const [hasLoaded, setHasLoaded] = useState(false)

    ReclinerVideo.propTypes = {
      label: PropTypes.string,
    }

    return (
      <LoadingWrapper>
        {!hasLoaded && <CircularProgress />}
        <StyledCardMedia
          loaded={hasLoaded}
          onLoadedData={() => setHasLoaded(true)} // Setloaded for video
          onLoad={() => setHasLoaded(true)} // Setloaded for image
          component={label === 'storage console' ? 'img' : 'video'}
          image={toUrl(label)}
          autoPlay
          alt={label}
          data-testid={type === 'Recliner' ? 'reclinerVideo' : 'consoleVideo'}
        />
      </LoadingWrapper>
    )
  }

  const handleClose = () => {
    toggleDrawer(false)
    toggleOption(optionLabels[defaultOptionIndex])
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleClose()
      }}
    >
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right" sx={{ zIndex: '1205 !important' }}>
        <StyledCard sx={{ minWidth: isMobile ? '100vw' : '100%', minHeight: isMobile ? '100vh' : '100%' }} square>
          <CardContent sx={{ boxSizing: 'border-box' }}>
            <HeaderContainer>
              <Typography
                component="div"
                color="primary.main"
                textTransform="uppercase"
                fontSize="23px"
                fontWeight={500}
                sx={{ paddingBottom: '0.625rem' }}
              >
                {type === 'Recliner' ? 'Reclining' : type} Type
              </Typography>
              <StyledCloseIcon sx={{ color: '#808080' }} onClick={toggleDrawer(false)} />
            </HeaderContainer>

            <ReclinerVideo label={selectedOption} />
            <ValueGrid options={options} sx={{ paddingTop: '15px' }}>
              {/* Spacing div for grid layout */}
              <div />
              {optionLabels.map(label => (
                <ButtonContainer key={label}>
                  <SelectionButton onClick={() => toggleOption(label)} selected={selectedOption === label}>
                    {/* eslint-disable-next-line react/prop-types */}
                    {label.replace('console', '')}
                  </SelectionButton>
                </ButtonContainer>
              ))}
            </ValueGrid>
            {rowLabels.map(rowLabel => (
              <ValueGrid options={options} key={rowLabel}>
                <LabelContainer>
                  <RowLabel> {rowLabel} </RowLabel>
                </LabelContainer>
                {optionLabels.map(label => (
                  <ValueContainer key={label}>{valueIcon(label, rowLabel)}</ValueContainer>
                ))}
              </ValueGrid>
            ))}
            {optionLabels.length > 1 && (
              <ValueGrid options={options}>
                <LabelContainer>
                  <RowLabel sx={{ fontWeight: 'bold' }}>{type} Selection</RowLabel>
                </LabelContainer>
                {optionLabels.map(label => (
                  <ButtonContainer key={label}>
                    <Radio checked={label === selectedOption} onClick={() => toggleOption(label)}>
                      {' '}
                    </Radio>
                  </ButtonContainer>
                ))}
              </ValueGrid>
            )}

            {optionLabels.length > 1 && (
              <ConfirmContainer
                sx={{
                  position: isMobile && 'absolute',
                  bottom: isMobile && '0',
                  left: isMobile && '0',
                  right: isMobile && '0',
                }}
              >
                <ConfirmSelectionLink />
              </ConfirmContainer>
            )}
          </CardContent>
        </StyledCard>
      </Drawer>
    </ClickAwayListener>
  )
}

LearnMoreDrawer.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  options: PropTypes.array,
  selectedProductSku: PropTypes.string,
}

export default LearnMoreDrawer
