import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Stack, useTheme, Typography, styled } from '@mui/material'
import { FavoriteBorderOutlined, Favorite } from '@mui/icons-material'
import ProductPrice from '../../../atoms/ProductPrice/ProductPrice'
import { HeartButton } from '../../../atoms/Button/Favorite'

const StyledHeading = styled(Typography)`
  font-size: 1.438rem;
  line-height: 1.563rem;
  font-weight: 600;
  margin: 0 0 0.8rem 0;
  color: #003566 !important; //  TODO why must I use important.... (commons.css was overriding)
`

const TitleBar = ({
  title,
  price,
  strikeThrough,
  strikeThroughPrice,
  sale,
  hasFavoriteButton,
  checked,
  handleClick,
  availability,
  roomSavings,
  className,
}) => {
  const { palette } = useTheme()

  return (
    <Stack direction="row" gap={2} alignItems="start" justifyContent="space-between" className={className}>
      <Stack
        direction="column"
        sx={{
          color: palette.primary.dark,
        }}
      >
        <StyledHeading dangerouslySetInnerHTML={{ __html: title }} data-testid="mobile-pdp-title-text" />
        <ProductPrice
          availability={availability}
          price={price}
          strikeThrough={strikeThrough}
          strikeThroughPrice={strikeThroughPrice}
          roomSavings={roomSavings}
          sale={sale}
          variant="body1"
        />
      </Stack>
      {hasFavoriteButton && (
        <HeartButton
          control={
            <Checkbox
              onChange={handleClick}
              checked={checked}
              icon={
                <div className="icon-default">
                  <FavoriteBorderOutlined />
                </div>
              }
              checkedIcon={
                <div className="icon liked">
                  <Favorite />
                  <div className="heartOne" />
                  <div className="heartTwo" />
                </div>
              }
              name="heartButton"
            />
          }
        />
      )}
    </Stack>
  )
}

TitleBar.displayName = 'TitleBar'
TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  strikeThrough: PropTypes.bool,
  strikeThroughPrice: PropTypes.number,
  sale: PropTypes.bool,
  hasFavoriteButton: PropTypes.bool,
  checked: PropTypes.bool,
  handleClick: PropTypes.func,
  availability: PropTypes.bool,
  roomSavings: PropTypes.any,
  className: PropTypes.string,
}

export default TitleBar
