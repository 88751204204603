import React from 'react'
import classNames from 'classnames'
import { AddonModelPropType } from '@models/api/products/addon-model'
import PropTypes, { objectOf, any, bool, array, number } from 'prop-types'
import { productAvailability, productPrice, productOnSale } from '@helpers/product'
import { productUrl } from '@helpers/route'
import { currencyFormatUS } from '@helpers/string-helper'
import { applePayEnabled, applePaySupported } from '@helpers/checkout/payment-section/apple-pay'
import ProductAddToCartSection from './product-add-to-cart-section'
import ProductPrice from '../../../@rtg2022/components/atoms/Price/ProductPrice'
// import ProductPrice from './product-price'
// import ProductFinancing from './product-financing'
import RTGLink from '../../shared/link'

const ProductTitlePricing = ({
  product,
  showImage,
  addons,
  requiredSelected,
  activeAddons,
  moreInfo,
  strikethrough,
  strikethroughPrice,
  showFinance,
  financeAmount,
  availability = true,
  room_savings,
}) => {
  let route = null
  if (product && product.route) route = product.route
  const enableApplePay = applePaySupported() && applePayEnabled()
  return (
    <div className="product-details-data grid-x grid-margin-x">
      <div
        className={classNames('product-variation-title cell grid-x', {
          'small-6 large-5': !moreInfo && !strikethrough && enableApplePay,
          'small-6 large-8': !moreInfo && !strikethrough && !enableApplePay,
          'small-12 large-7': moreInfo || (!moreInfo && strikethrough && !enableApplePay),
        })}
      >
        {showImage && (
          <div className="product-details-data-image cell small-2">
            <img src={`${product.primary_image}&w=150`} alt={product.title} />
          </div>
        )}
        <div className={`product-info cell small-${showImage ? '10' : '12'}`}>
          <div className="product-title" dangerouslySetInnerHTML={{ __html: product.title }} />
        </div>
      </div>
      <div
        className={classNames('product-pricing cell', {
          'small-6 large-7': !moreInfo && !strikethrough && enableApplePay,
          'small-6 large-4': !moreInfo && !strikethrough && !enableApplePay,
          'small-12 large-5': moreInfo || (!moreInfo && strikethrough && !enableApplePay),
        })}
      >
        <div className="grid-x cell small-12 list-price-container">
          <div
            className={classNames('product-price-financing cell grid-y', {
              'small-12 medium-12 large-7': !moreInfo,
              'small-12 medium-12 large-6': moreInfo,
            })}
          >
            <ProductPrice
              showFinancing={showFinance}
              price={currencyFormatUS(productPrice(product))}
              sale={productOnSale(product)}
              strikeThrough={strikethrough}
              strikethroughPrice={currencyFormatUS(strikethroughPrice)}
              availability={availability}
              financeAmount={currencyFormatUS(financeAmount)}
              room_savings={room_savings}
            />
          </div>
          <div
            className={classNames('product-list-availability cell grid-x', {
              'small-12 medium-12 large-5': !moreInfo,
              'small-12 medium-12 large-6': moreInfo,
            })}
          >
            {(moreInfo && (
              <div className="buy-now-popup more-info-btn">
                <RTGLink
                  data={{
                    slug: productUrl(route),
                    altDesc: product.title ? product.title : '',
                    text: 'More Info',
                  }}
                  className="buy-now"
                />
              </div>
            )) || (
              <>
                <ProductAddToCartSection
                  availability={productAvailability(product)}
                  product={product}
                  price={productPrice(product)}
                  hideShipping
                  addons={addons}
                  requiredSelected={requiredSelected}
                  activeAddons={activeAddons}
                  componentPage="pdp"
                  index={0}
                  source="pdp"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

ProductTitlePricing.propTypes = {
  product: objectOf(any),
  showImage: bool,
  addons: array,
  requiredSelected: PropTypes.arrayOf(AddonModelPropType),
  activeAddons: PropTypes.arrayOf(AddonModelPropType),
  moreInfo: bool,
  strikethrough: bool,
  strikethroughPrice: number,
  showFinance: bool,
  financeAmount: number,
  availability: bool,
  room_savings: number,
}

export default ProductTitlePricing
