import React from 'react'
import PropTypes from 'prop-types'
import { useTest } from '@hooks/useTest'
import { Typography, Stack, Badge, styled } from '@mui/material'

const RelativeBadge = styled(Badge)`
  position: relative;

  .MuiBadge-badge {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    transform: none;
  }
`

const StyledAdditionalTitle = styled(Typography, {
  shouldForwardProp: prop => !['hasOptions', 'isMovement'].includes(prop),
})(({ theme, isMovement, hasOptions }) => ({
  color: isMovement || hasOptions ? theme.palette.secondary.lighter : theme.palette.primary.main,
  textTransform: 'capitalize',
}))

function ProductOptionHeader({ badgeNumber, title, additionalTitle, badgeVisible = false, isMovement, hasOptions }) {
  const { generateTestId } = useTest()
  return (
    <Stack direction="row" spacing={1} alignItems="start">
      {badgeVisible && <RelativeBadge badgeContent={badgeNumber} color="primary" />}
      <Typography
        fontWeight={600}
        textTransform="uppercase"
        color="primary.dark"
        data-testid={generateTestId('ProductOptionHeader', title)}
      >
        {title}
      </Typography>
      {additionalTitle && (
        <StyledAdditionalTitle
          isMovement={isMovement}
          hasOptions={hasOptions}
          data-testid={generateTestId('ProductOptionSubtitle', title)}
        >
          {additionalTitle}
        </StyledAdditionalTitle>
      )}
    </Stack>
  )
}

export const ProductOptionHeaderTypes = {
  badgeNumber: PropTypes.number,
  title: PropTypes.string.isRequired,
  additionalTitle: PropTypes.string,
  badgeVisible: PropTypes.bool,
  isMovement: PropTypes.bool,
  hasOptions: PropTypes.bool,
}

ProductOptionHeader.propTypes = ProductOptionHeaderTypes

export default ProductOptionHeader
