import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Stack, Typography, Box, Link } from '@mui/material'
import Rating from '@mui/material/Rating'

const StyledRating = styled(Rating)(({ theme }) => ({
  width: '100%',
  paddingBottom: '25px',
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
  [theme.breakpoints.down('md')]: {
    '> span': {
      width: '20%',
      margin: '0 1px',
    },
  },
}))

const StyledBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '0 4px',
  position: 'relative',
  width: '100px',
  [theme.breakpoints.down('md')]: {
    margin: '0 1px',
    width: '100%',
  },
}))

const NumberCircle = styled(Typography)(({ theme }) => ({
  borderRadius: '50%',
  color: theme.palette.common.white,
  fontSize: '18px',
  fontWeight: 700,
  height: '30px',
  lineHeight: '30px',
  position: 'absolute',
  textAlign: 'center',
  width: '30px',
  [theme.breakpoints.down('md')]: {
    fontSize: '15px',
    height: '20px',
    lineHeight: '20px',
    width: '20px',
  },
}))

const labels = {
  feel: ['Extra Soft', 'Soft', 'Medium', 'Firm', 'Extra Firm'],
  generic: ['Basic', 'Good', 'Superior', 'Premier', 'Ultimate'],
}

const IconContainer = ({ props, variant, defaultValue, isMobile }) => {
  const { value } = props
  const category = labels[variant]
  const label = value - 1
  const selected = value === defaultValue
  return (
    <Stack alignItems="center">
      <StyledBar
        sx={{
          borderBottom: theme =>
            selected
              ? { xs: `13px solid ${theme.palette.primary.main}`, md: `23px solid ${theme.palette.primary.main}` }
              : '8px solid #AAAAAF',
          pt: () => (selected ? 0 : '15px'),
          mb: () => (selected ? 0 : 1),
          mt: () => ({
            xs: selected ? '30px' : '20px',
            md: '36px',
          }),
        }}
      >
        <NumberCircle
          sx={{
            backgroundColor: () => (selected ? 'common.black' : '#AAAAAF'),
            top: () => (selected ? { xs: '-22px', md: '-32px' } : { xs: '8px', md: '4px' }),
          }}
        >
          {value}
        </NumberCircle>
      </StyledBar>
      <Stack>
        {selected && (
          <Typography variant="body2" lineHeight="4px" mt={2} color="common.black" align="center">
            ▲
          </Typography>
        )}
        <Typography
          variant="body1"
          color="text.primary"
          // eslint-disable-next-line no-nested-ternary
          fontSize={isMobile ? '12px' : selected ? '18px' : '16px'}
          fontWeight={value !== defaultValue ? 400 : 600}
          maxWidth={isMobile ? 65 : 100}
          lineHeight={isMobile && 2.5}
          marginTop="10px"
        >
          {category[label]}
        </Typography>
      </Stack>
    </Stack>
  )
}

IconContainer.propTypes = {
  defaultValue: PropTypes.number,
  isMobile: PropTypes.bool,
  props: PropTypes.object,
  value: PropTypes.number.isRequired,
  variant: PropTypes.string,
}

export default function PRODUCT_RATING({ variant, route, default_value, label, isMobile }) {
  const desktopFontSize = '16px'
  return (
    <>
      <Stack direction="row" spacing={1} mx="4px" mt="25px">
        <Typography
          textTransform="uppercase"
          fontSize={isMobile ? '12px' : desktopFontSize}
          fontWeight={600}
          color="#003566"
        >
          {route ? (
            <Link href={route} sx={{ fontSize: { xs: '12px', md: desktopFontSize }, fontWeight: 600 }}>
              {variant === 'generic' ? label : variant}:
            </Link>
          ) : (
            `${variant === 'generic' ? label : variant}:`
          )}
        </Typography>
        <Typography fontSize={isMobile ? '12px' : desktopFontSize} fontWeight={600}>
          {labels[variant][default_value - 1]}
        </Typography>
      </Stack>
      <StyledRating
        readOnly
        name="highlight-selected-only"
        defaultValue={default_value}
        IconContainerComponent={value => (
          <IconContainer variant={variant} props={value} defaultValue={default_value} isMobile={isMobile} />
        )}
        getLabelText={() => labels[variant][default_value - 1]}
        highlightSelectedOnly
      />
    </>
  )
}

PRODUCT_RATING.propTypes = {
  default_value: PropTypes.number,
  isMobile: PropTypes.bool,
  label: PropTypes.string,
  route: PropTypes.string,
  variant: PropTypes.string,
}
