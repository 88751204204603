// dependencies
import React from 'react'
import { string, array } from 'prop-types'
import { Box, Typography, Stack, styled } from '@mui/material'
import { useTest } from '@hooks/useTest'

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 600,
  lineHeight: 1.5,
  fontSize: 16,
}))

const StyledValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 400,
  lineHeight: 1.5,
  fontSize: 16,
  marginLeft: '.4rem',
  textTransform: 'capitalize',
}))

const StyledSummaryItems = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.main}0D`,
  width: '100%',
  padding: '16px',
  borderRadius: '4px',
  '& > div': {
    marginBottom: '5px',
  },
}))

const DesktopSummaryItem = ({ label, value }) => {
  const { generateTestId } = useTest()
  if (!value) return null
  return (
    <Box
      display="flex"
      flexDirection="row"
      textTransform="capitalize"
      data-testid={generateTestId('summarySelection', label)}
    >
      <StyledLabel>{label}</StyledLabel>
      <StyledValue>{value}</StyledValue>
    </Box>
  )
}

DesktopSummaryItem.propTypes = {
  label: string,
  value: string,
}

const WARRANTY_LABEL = 'Furniture Protection Plan:'

export default function DesktopSummary({ summaryData, imagesData = [] }) {
  return (
    <Stack>
      {imagesData.length > 0 && (
        <Stack direction="column" gap={1} marginBottom="30px" padding="0 20px">
          {imagesData?.map(({ src, value }) => (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={value}>
              <img style={{ width: '52px', aspectRatio: '1/1', objectFit: 'contain' }} src={src} alt={value} />
              <Typography sx={{ color: '#003566', marginLeft: '15px', textTransform: 'capitalize' }}>
                {value}
              </Typography>
            </Box>
          ))}
        </Stack>
      )}
      <StyledSummaryItems>
        {summaryData?.map(({ label, value }) =>
          label !== WARRANTY_LABEL ? <DesktopSummaryItem key={label} label={label} value={value} /> : null,
        )}
        {/* If there is Warranty item display last */}
        {summaryData.find(i => i.label === WARRANTY_LABEL)?.label ? (
          <DesktopSummaryItem
            key={summaryData.find(i => i.label === WARRANTY_LABEL).label}
            label={summaryData.find(i => i.label === WARRANTY_LABEL).label}
            value={summaryData.find(i => i.label === WARRANTY_LABEL).value}
          />
        ) : null}
      </StyledSummaryItems>
    </Stack>
  )
}

DesktopSummary.propTypes = {
  summaryData: array,
  imagesData: array,
}
