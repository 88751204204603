import React from 'react'

import classNames from 'classnames'
import { any, arrayOf, bool, objectOf, instanceOf, oneOfType, string } from 'prop-types'

import { getRegionZone } from '@helpers/geo-location'
import { getRegionSkuList } from '@helpers/product'
import { analyticsProduct } from '@helpers/google-tag-manager'
import { productUrl } from '@helpers/route'
import { getURLParam } from '@helpers/general'
import usePageAnalytics from '@hooks/usePageAnalytics'

import ProductDetailView from './ProductDetailView'
import BelowTheFold from './BelowTheFold'
import ProductOpenGraph from '../product-open-graph'

const ProductDetail = ({
  product,
  room,
  see_in_room,
  bannerPlacementMiddle,
  financeBanners,
  bannerBySku,
  room_package_upgrades,
  isInStock,
  availabilityDate,
  foundationsMattressBanner,
}) => {
  const { region, zone } = getRegionZone()
  const financeRegion = region && region !== 'OOM' ? region : 'SE'
  const renderNewRightRailPDP = true
  const isMattress = () =>
    product?.category?.match('bedding') && product?.breadcrumb?.breadcrumb_label?.match('Mattress')
  const isItem = () => product?.type?.match('item') && !product?.room_configurations
  const isPLA =
    (getURLParam('web_campaign') && getURLParam('web_campaign').includes('true')) ||
    (getURLParam('utm_campaign') && getURLParam('utm_campaign').includes('PLA')) ||
    (getURLParam('utm_campaign') && getURLParam('utm_campaign').includes('PMax'))

  /* check if sub-category exists for Similar Items heading */
  let similarHeading = 'Items'
  if (typeof product !== 'undefined' && typeof product?.sub_category !== 'undefined') {
    similarHeading = product?.sub_category?.[0]
  }
  let promotions
  if (product && product.promotions) {
    promotions = product.promotions[`${region}_${zone}`] || product.promotions[`${region}_0`]
  }

  usePageAnalytics(
    {
      type: 'product',
      title: product?.title,
      path: productUrl(product?.route),
    },
    [
      {
        event: 'ee_detail',
        ecommerce: { detail: { products: [analyticsProduct(product)] } },
      },
    ],
  )

  if (!product) return null

  return (
    <div
      className={classNames('product-details', {
        room,
        gradient: isMattress(),
        isItem: isItem(),
      })}
    >
      <ProductOpenGraph product={product} />
      {/*       
      // Is this a new PDP Right Rail product?
      // If so, lets render a new right rail component, otherwise we render the legacy PDP

      //  TODO lets use context here instead of prop drilling product
*/}
      <ProductDetailView
        product={product}
        see_in_room={see_in_room}
        items_in_room={getRegionSkuList(product.items_in_room, region)}
        promotions={promotions}
        room_package_upgrades={room_package_upgrades}
        isInStock={isInStock}
        availabilityDate={availabilityDate}
        foundationsMattressBanner={foundationsMattressBanner}
        isPLA={isPLA}
      />
      <BelowTheFold
        financeBanners={financeBanners}
        financeRegion={financeRegion}
        bannerBySku={bannerBySku}
        product={product}
        see_in_room={see_in_room}
      />
    </div>
  )
}

ProductDetail.propTypes = {
  product: objectOf(any),
  room: bool,
  see_in_room: objectOf(any),
  bannerPlacementMiddle: objectOf(any),
  foundationsMattressBanner: objectOf(any),
  financeBanners: objectOf(any),
  bannerBySku: arrayOf(any),
  room_package_upgrades: arrayOf(any),
  availabilityDate: any,
  isInStock: bool,
}

export default ProductDetail
