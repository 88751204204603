import React, { useState } from 'react'
import { string, bool } from 'prop-types'
import { addToDataLayer } from '@helpers/google-tag-manager'
import LocationSvg from '@shared/svgs/locationSvg'
import Button from '@mui/material/Button'
import SeeInStoresModal from './see-in-stores-modal'
import '../../../assets/css/components/product/product-parts/see-in-stores.sass'

const SeeInStores = ({
  componentPage,
  customButtonText,
  lia,
  primary_image,
  sku,
  title,
  zip,
  className,
  showIcon = true,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const onSeeInStore = () => {
    setModalOpen(true)
    addToDataLayer('click', componentPage, 'see in stores', sku)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Button
        data-testid="seeInStoreButton"
        className={`${className} see-in-store-button grid-x`}
        onClick={() => onSeeInStore()}
        type="button"
      >
        <span className="small-12 location-icon-see-in-store">
          {showIcon && <LocationSvg className="icon location" />}
          {customButtonText || 'See In Store'}
        </span>
      </Button>
      {modalOpen && (
        <SeeInStoresModal
          modalOpen={modalOpen}
          closeModal={closeModal}
          zip={zip}
          lia={lia}
          sku={sku}
          title={title}
          primary_image={primary_image}
        />
      )}
    </>
  )
}

SeeInStores.propTypes = {
  componentPage: string,
  customButtonText: string,
  lia: bool,
  primary_image: string,
  title: string,
  sku: string,
  zip: string,
  className: string,
  showIcon: bool,
}

export default SeeInStores
