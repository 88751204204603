import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Link } from '@mui/material'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { productAvailability, productOnSale, productPrice } from '@helpers/product'
import { productUrl } from '../../../../lib/helpers/route'
import Accordion from './Accordion'
import Pill from '../../atoms/Pill/Pill'

function MaterialOptions({ badgeNumber, options = [], itemComponent, selectedProductSku, disabled, additionalTitle }) {
  const ItemComponent = itemComponent || Link
  return (
    <Accordion
      selectionGroup="material"
      disabled={disabled}
      defaultExpanded={options?.length > 1}
      badgeNumber={badgeNumber}
      title="Material:"
      additionalTitle={additionalTitle}
    >
      {options?.length > 1 ? (
        <Grid container columnGap={2} rowGap={1}>
          {options.map((option, index) => {
            const sku = option?.sku || option?._id
            const isSelected = sku === selectedProductSku
            return (
              <Grid item key={option}>
                <ItemComponent
                  onClick={() =>
                    addToDataLayer_nextgen('ee_click', {
                      ecommerce: {
                        click: {
                          products: [
                            {
                              ...option,
                              price: productPrice(option),
                              on_sale: productOnSale(option),
                              availability: productAvailability(option),
                            },
                          ],
                          list: 'material',
                          position: index,
                        },
                      },
                      selection_group: 'material',
                      selection_clicked: option.label,
                      current_selection: options.find(i => i.sku === selectedProductSku)?.label,
                      selection_count: options.length,
                    })
                  }
                  data={{
                    InternalUrl: productUrl(option?.route),
                    id: `product-tile-link:${sku}`,
                    Title: option?.label,
                  }}
                  style={{ ...(isSelected && { pointerEvents: 'none' }) }}
                >
                  <Pill label={option?.label} selected={isSelected} />
                </ItemComponent>
              </Grid>
            )
          })}
        </Grid>
      ) : null}
    </Accordion>
  )
}

MaterialOptions.propTypes = {
  badgeNumber: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  itemComponent: PropTypes.any,
  selectedProductSku: PropTypes.string,
  disabled: PropTypes.bool,
  additionalTitle: PropTypes.string,
}

export default MaterialOptions
