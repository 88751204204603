import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Accordion from '../../../organisms/PDP/Accordion'
import ColorSelection from '../ChooseProductColor'

const StyledAccordion = styled(Accordion)(({ theme }) => ({}))

// eslint-disable-next-line import/prefer-default-export
const SwatchAccordion = ({ title, subtitle, options, sku, LinkComponent, logError = e => console.warn(e) }) => {
  //  TODO: Remove this if we are not logging missing data
  if (options?.some?.(option => !option.swatchImage)) {
    logError('Error: Some options are missing | swatchImage prop')
  }

  return (
    <StyledAccordion title={title} additionalTitle={subtitle} subTitle={subtitle} defaultExpanded disabled>
      <ColorSelection
        colors={options?.map?.(item => ({ ...item, image: item.swatchImage || item.image }))}
        selectedProductSku={sku}
        itemComponent={options.length > 1 ? LinkComponent : null}
      />
    </StyledAccordion>
  )
}

SwatchAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      swatchImage: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    }),
  ).isRequired,
  sku: PropTypes.string.isRequired,
  LinkComponent: PropTypes.elementType,
  logError: PropTypes.func,
}

export default SwatchAccordion
