import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import usePageAnalytics from '@hooks/usePageAnalytics'
import { productUrl } from '@helpers/route'
import { analyticsProduct } from '@helpers/google-tag-manager'
import { getLocation } from '@services/location'
import { getSeeInStore } from '@services/product'
import { useProduct } from '@components/product/hooks'
import ProductLIA from './product-lia'
import ProductPDPLIA from './product-lia-pdp'

function useSeeInStore({ sku, zip, storeNumber }, options) {
  return useQuery(
    ['seeInStore', { sku, zip, storeNumber }],
    () => getSeeInStore(sku, zip).then(data => data.find(loc => loc.storeNumber === storeNumber.toString())),
    options,
  )
}

function useLocation({ zip }, options) {
  return useQuery(
    ['location', { zip }],
    () => getLocation(zip).then(data => ({ region: data.response.region, zone: data.response.price_zone })),
    options,
  )
}

export default function ProductLIAWrapperNew({ product, store }) {
  const { isLivingRoom } = useProduct({ product })
  const seeInStoreParams = {
    sku: product.sku,
    storeNumber: store.StoreNumber,
    zip: store.Zip,
  }
  const { data: locData, isLoading: isLocationLoading } = useLocation({ zip: store.Zip }, { enabled: !!store?.Zip })
  const { data: matchingStore } = useSeeInStore(
    {
      sku: product.sku,
      storeNumber: store.StoreNumber,
      zip: store.Zip,
    },
    {
      enabled: !isLocationLoading,
    },
  )

  usePageAnalytics(
    {
      type: 'product',
      title: product?.title,
      path: productUrl(product?.route),
    },
    [
      {
        event: 'ee_detail',
        ecommerce: { detail: { products: [analyticsProduct(product)] } },
      },
    ],
  )

  if (isLivingRoom) {
    return (
      <ProductPDPLIA
        product={product}
        store={store}
        region={locData?.region ?? 'FL'}
        zone={locData?.zone ?? '0'}
        available={!!matchingStore}
      />
    )
  }

  return (
    <ProductLIA
      product={product}
      store={store}
      region={locData?.region ?? 'FL'}
      zone={locData?.zone ?? '0'}
      available={!!matchingStore}
    />
  )
}

ProductLIAWrapperNew.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.any,
    zip: PropTypes.any,
    title: PropTypes.string,
    route: PropTypes.string,
  }),
  store: PropTypes.shape({
    StoreNumber: PropTypes.any,
    Zip: PropTypes.any,
  }),
}
