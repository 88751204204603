import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { string, object } from 'prop-types'
import ProductTileMiniWrapper from '@components/product/ProductTileMini/ProductTileMiniWrapper'
import BaseSwiper from '../../@rtg2022/components/organisms/BaseSwiper/BaseSwiper'
import HeadingAndSeeAllLink from '../../@rtg2022/components/molecules/HeadingAndSeeAllLink/HeadingAndSeeAllLink'

const RecentlyViewed = ({ data, sku }) => {
  const [recentlyViewedArray, setRecentlyViewedArray] = useState([])

  useEffect(() => {
    addToRecentlyViewed(sku ?? '000000')
  }, [sku])

  const addToRecentlyViewed = currentSKU => {
    if (currentSKU) {
      const recentlyViewedString = localStorage?.getItem('recentlyViewedV2') ?? ''
      const newArray = recentlyViewedString ? recentlyViewedString?.split(',') : []
      if (!newArray.includes(currentSKU)) {
        newArray?.unshift(currentSKU)
      }
      if (newArray.length > 13) {
        newArray.pop()
      }
      localStorage?.setItem('recentlyViewedV2', newArray)

      setRecentlyViewedArray(newArray.filter(s => s !== currentSKU && s !== '000000'))
    }
  }

  if (!data.ShowRecentlyViewed || recentlyViewedArray.length === 0) return null

  const slides = recentlyViewedArray.map((recentSku, index) => (
    <ProductTileMiniWrapper sku={recentSku} index={index} key={recentSku} source="recently viewed" />
  ))

  return (
    <Box sx={{ margin: '15px 0', padding: '15px 0' }}>
      <HeadingAndSeeAllLink title="Recently Viewed" />
      <BaseSwiper
        title="Recently Viewed"
        slides={slides}
        slidesPerViewDesktop="auto"
        slidesPerViewMobile="auto"
        spaceBetween={15}
        fadeScrollbar
        // fullWidthDesktop
        // fullWidthMobile
        freeModeDesktop
        freeModeMobile
        dataTestId="slider:recently-viewed"
        cssMode
      />
    </Box>
  )
}

RecentlyViewed.propTypes = {
  sku: string,
  data: object.isRequired,
}

export default RecentlyViewed
