import React from 'react'
import PropTypes from 'prop-types'

const LocationSvg = props => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="32"
      height="28"
      viewBox="0 0 37.34 48.77"
      role="presentation"
      aria-hidden="true"
    >
      <path d="M18.67,48.77a4.61,4.61,0,0,1-3.85-2.07L2.66,28.25A18.65,18.65,0,0,1,16.57.11h0A18.66,18.66,0,0,1,34.72,28.19L22.52,46.7A4.63,4.63,0,0,1,18.67,48.77Zm0-45.55a17.48,17.48,0,0,0-1.77.09h0A15.44,15.44,0,0,0,5.38,26.54L17.51,44.92a1.42,1.42,0,0,0,2.32,0L32,26.48a15.32,15.32,0,0,0,2.13-7.82A15.49,15.49,0,0,0,29,7.16,15.25,15.25,0,0,0,18.69,3.22Zm0,24a8.52,8.52,0,1,1,8.52-8.52A8.53,8.53,0,0,1,18.67,27.18Zm0-13.82a5.31,5.31,0,1,0,5.3,5.3A5.31,5.31,0,0,0,18.67,13.36Z" />
    </svg>
  )
}

LocationSvg.propTypes = {
  className: PropTypes.string,
}

export default LocationSvg
