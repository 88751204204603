import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Link, styled, Drawer, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import RTGLink from '@shared/link'
import { productUrl } from '@helpers/route'
import Button from '../../../atoms/Button/Button'
import PriceAtom from '../../../atoms/ProductPrice/PriceAtom'

const StyledMainTitle = styled('h3')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '500',
  textTransform: 'capitalize',
  margin: '20px 15px 10px 15px',
  color: theme.palette.primary.dark,
}))

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  fontSize: '18x',
  fontWeight: '400',
  position: 'absolute',
  right: '12px',
  top: '12px',
  color: theme.palette.secondary.dark,
  cursor: 'pointer',
}))

const StyledRoomPackage = styled(Stack)(({ theme }) => ({
  maxWidth: '390px',
  borderRadius: '4px',
  border: '1px solid rgba(0, 83, 160, 0.12)',
  padding: '10px',
  margin: '15px',
  marginBottom: '0px',
  button: {
    marginTop: '15px',
  },
  h4: {
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'capitalize',
    color: theme.palette.primary.dark,
  },
  h5: {
    fontSize: '11px',
    fontWeight: '400',
    textTransform: 'uppercase',
    marginTop: '5px',
    color: theme.palette.primary.dark,
  },
  '.RoomPackagePrice': {
    color: theme.palette.primary.dark,
    fontSize: '23px',
    marginRight: '10px',
    fontWeight: '600',
  },
}))

const StyledRoomItemImage = styled('div')(({ theme }) => ({
  height: '124px',
  backgroundColor: theme.palette.grey[100],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  img: {
    maxHeight: '72px',
  },
}))

const StyledRoomSavings = styled('div')(({ theme }) => ({
  fontSize: '10px',
  fontWeight: '400',
  lineHeight: 'normal',
  color: theme.palette.primary.dark,
  span: {
    fontSize: '12px',
    fontWeight: '500',
    color: theme.palette.error.main,
  },
}))

function RoomPackagesDrawer({ roomPackages, onClose, isOpen }) {
  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right" sx={{ zIndex: '1500 !important' }}>
      <StyledMainTitle>ROOM PACKAGE OPTIONS</StyledMainTitle>
      <StyledCloseIcon onClick={onClose} />
      {roomPackages?.map((roomPackage, index) => {
        const { title, sku, items, route, price, room_savings } = roomPackage
        return (
          <StyledRoomPackage direction="column" key={`room-option-${sku}`}>
            <RTGLink
              className="cell"
              data={{
                slug: productUrl(route),
                title: sku,
                category: 'product-upgrade',
                action: 'upgrade click',
                label: sku,
              }}
              noTabIndex
              trackingData={{
                event: 'ee_click',
                ecommerce: {
                  click: {
                    list: `upgrade_other_room_options`,
                    position: index + 1,
                    products: [roomPackage],
                  },
                },
              }}
            >
              <h4>{title}</h4>
              <Stack direction="row" alignItems="center" marginBottom="15px">
                <PriceAtom price={price} className="RoomPackagePrice" />
                {room_savings > 20 && (
                  <StyledRoomSavings>
                    <PriceAtom price={room_savings} /> Savings (Compared to items sold separately)
                  </StyledRoomSavings>
                )}
              </Stack>
              <Grid container spacing={1}>
                {items?.map(item => (
                  <Grid item xs={6} key={sku}>
                    <StyledRoomItemImage>
                      <img src={item?.image} alt={item?.label} />
                    </StyledRoomItemImage>
                    <h5>
                      {item?.label} {item?.quantity > 1 && `(${item.quantity})`}
                    </h5>
                  </Grid>
                ))}
              </Grid>
              <Button
                variant="contained"
                color="primary"
                label="VIEW ROOM DETAILS"
                fullWidth
                disableElevation
                disableRipple
              />
            </RTGLink>
          </StyledRoomPackage>
        )
      })}
    </Drawer>
  )
}

RoomPackagesDrawer.propTypes = {
  roomPackages: PropTypes.array,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default RoomPackagesDrawer
