import React from 'react'
import PropTypes from 'prop-types'
import { styled, Grid, Stack } from '@mui/material'
import ProductRating from '../../../@rtg2022/components/atoms/ProductRating/ProductRating'

const GridStyled = styled(Grid)(({ theme }) => ({
  fontSize: '16px',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    lineHeight: '12px',
    padding: '10px',
  },
  '&.uppercase': {
    color: theme.palette.primary.dark,
    fontWeight: '600',
  },
}))

const StyledDimensionImage = styled('img')`
  max-width: 50%;
`

function determineRatingNumber(rating) {
  return (
    ((rating === 'extra soft' || rating === 'basic') && 1) ||
    ((rating === 'soft' || rating === 'good') && 2) ||
    ((rating === 'medium' || rating === 'superior') && 3) ||
    ((rating === 'firm' || rating === 'premier') && 4) ||
    ((rating === 'extra firm' || rating === 'ultimate') && 5)
  )
}

const MattressDetails = ({
  size,
  dimensions,
  dimensionImage,
  comfort,
  temperature_management,
  isMobile,
  pressure_relief,
  support_level,
  technology,
  sleep_position,
}) => (
  <Grid container>
    <Grid xs={12} md={6} sx={{ padding: { xs: '0', md: '0 30px 30px' } }}>
      <Grid
        container
        sx={{
          '--Grid-borderWidth': '1px',
          border: 'var(--Grid-borderWidth) solid',
          borderBottom: 0,
          borderColor: 'divider',
          '& > div': {
            borderBottom: 'var(--Grid-borderWidth) solid',
            borderColor: 'divider',
            padding: '22px',
            '&.bluebg': {
              backgroundColor: 'rgba(0, 83, 160, 0.04)',
            },
          },
          '& > div:not(.uppercase)': {
            textTransform: 'capitalize',
          },
        }}
      >
        <GridStyled xs={6} className="uppercase">
          Size
        </GridStyled>
        <GridStyled xs={6}>{size}</GridStyled>
        <GridStyled xs={6} className="uppercase bluebg">
          Mattress Dimensions
        </GridStyled>
        <GridStyled xs={6} className="bluebg">
          {dimensions}
        </GridStyled>
        <GridStyled xs={6} className="uppercase">
          Technology
        </GridStyled>
        <GridStyled xs={6}>{technology}</GridStyled>
        <GridStyled xs={6} className="uppercase bluebg">
          Sleep Position
        </GridStyled>
        <GridStyled xs={6} className="bluebg">
          {sleep_position}
        </GridStyled>
      </Grid>
      <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ padding: { xs: '0 30px', md: '30px' } }}>
        {dimensionImage.length > 1 ? (
          <>
            {dimensionImage[0] && <StyledDimensionImage src={dimensionImage[0]} alt={dimensions} />}
            {dimensionImage[1] && <StyledDimensionImage src={dimensionImage[1]} alt={dimensions} />}
          </>
        ) : (
          dimensionImage[0] && <img src={dimensionImage[0]} alt={dimensions} />
        )}
      </Stack>
    </Grid>
    <Grid xs={12} md={6} sx={{ padding: { xs: '0', md: '0 30px 30px' } }}>
      {comfort && <ProductRating isMobile={isMobile} variant="feel" default_value={determineRatingNumber(comfort)} />}
      {temperature_management && (
        <ProductRating
          isMobile={isMobile}
          variant="generic"
          default_value={parseInt(temperature_management[0])}
          label="Temperature Management"
        />
      )}
      {pressure_relief && (
        <ProductRating
          isMobile={isMobile}
          variant="generic"
          default_value={parseInt(pressure_relief[0])}
          label="Pressure Relief"
        />
      )}
      {support_level && (
        <ProductRating
          isMobile={isMobile}
          variant="generic"
          default_value={parseInt(support_level[0])}
          label="Support Level"
        />
      )}
    </Grid>
  </Grid>
)

MattressDetails.propTypes = {
  size: PropTypes.number,
  dimensions: PropTypes.string,
  dimensionImage: PropTypes.object,
  comfort: PropTypes.string,
  temperature_management: PropTypes.string,
  isMobile: PropTypes.bool,
  pressure_relief: PropTypes.string,
  support_level: PropTypes.string,
  technology: PropTypes.string,
  sleep_position: PropTypes.string,
}

export default MattressDetails
