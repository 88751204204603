import React from 'react'
import PropTypes from 'prop-types'
import { alpha, Box, css, styled } from '@mui/material'
import PriceAtom from '../Price/Price'

const Wrapper = styled(Box, {
  shouldForwardProp: prop => !['hasImage', 'boldDescription'].includes(prop),
})(
  ({ theme, selected, variant, hasImage, boldDescription }) => css`
    border: 2px solid transparent;
    background-color: ${alpha(theme.palette.text.primary, 0.04)};
    color: ${theme.palette.primary.dark};
    height: 100%;
    overflow: hidden;
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      margin-top: 10px;
    }

    .BoxButton {
      &__imageSrc {
        aspect-ratio: 1;
        width: 100%;
        max-width: 7.5rem;
        object-fit: contain;
      }
      &__textWrapper {
        display: grid;
        gap: 0.25rem;
      }
      &__label {
        font-weight: 500;
        line-height: 1.25rem;
        text-transform: capitalize;
        text-decoration: none;
        &--desc {
          font-weight: ${!boldDescription && '400'};
          font-size: ${!boldDescription && '0.75rem'};
        }
      }
      &__additionalText {
        font-weight: 500;
        text-decoration: none;
        div {
          p {
            color: ${theme.palette.primary.main};
          }
          & > p:nth-child(2) {
            font-size: 12px;
          }
        }
      }
    }

    ${variantStyles({ theme, hasImage })[variant]}
    ${selected && selectedStyles({ theme })}

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.12);
    }
  `,
)

const variantStyles = ({ theme, hasImage }) => ({
  default: css`
    padding: 0.75rem 0.5rem;
  `,
  'card-vertical': css`
    grid-template-columns: 1fr;
    place-items: center;
    text-align: center;
    padding: 0 0.5rem 0.5rem;
  `,
  'card-horizontal': css`
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 7.5rem 1fr;
    align-items: center;
    padding-right: 0.5rem;

    ${theme.breakpoints.up('sm')} {
      gap: 2rem;
    }
  `,
  size: css`
    height: 52px;
    padding: 0.75rem 0.5rem;
  `,
  arrangement: css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    justify-items: center;
    text-align: center;

    .BoxButton {
      &__additionalText {
        margin: 0;
      }

      &__textWrapper {
        ${!hasImage &&
          css`
            align-items: center;
          `}
        padding: 10px;
        display: grid;
        gap: 0.25rem;
      }
    }
  `,
  'chair-style': css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    justify-items: center;
    text-align: center;

    .BoxButton {
      &__additionalText {
        margin: 0;
      }

      &__textWrapper {
        ${!hasImage &&
          css`
            align-items: center;
          `}
        padding: 1rem 0.5rem;
        display: grid;
        gap: 0.25rem;
      }

      &__imageSrc {
        padding-top: 1rem;
        aspect-ratio: 1;
        width: 100%;
        max-width: 7.5rem;
        object-fit: contain;
      }
    }
  `,
})

const selectedStyles = ({ theme }) => ({
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  '.BoxButton': {
    '&__label': {
      fontWeight: 600,
    },
    '&__additionalText': {
      fontWeight: 400,
    },
  },
})
const StyledImage = styled('img')({
  marginRight: '25px',
})

const StyledContainerInfo = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})

const StyledButtonLabel = styled('span')({})

const StyledButtonDescription = styled('span')({
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '22px',
  color: '#757575',
})

const StyledAdditionalText = styled('span')({
  fontWeight: 600,
  marginLeft: '0.8rem',
})

const BoxButton = ({
  additionalText,
  imageSrc,
  label,
  description,
  boldDescription,
  selected,
  additionalComponentStyles,
  additionalTextType = 'number',
  variant = 'default',
}) => {
  const renderContent = () => {
    switch (variant) {
      case 'card-vertical':
      case 'card-horizontal':
      case 'size':
        return (
          <>
            {imageSrc && <img className="BoxButton__imageSrc" src={imageSrc} alt={label} />}
            <div className="BoxButton__textWrapper">
              <StyledButtonLabel className="BoxButton__label">{label}</StyledButtonLabel>
              {additionalText && (
                <StyledAdditionalText style={additionalComponentStyles} className="BoxButton__additionalText">
                  {/* <PriceAtom price={additionalText} /> */}
                </StyledAdditionalText>
              )}
            </div>
          </>
        )
      case 'arrangement':
        return (
          <>
            {imageSrc && <img className="BoxButton__imageSrc" src={imageSrc} alt={label} />}
            <div className="BoxButton__textWrapper">
              <StyledButtonLabel className="BoxButton__label">{label}</StyledButtonLabel>
              {additionalText && (
                <StyledAdditionalText style={additionalComponentStyles} className="BoxButton__additionalText">
                  {additionalTextType === 'number' && <PriceAtom price={additionalText} />}
                  {additionalTextType === 'string' && additionalText}
                  {additionalTextType === 'html' && <div dangerouslySetInnerHTML={{ __html: additionalText }} />}
                </StyledAdditionalText>
              )}
            </div>
          </>
        )
      case 'chair-style':
        return (
          <>
            {imageSrc && <img className="BoxButton__imageSrc" src={imageSrc} alt={label} />}
            <div className="BoxButton__textWrapper">
              <StyledButtonLabel className="BoxButton__label">{label}</StyledButtonLabel>
              {additionalText && (
                <StyledAdditionalText style={additionalComponentStyles} className="BoxButton__additionalText">
                  {additionalTextType === 'number' && <PriceAtom price={additionalText} />}
                  {additionalTextType === 'string' && additionalText}
                  {additionalTextType === 'html' && <div dangerouslySetInnerHTML={{ __html: additionalText }} />}
                </StyledAdditionalText>
              )}
            </div>
          </>
        )
      case 'console':
        return (
          <>
            {imageSrc && <StyledImage className="BoxButton__imageSrc" src={imageSrc} alt={label} />}
            <StyledContainerInfo>
              <StyledButtonLabel className="BoxButton__label">{label}</StyledButtonLabel>
              <StyledButtonDescription className="BoxButton__label--desc">{description}</StyledButtonDescription>
            </StyledContainerInfo>
            {additionalText && (
              <StyledAdditionalText style={additionalComponentStyles}>
                {parseInt(additionalText.replace('$', '')) < 0 ? `-` : `+`}{' '}
                <PriceAtom price={additionalText.replace('-', '')} />
              </StyledAdditionalText>
            )}
          </>
        )
      default:
        return (
          <>
            {imageSrc && <StyledImage className="BoxButton__imageSrc" src={imageSrc} alt={label} />}
            <StyledContainerInfo>
              <StyledButtonLabel className="BoxButton__label">{label}</StyledButtonLabel>
              <StyledButtonDescription className="BoxButton__label--desc">{description}</StyledButtonDescription>
            </StyledContainerInfo>
            {additionalText && (
              <StyledAdditionalText style={additionalComponentStyles}>
                {additionalTextType === 'number' && <PriceAtom price={additionalText} />}
                {additionalTextType === 'string' && additionalText}
                {additionalTextType === 'html' && <div dangerouslySetInnerHTML={{ __html: additionalText }} />}
              </StyledAdditionalText>
            )}
          </>
        )
    }
  }

  return (
    <Wrapper
      selected={selected}
      variant={variant === 'console' ? 'default' : variant}
      hasImage={!!imageSrc}
      boldDescription={boldDescription}
    >
      {renderContent()}
    </Wrapper>
  )
}

BoxButton.propTypes = {
  additionalText: PropTypes.string,
  additionalTextType: PropTypes.oneOf(['html', 'number', 'string']),
  imageSrc: PropTypes.string,
  label: PropTypes.string.isRequired,
  boldDescription: PropTypes.bool,
  description: PropTypes.string,
  selected: PropTypes.bool,
  additionalComponentStyles: PropTypes.object,
  variant: PropTypes.oneOf([
    'arrangement',
    'card-horizontal',
    'card-vertical',
    'chair-style',
    'console',
    'default',
    'size',
  ]),
}

export default BoxButton
