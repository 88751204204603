import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  styled,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { currencyFormatUS } from '@helpers/string-helper'
import { productAvailability, productOnSale } from '@helpers/product'
import { useTest } from '@hooks/useTest'
import Accordion from './Accordion'
import BoxButton from '../../atoms/BoxButton/BoxButton'
import { productUrl } from '../../../../lib/helpers/route'

const StyledAccordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',

    '& .MuiCollapse-root': {
      width: 'calc(100% + 5px)',
    },

    '& .MuiAccordionSummary-expandIconWrapper': {
      color: theme.palette.primary.main,

      '&.Mui-expanded': {
        transform: 'rotate(-180deg)',
      },
    },
  }),
)

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  color: theme.palette.primary.main,
  minHeight: 0,

  '&.Mui-expanded': {
    marginTop: '14px',
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
    justifyContent: 'center',
    flexGrow: 0,
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
}))

const ArrangementCard = ({ trackingData, option, itemComponent: ItemComponent, selectedProductSku }) => {
  const theme = useTheme()
  const { generateTestId } = useTest()
  const sku = option?.sku || option?._id
  const isSelected = sku === selectedProductSku

  return (
    <Grid item xs={6} data-testid={generateTestId('arrangement', 'option')}>
      <ItemComponent
        onClick={() =>
          addToDataLayer_nextgen('ee_click', {
            ecommerce: {
              click: {
                products: [
                  {
                    ...option,
                    price: option?.arrangement_price,
                    on_sale: productOnSale(option),
                    availability: productAvailability(option),
                  },
                ],
                list: 'arrangement',
                position: trackingData.position,
              },
            },
            selection_group: 'arrangement',
            selection_clicked: option.label,
            current_selection: trackingData.currentSelection,
            selection_count: trackingData.selectionCount,
          })
        }
        data={{
          InternalUrl: productUrl(option?.route),
          id: `product-tile-link:${sku}`,
          Title: option?.label,
        }}
        style={{ ...(isSelected && { pointerEvents: 'none' }) }}
      >
        <BoxButton
          imageSrc={option?.icon_image}
          label={option?.label}
          selected={isSelected}
          variant="arrangement"
          additionalText={currencyFormatUS(option?.arrangement_price)}
          additionalComponentStyles={{
            ...(option?.arrangement_price < option?.arrangement_strikethrough_price && {
              color: theme.palette.error.main,
            }),
          }}
        />
      </ItemComponent>
    </Grid>
  )
}
ArrangementCard.propTypes = {
  trackingData: PropTypes.shape({
    position: PropTypes.number,
    selectionCount: PropTypes.number,
    currentSelection: PropTypes.string,
  }),
  option: PropTypes.shape({
    route: PropTypes.string,
    label: PropTypes.string,
    icon_image: PropTypes.string,
    sku: PropTypes.string,
    _id: PropTypes.string,
    arrangement_price: PropTypes.number,
    arrangement_strikethrough_price: PropTypes.number,
  }),
  itemComponent: PropTypes.node,
  selectedProductSku: PropTypes.string,
}

function ArrangementOptions({
  badgeNumber,
  options = [],
  itemComponent,
  selectedProductSku,
  defaultExpanded,
  additionalTitle,
}) {
  const ItemComponent = itemComponent || Link
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))
  const [expanded, setExpanded] = useState(false)

  const handleChange = () => setExpanded(prev => !prev)

  return (
    <Accordion
      selectionGroup="arrangement"
      selectionCount={options.length}
      disabled={options?.length <= 1}
      badgeNumber={badgeNumber}
      defaultExpanded={defaultExpanded && options.length > 1}
      title="Arrangement:"
      additionalTitle={additionalTitle || `${options.length} ${options.length > 1 ? 'Options' : 'Option'}`}
      subTitle={options.length > 1 && options.find(i => i.sku === selectedProductSku)?.label}
    >
      <Grid container spacing={1}>
        {!isDesktop && options.length > 2 ? (
          <>
            {options.slice(0, 2).map((option, index) => (
              <ArrangementCard
                trackingData={{
                  position: index,
                  selectionCount: options.length,
                  currentSelection: options.find(i => i.sku === selectedProductSku)?.label,
                }}
                key={`arrangement_option_${option.sku}`}
                option={option}
                selectedProductSku={selectedProductSku}
                itemComponent={ItemComponent}
              />
            ))}
            <Grid item xs={12}>
              <StyledAccordion onChange={handleChange}>
                <StyledAccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  Show {expanded ? 'Less' : 'More'}
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <Grid container spacing={1}>
                    {options.slice(2).map((option, index) => (
                      <ArrangementCard
                        trackingData={{
                          position: index + 2,
                          selectionCount: options.length,
                          currentSelection: options.find(i => i.sku === selectedProductSku)?.label,
                        }}
                        key={`arrangement_option_${option.sku}`}
                        option={option}
                        selectedProductSku={selectedProductSku}
                        itemComponent={ItemComponent}
                      />
                    ))}
                  </Grid>
                </StyledAccordionDetails>
              </StyledAccordion>
            </Grid>
          </>
        ) : (
          options.map((option, index) => (
            <ArrangementCard
              trackingData={{
                position: index,
                selectionCount: options.length,
                currentSelection: options.find(i => i.sku === selectedProductSku)?.label,
              }}
              key={`arrangement_option_${option.sku}`}
              option={option}
              selectedProductSku={selectedProductSku}
              itemComponent={ItemComponent}
            />
          ))
        )}
      </Grid>
    </Accordion>
  )
}

ArrangementOptions.propTypes = {
  badgeNumber: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      imageSrc: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      additionalText: PropTypes.string,
      arrangement_price: PropTypes.number,
    }),
  ).isRequired,
  itemComponent: PropTypes.any,
  selectedProductSku: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  additionalTitle: PropTypes.string,
}

export default ArrangementOptions
