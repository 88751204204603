import React from 'react'
import { object } from 'prop-types'
import { styled, Box } from '@mui/material'
import StoreReviews from '../../../@rtg2022/components/organisms/Reviews/reviews'

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '15px',
}))

const StyledBoldBox = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  textTransform: 'capitalize',
  color: theme.palette.primary.dark,
}))

const ProductDescription = ({ reviewsData }) => {
  const sendAnalytics = analyticsObj => {
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        category: 'PDP Product Reviews',
        productSku: reviewsData?.sku,
        ...analyticsObj,
      })
    }
  }

  return (
    <>
      {reviewsData && (
        <StyledContainer className="small-12">
          <StoreReviews reviewsData={reviewsData} sendAnalytics={sendAnalytics} />
        </StyledContainer>
      )}
    </>
  )
}

ProductDescription.propTypes = {
  reviewsData: object,
}

export default ProductDescription
