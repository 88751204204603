import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import { currencyFormatUS } from '@helpers/string-helper'
import Accordion from '../../../organisms/PDP/Accordion'
import BoxButton from '../../../atoms/BoxButton/BoxButton'

const productUrl = route => `/furniture/product${route}`

const getOptionPrice = ({ on_sale, pricing, region, zone, default_price }) => {
  const regionZone = `${region}_${zone}`
  const isOnSale = on_sale?.[regionZone] ?? false
  const priceString = `${region}_${zone}_${isOnSale ? 'sale' : 'list'}_price`
  const price = pricing[priceString] || default_price
  return price
}

const ButtonAccordion = ({
  title,
  options,
  LinkComponent,
  sku,
  additionalTitle,
  subTitle,
  region,
  zone,
  variant,
  ...props
}) => (
  <Accordion
    {...props}
    selectionCount={options.length}
    title={title}
    additionalTitle={
      options.length < 2
        ? subTitle
        : additionalTitle || `${options.length} ${options.length > 1 ? 'Options' : 'Option'}`
    }
    subTitle={options.length < 2 ? null : subTitle}
    disabled={options.length < 2}
  >
    {options.length > 1 && (
      <Stack direction="column" gap={1}>
        {options.map(option => {
          const selectedProductSku = option?.sku || option?._id
          const isSelected = sku === selectedProductSku
          return (
            <LinkComponent
              data={{
                InternalUrl: productUrl(option.route),
                id: `product-upgrade-link:${option.sku}`,
                Title: option?.label,
              }}
              key={option.sku}
              style={{ ...(isSelected && { pointerEvents: 'none' }) }}
            >
              <BoxButton
                label={option.label}
                selected={isSelected}
                variant={variant}
                additionalText={
                  option?.pricing
                    ? currencyFormatUS(
                        getOptionPrice({
                          on_sale: option.on_sale,
                          pricing: option.pricing,
                          region,
                          zone,
                          default_price: option.pricing.default_price,
                        }),
                      )
                    : ''
                }
              />
            </LinkComponent>
          )
        })}
      </Stack>
    )}
  </Accordion>
)

ButtonAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  additionalTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon_image: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    }),
  ).isRequired,
  sku: PropTypes.string.isRequired,
  LinkComponent: PropTypes.elementType.isRequired,
  region: PropTypes.string.isRequired, //  Needed for option price
  zone: PropTypes.string.isRequired, //  Needed for option price
  variant: PropTypes.string,
}

export default ButtonAccordion
