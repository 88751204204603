import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { styled, useMediaQuery, Box, Typography, Button } from '@mui/material'
import LocationIcon from '@mui/icons-material/LocationOnOutlined'
import PhoneOutlined from '@mui/icons-material/PhoneOutlined'
import _capitalize from 'lodash/capitalize'

import { useStore } from '@hooks/useStore'
import RTGLink from '../../shared/link'
import SeeInStores from '../product-parts/product-see-in-stores'

const StyledSeeInStoresButton = styled(SeeInStores)({
  minWidth: '145px',
  textDecoration: 'underline',
  textTransform: 'capitalize !important',
  height: 'fit-content',
  padding: 0,
  span: {
    width: 'unset !important',
  },
})

const StyledStoreInfoLiaContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,
  'a, a > p': {
    color: theme.palette.primary.main,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0,
    alignItems: 'flex-start',
    outline: 'none',
  },
}))

const StyledOnDisplay = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  width: '100%',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '1.19rem',
  textTransform: 'uppercase',
}))

const StyledStoreNameContainer = styled(Box)({
  display: 'flex',
  marginBottom: 16,
})

const StyledStoreNameText = styled(Typography)({
  fontWeight: 600,
  fontSize: 19,
  lineHeight: '1.61rem',
})

const StyledStoreAddressContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const StyledStoreAddressItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  svg: {
    color: theme.palette.primary.main,
  },
}))

const StyledStoreAddressButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '13px',
  textTransform: 'capitalize',
}))

const OpenStoreText = styled(Typography)(({ theme, closed }) => ({
  color: closed ? theme.palette.error.main : theme.palette.success.main,
  fontWeight: 600,
  marginTop: 18,
}))

export default function ProductLIAStoreInfo({ product, store, available }) {
  const {
    storeCity,
    storeState,
    storeZip,
    address1,
    address2,
    storePhoneNumber,
    storeUrl,
    storeExtendedName,
    storeShortTodayOpenStatus,
  } = useStore({
    store,
  })
  const openStoreText = storeShortTodayOpenStatus?.includes('Closed')
    ? storeShortTodayOpenStatus
    : `Open Today ${storeShortTodayOpenStatus}`

  if (!available) {
    return (
      <StyledStoreInfoLiaContainer>
        <StyledOnDisplay>Not available for display or order at</StyledOnDisplay>
        <RTGLink
          data={{
            url: storeUrl,
            text: storeExtendedName,
            category: 'store',
            action: 'click',
            label: storeExtendedName,
          }}
          className="store-link"
        />
      </StyledStoreInfoLiaContainer>
    )
  }

  return (
    <StyledStoreInfoLiaContainer>
      <StyledOnDisplay>On display at</StyledOnDisplay>
      <StyledStoreNameContainer>
        <StyledStoreNameText>{storeExtendedName}</StyledStoreNameText>
        <StyledSeeInStoresButton
          sku={product?.sku}
          title={product?.title}
          primary_image={product?.primary_image}
          zip={storeZip}
          customButtonText="Change Location"
          showIcon={false}
          lia
        />
      </StyledStoreNameContainer>
      <StyledStoreAddressContainer>
        <StyledStoreAddressItem>
          <LocationIcon />
          <StyledStoreAddressButton variant="text" href={storeUrl}>
            <Typography>{_capitalize(`${address1}${address2 ? `, ${address2}` : ''}`)}</Typography>
            <Typography>
              {storeCity}, {storeState}, {store.Zip}
            </Typography>
          </StyledStoreAddressButton>
        </StyledStoreAddressItem>
        <StyledStoreAddressItem>
          <PhoneOutlined />
          <StyledStoreAddressButton variant="text" href={`tel:${storePhoneNumber.replace('/', '-')}`}>
            {storePhoneNumber.replace('/', '-')}
          </StyledStoreAddressButton>
        </StyledStoreAddressItem>
      </StyledStoreAddressContainer>
      <OpenStoreText>{openStoreText}</OpenStoreText>
      <RTGLink
        data={{
          url: storeUrl,
          text: 'View Store Details',
          category: 'store',
          action: 'click',
          label: 'View Store Details',
        }}
        className="store-link"
      />
    </StyledStoreInfoLiaContainer>
  )
}

ProductLIAStoreInfo.propTypes = {
  available: PropTypes.bool,
  product: PropTypes.object,
  store: PropTypes.object,
}
