import React from 'react'
import { any, arrayOf, bool, number, shape, string } from 'prop-types'
import {
  styled,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material'
import DeliveryTruckIcon from '@assets/images/delivery-truck'
import { useTest } from '@hooks/useTest'

const Availability = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.light,
  borderRadius: 5,
  fontSize: 12,
  lineHeight: '17px',
  marginTop: 8,
  padding: '5px 0',
  width: '100%',
}))

const AvailabilityDate = styled(Box)({
  fontWeight: 700,
  textTransform: 'uppercase',
})

const StyledCard = styled(Card, { shouldForwardProp: prop => prop !== 'active' })(({ active, theme }) => ({
  borderColor: active ? theme.palette.secondary.dark : theme.palette.common.white,
  borderRadius: 5,
  borderWidth: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 385,
  maxWidth: 385,
  width: 'calc(100vw - 35px)',
}))

const CardHeader = styled(Grid, { shouldForwardProp: prop => prop !== 'active' })(({ active }) => ({
  border: 0,
  paddingBottom: 10,
  borderStyle: 'solid',
  borderBottomWidth: active ? 2 : 1,
  borderColor: '#e7e7ea',
  marginBottom: active ? 10 : 11,
}))

const CardFooter = styled(CardActions)({
  flexDirection: 'column',
  padding: '1rem',
  '> button': { border: '2px solid' },
})

const Title = styled(Box, { shouldForwardProp: prop => prop !== 'active' })(({ active, theme }) => ({
  fontWeight: active ? 600 : 400,
  color: theme.palette.secondary.dark,
  width: '90%',
  marginBottom: 8,
  fontSize: 15,
  lineHeight: '20px',
}))

const Superscript = styled(Box)({
  alignSelf: 'flex-start',
  display: 'inline-flex',
  fontSize: 10,
  fontWeight: 300,
  lineHeight: '14px',
  verticalAlign: 'top',
})

const Price = styled(Box)({
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  fontSize: 15,
  fontWeight: 700,
  display: 'flex',
})

const RoomSavings = styled(Box)(({ theme }) => ({
  color: '#e31c3d',
  display: 'inline-flex',
  flex: 1,
  flexDirection: 'column',
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '17px',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

const ListItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '0 0 50px',
  alignItems: 'center',
  marginRight: 5,
  textAlign: 'center',
  height: 40,
  width: 'auto',
  [theme.breakpoints.up('sm')]: {
    flex: '0 0 60px',
  },
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '13px',
  marginLeft: 0,
  textTransform: 'capitalize',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 10,
  },
}))

const StyledImage = styled('img')({
  height: 'auto',
  margin: '0 auto',
  maxHeight: '100%',
  maxWidth: '100%',
})

const Item = ({ label, image, quantity }) => {
  const resizedImage = `${image}&w=75`
  return (
    <Box mb={1} height="100%" width="50%">
      <List aria-label="Required Addon" sx={{ padding: 0 }}>
        <ListItem display="flex" alignItems="center" disableGutters sx={{ padding: 0 }}>
          <ListItemWrapper>
            <StyledImage src={resizedImage} alt={label} />
          </ListItemWrapper>
          <StyledListItemText
            disableTypography
            primary={quantity && quantity > 1 ? `${label} (${quantity})` : `${label}`}
          />
        </ListItem>
      </List>
    </Box>
  )
}

Item.propTypes = {
  image: any,
  label: any,
  quantity: any,
}

const weeksBetween = (date1, date2) => {
  const ONE_WEEK = 1000 * 60 * 60 * 24 * 7
  const difference_ms = Math.abs(date1.getTime() - date2.getTime())
  return Math.floor(difference_ms / ONE_WEEK)
}

const formatDate = date => new Date(new Date(date).toUTCString().substr(0, 25))

const sameYear = date => {
  const dateNow = new Date().getFullYear()
  const dateAvailable = parseInt(date.substring(date.length - 4))
  return dateNow === dateAvailable ? date.slice(0, -6) : date
}

const parseDate = date => {
  const formattedDate = formatDate(date)
  const dateString = formattedDate.toLocaleString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  return dateString
}

const getPriceStrings = price => {
  try {
    if (typeof price === 'string') {
      if (price[0] === '$') {
        return price.slice(1).split('.')
      }
      return price.split('.')
    }
    return price.toString().split('.')
  } catch {
    return ['Invalid Price']
  }
}

const ProductRoomCard = ({
  active = false,
  availability_date = '',
  items = [],
  price = '',
  room_savings = 0,
  title = '',
}) => {
  const [dollars, cents] = getPriceStrings(typeof price === 'number' ? price.toFixed(2) : price)
  const today = new Date()
  const isAvailableIn2Weeks = weeksBetween(today, new Date(parseDate(availability_date))) < 2
  const returnDate = sameYear(parseDate(availability_date))
  const { generateTestId } = useTest()

  return (
    <StyledCard variant="outlined" active={active}>
      <CardContent pb={0}>
        <Stack direction="column" justifyContent="flex-start">
          <CardHeader container active={active}>
            <Title active={active} data-testid={generateTestId(active ? 'SelectedRoom' : 'OtherRooms', 'Title')}>
              {title}
            </Title>
            <Stack direction="row" spacing={1}>
              <Price data-testid={generateTestId(active ? 'SelectedRoom' : 'OtherRooms', 'Price')}>
                <Superscript>$</Superscript>
                {dollars}
                {cents !== '00' && <Superscript mr={1}>{cents}</Superscript>}
              </Price>
              {room_savings > 20 && (
                <RoomSavings>
                  <Box mr="5px">{`$${room_savings.toFixed(0)} Savings`}</Box>
                  <Box fontSize={12} fontWeight={400} color="#333">
                    (Compared to items sold separately)
                  </Box>
                </RoomSavings>
              )}
            </Stack>
          </CardHeader>
          {items && items.length > 0 && (
            <Grid container data-testid={generateTestId(active ? 'SelectedRoom' : 'OtherRooms', 'Includes')}>
              <Box width="100%" fontSize={12} fontWeight="fontWeightBold">
                Includes
              </Box>
              {items.map(item => (
                <Item key={JSON.stringify(item)} image={item.image} label={item.label} quantity={item.quantity} />
              ))}
            </Grid>
          )}
        </Stack>
      </CardContent>
      <CardFooter disableSpacing>
        {!active && (
          <Button
            fullWidth
            size="medium"
            color="primary"
            variant="outlined"
            data-testid={generateTestId('OtherRooms', 'ViewDetails')}
          >
            View Room Details
          </Button>
        )}
        {availability_date && (
          <Availability
            direction="row"
            spacing={1}
            data-testid={generateTestId(active ? 'SelectedRoom' : 'OtherRooms', 'Availability')}
          >
            <DeliveryTruckIcon />
            <Box sx={{ fontWeight: isAvailableIn2Weeks ? 700 : 500 }}>
              {isAvailableIn2Weeks ? 'Available Now' : 'Available as soon as'}
            </Box>
            {!isAvailableIn2Weeks && <AvailabilityDate>{returnDate}</AvailabilityDate>}
          </Availability>
        )}
      </CardFooter>
    </StyledCard>
  )
}

ProductRoomCard.propTypes = {
  active: bool,
  availability_date: string,
  items: arrayOf(
    shape({
      image: string,
      label: string,
      quantity: number,
    }),
  ),
  price: number,
  room_savings: number,
  title: string,
}

export default ProductRoomCard
