import React from 'react'
import PropTypes from 'prop-types'

import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { Stack, Box, Link, styled } from '@mui/material'
import { productUrl } from '../../../../lib/helpers/route'
import ProductColor from '../../atoms/ProductColor'
import { useTest } from '../../../../lib/hooks/useTest'

const StyledProductColor = styled(ProductColor)(() => ({
  padding: 0,
}))

function ChooseProductColor({ colors, selectedProductSku, itemComponent, className }) {
  const ItemComponent = itemComponent || Link
  const { generateTestId } = useTest()
  return (
    <Stack spacing={2} className={className} data-testid={generateTestId('ProductColor', 'Container')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '9px',
        }}
      >
        {colors?.map((color, index) => {
          const sku = color?.sku || color?._id
          const isSelected = selectedProductSku === color.sku || colors?.length <= 1
          return (
            <ItemComponent
              onClick={() =>
                addToDataLayer_nextgen('ee_click', {
                  ecommerce: {
                    click: {
                      products: [color],
                      list: 'color',
                      position: index,
                    },
                  },
                  selection_group: 'color',
                  selection_clicked: color.label,
                  current_selection: colors.find(i => i.sku === selectedProductSku)?.variation_value,
                  selection_count: colors.length,
                })
              }
              data={{
                InternalUrl: productUrl(color?.route),
                id: `product-tile-link:${color?.sku}`,
                Title: color?.variation_value,
              }}
              key={color.sku}
              style={{ ...(isSelected && { pointerEvents: 'none' }) }}
            >
              <StyledProductColor color={color} isSelected={isSelected} />
            </ItemComponent>
          )
        })}
      </Box>
    </Stack>
  )
}

ChooseProductColor.propTypes = {
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      variation_value: PropTypes.string,
      title: PropTypes.string.isRequired,
      catalog_availability: PropTypes.object,
      image: PropTypes.string.isRequired,
      route: PropTypes.string,
    }),
  ).isRequired,
  selectedProductSku: PropTypes.string.isRequired,
  itemComponent: PropTypes.any,
  className: PropTypes.string,
}

export default ChooseProductColor
