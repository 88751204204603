import React from 'react'
import { Stack, useTheme, Box, Typography, styled } from '@mui/material'
import { string, func, bool, any, oneOfType, number } from 'prop-types'
import { useTest } from '@hooks/useTest'
import Button from '../../atoms/Button/Button'
import ProductPrice from '../../atoms/ProductPrice/ProductPrice'
import { FAVORITE_BUTTON } from '../../..'

const StyledProductPrice = styled(ProductPrice)(({ theme }) => ({
  alignItems: 'flex-end',
  '.container-price': {
    [theme.breakpoints.up('sm')]: {
      minWidth: '200px',
      justifyContent: 'flex-end',
    },
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 220,
  [theme.breakpoints.up('sm')]: {
    marginLeft: '16px',
  },
}))

function DesktopTitle({
  title,
  price,
  availability,
  addToCartLabel,
  addToCartClick,
  className,
  handleFavoriteClick,
  isFavorited,
  sale,
  strikethrough,
  strikeThroughPrice,
  roomSavings,
}) {
  const theme = useTheme()
  const { generateTestId } = useTest()
  return (
    <Box
      id="desktop-pdp-title"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0,0,0,0.12)',
        zIndex: 8,
      }}
      className={className}
    >
      <Typography
        component="h1"
        data-testid={generateTestId('pdptitle', 'text')}
        sx={{
          color: 'primary.dark',
          fontWeight: 600,
          fontSize: 26,
          ...(!!roomSavings && { maxWidth: '55%' }),
          [theme.breakpoints.only('xl')]: {
            fontSize: 30,
          },
        }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} sx={{ flex: 1 }}>
        <StyledProductPrice
          price={price}
          availability={availability}
          strikeThrough={strikethrough}
          strikeThroughPrice={strikeThroughPrice}
          sale={sale}
          sx={{
            color: 'primary.dark',
            fontWeight: 400,
            fontSize: 20,
            [theme.breakpoints.only('xl')]: {
              fontSize: 27,
            },
          }}
          roomSavings={roomSavings}
        />
        <StyledButton
          color="primary"
          size="medium"
          variant="contained"
          disableElevation
          label={addToCartLabel}
          onClick={addToCartClick}
          data-testid={generateTestId('pdptitle', 'addtocart')}
        />
        {handleFavoriteClick && <FAVORITE_BUTTON checked={isFavorited} handleClick={handleFavoriteClick} />}
      </Stack>
    </Box>
  )
}

DesktopTitle.propTypes = {
  title: string,
  price: string,
  availability: bool,
  addToCartLabel: string,
  addToCartClick: func,
  className: string,
  handleFavoriteClick: func,
  isFavorited: bool,
  sale: bool,
  strikethrough: bool,
  roomSavings: any,
  strikeThroughPrice: oneOfType([number, string]),
}

export default DesktopTitle
