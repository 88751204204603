import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  styled,
  Grid,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import RTGLink from '@shared/link'
import { parseDescription } from '@helpers/product'
import { getRegionZone } from '@helpers/geo-location'
import { formatReviews } from '@helpers/reviews'
import { decodeTrademarkEntity } from '@helpers/string-helper'
import _isEmpty from 'lodash/isEmpty'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { theme as themeStyles } from '@constants/styles'
import Dimensions from './Dimensions'
import ProductReviews from '../product-parts/product-reviews'
import { FETCH_PROPERTIES, useProduct } from '../hooks'

const StyledDescList = styled(`ul`)`
  list-style: none;
`

const SkuStyled = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}))

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '.MuiAccordionSummary-content': {
    color: '#333333',
    textTransform: 'uppercase',
  },
  '.MuiAccordion-root:first-of-type': {
    borderTop: 'none',
  },
  '.MuiAccordion-root': {
    boxShadow: 'none',
    borderTop: '1px solid rgba(0,0,0,0.1)',
  },
  '.MuiAccordionSummary-root.Mui-expanded': {
    margin: 0,
  },
  '.MuiAccordion-root.Mui-expanded': {
    margin: 0,
    '&::before': {
      position: 'absolute',
      content: '""',
      opacity: 1,
      left: 0,
      top: '-1px',
      right: 0,
      height: '1px',
      backgroundColor: 'rgba(0,0,0,0.12)',
    },
  },
}))

const StyledWarrantyLink = styled('a')(({ theme, multi }) => ({
  color: '#0053a0',
  border: '1px solid',
  borderRadius: '5px',
  padding: '3px 6px',
  fontSize: '14px',
  marginLeft: !multi && '10px',
}))

const WarrantyText = styled(Typography)(() => ({
  marginBottom: '16px',
}))
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`product-description-tab-${index}`} {...other}>
      {value === index && index !== 3 ? (
        <Box sx={{ p: { xs: 0, md: 3 } }}>
          <Typography sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: '1rem' }}>
            {children}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ p: { xs: 0, md: 3 } }}>{children}</Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `product-description-${index}`,
    'aria-controls': `product-description-tab-${index}`,
  }
}

/**
 * DescriptionTab
 * @param {array} assemblyInstructions
 * @param {string} description
 * @param {string} dimensions
 * @param {string} size
 * @param {string} technology
 * @param {array} dimensionImage
 * @returns
 */
function DescriptionTab({
  assemblyInstructions,
  dimensionImage,
  description,
  dimensions,
  warrantyLink,
  warrantyDescription,
  product,
}) {
  const [value, setValue] = React.useState(0)
  const { warranty } = parseDescription(description, warrantyDescription)
  const warrantyData = warranty || warrantyLink
  const { image, reviews, title } = useProduct({ product }, FETCH_PROPERTIES.PRODUCT_REVIEWS)
  const formattedReviews = useMemo(() => formatReviews({ title, image, reviewsData: reviews }), [image, reviews, title])
  const roomItems = product.items_in_room?.[`${getRegionZone()?.region}`]

  const [index, setIndex] = useState([])

  const productDimensions = dimensions || roomItems

  useEffect(() => {
    const tabs = [description, productDimensions, warrantyData, reviews]
    const tabArray = []
    tabs.forEach(tab => {
      if (tab && !_isEmpty(tab)) {
        tabArray.push(tab)
      }
    })
    setIndex(tabArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const descriptionHTML = (
    <Grid container>
      {description && (
        <>
          <Grid md={3} />
          <Grid xs={12} md={6}>
            <>
              <Typography>{decodeTrademarkEntity(description)}</Typography>
              {assemblyInstructions && (
                <>
                  <Typography pt="20px">
                    <RTGLink
                      data={{
                        target: '_blank',
                        url: assemblyInstructions[0],
                        category: 'product description',
                        action: 'product assembly instructions click',
                        label: 'Assembly Instructions',
                      }}
                    >
                      Manufacturer's Assembly Instructions
                      <span className="hide508">PDF (Opens in new window)</span>
                    </RTGLink>
                  </Typography>
                  <Typography>
                    Subject to change. Please consult the instructions included with your delivery.
                  </Typography>
                </>
              )}
              <Typography pt="20px">
                <SkuStyled>SKU: </SkuStyled>
                {product.sku}
              </Typography>
            </>
          </Grid>
          <Grid md={3} />
        </>
      )}
    </Grid>
  )

  const warrantyHTML = (
    <Grid container>
      <Grid md={3} />
      <Grid xs={12} md={6}>
        {Array.isArray(warranty) && warranty.length > 1 ? (
          warranty.map(wty => <WarrantyText key={wty}>{wty}</WarrantyText>)
        ) : (
          <WarrantyText>{warranty}</WarrantyText>
        )}
        {warrantyLink && (
          <StyledWarrantyLink
            multi={Array.isArray(warranty) && warranty.length > 1}
            href={warrantyLink}
            target="_blank"
          >
            VIEW WARRANTY
            <span className="hide508">(opens in new window)</span>
          </StyledWarrantyLink>
        )}
      </Grid>
      <Grid md={3} />
    </Grid>
  )

  const dimensionsHTML = (
    <>
      <Dimensions dimensions={dimensions} dimensionImage={dimensionImage} roomItems={roomItems} isMobile={isMobile} />
    </>
  )

  const reviewsHTML = (
    <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
      <Grid md={10}>
        <ProductReviews reviewsData={formattedReviews} />
      </Grid>
    </Grid>
  )

  const getIndex = tab => {
    if (index.includes(tab)) {
      return index.indexOf(tab)
    }
    return null
  }

  return isMobile ? (
    <StyledBox>
      {description && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Description</AccordionSummary>
          <AccordionDetails>{descriptionHTML}</AccordionDetails>
        </Accordion>
      )}
      {productDimensions && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Dimensions</AccordionSummary>
          <AccordionDetails>{dimensionsHTML}</AccordionDetails>
        </Accordion>
      )}
      {warrantyData && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Warranty</AccordionSummary>
          <AccordionDetails sx={{ textWrap: 'balance' }}>{warrantyHTML}</AccordionDetails>
        </Accordion>
      )}
      {!_isEmpty(reviews) && (
        <Accordion>
          <AccordionSummary className="reviews-tab" expandIcon={<ExpandMoreIcon />}>
            Customer Reviews
          </AccordionSummary>
          <AccordionDetails>{reviewsHTML}</AccordionDetails>
        </Accordion>
      )}
    </StyledBox>
  ) : (
    <Box sx={{ width: '100%', backgroundColor: 'white', marginTop: '20px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="product overview"
          centered
          sx={{
            '.Mui-selected': { fontWeight: 'bold' },
            '.MuiTabs-indicator': { backgroundColor: themeStyles.accent },
          }}
        >
          {description && <Tab label="Description" {...a11yProps(getIndex(description))} />}
          {productDimensions && <Tab label="Dimensions" {...a11yProps(getIndex(productDimensions))} />}
          {warrantyData && <Tab label="Warranty" {...a11yProps(getIndex(warrantyData))} />}
          {!_isEmpty(reviews) && (
            <Tab className="reviews-tab" label="Customer Reviews" {...a11yProps(getIndex(reviews))} />
          )}
        </Tabs>
      </Box>

      {description && (
        <TabPanel value={value} index={getIndex(description)}>
          {descriptionHTML}
        </TabPanel>
      )}
      {productDimensions && (
        <TabPanel value={value} index={getIndex(productDimensions)}>
          {dimensionsHTML}
        </TabPanel>
      )}
      {warrantyData && (
        <TabPanel value={value} index={getIndex(warrantyData)}>
          {warrantyHTML}
        </TabPanel>
      )}
      {!_isEmpty(reviews) && (
        <TabPanel value={value} index={getIndex(reviews)}>
          {reviewsHTML}
        </TabPanel>
      )}
    </Box>
  )
}

DescriptionTab.propTypes = {
  assemblyInstructions: PropTypes.array,
  dimensionImage: PropTypes.array,
  description: PropTypes.string,
  dimensions: PropTypes.string,
  comfort: PropTypes.string,
  pressure_relief: PropTypes.string,
  sleep_position: PropTypes.string,
  support_level: PropTypes.string,
  temperature_management: PropTypes.string,
  size: PropTypes.string,
  technology: PropTypes.string,
  warrantyLink: PropTypes.string,
  warrantyDescription: PropTypes.string,
  product: PropTypes.object,
}

export default DescriptionTab
