import React from 'react'
import PropTypes from 'prop-types'
import { Button as MuiButton } from '@mui/material'

/**
 * Primary UI component for user interaction
 */
const Button = ({ variant, color, size, label, ...props }) => (
  <MuiButton color={color} variant={variant} size={size} {...props}>
    {label}
    {props.children}
  </MuiButton>
)

Button.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf(['primary', 'secondary', 'warning']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  size: 'medium',
  onClick: undefined,
}

export default Button
