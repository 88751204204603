import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Link, styled } from '@mui/material'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import RoomsPackagesDrawer from './RoomPackagesDrawer'

const StyledRoomImage = styled('img')(() => ({
  height: '75px',
  width: '114px',
  objectFit: 'cover',
  marginRight: '10px',
}))

const StyledChevronRightIcon = styled(ChevronRightIcon)(({ theme }) => ({
  width: '35px',
  height: '35px',
  fill: theme.palette.secondary.lighter,
}))

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: theme.palette.secondary.dark,
  textDecoration: 'none',
}))

function RoomsPackages({ title, image, options, itemComponent }) {
  const ItemComponent = itemComponent || Link
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const handleClick = () => {
    setIsDrawerOpen(true)
    addToDataLayer_nextgen('ee_click', {
      ecommerce: {
        click: {
          title,
          options,
        },
      },
      selection_group: 'see_in_room',
    })
  }

  const onDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  return (
    <Grid container columnGap={2} rowGap={1} style={{ cursor: 'pointer' }}>
      <RoomsPackagesDrawer roomPackages={options} isOpen={isDrawerOpen} onClose={onDrawerClose} />
      <ItemComponent style={{ display: 'flex', alignItems: 'center' }} onClick={handleClick}>
        <StyledRoomImage src={image || options?.[0]?.image} alt={title || options?.[0]?.title} />
        <DescriptionText>Shop designer styled room options</DescriptionText>
        <StyledChevronRightIcon />
      </ItemComponent>
    </Grid>
  )
}

RoomsPackages.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  options: PropTypes.array,
  itemComponent: PropTypes.any,
}

export default RoomsPackages
