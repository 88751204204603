import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, Link, styled, Button, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { currencyFormatUS } from '@helpers/string-helper'
import { productPrice, productOnSale, productAvailability } from '@helpers/product'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { productUrl } from '@helpers/route'
import { useTest } from '@hooks/useTest'
import Accordion from './Accordion'
import BoxButton from '../../atoms/BoxButton/BoxButton'
import ProductPrice from '../../atoms/ProductPrice/ProductPrice'
import LearnMoreDrawer from '../../molecules/PDP/LearnMoreDrawer'

const StyledAccordion = styled(Accordion)(({ isExpanded, defaultExpanded }) => ({
  margin: !isExpanded || !defaultExpanded ? '16px 0 16px 0' : '0',
  padding: '0!important',
}))

const StyledProductPrice = styled(ProductPrice)({
  width: 'unset',
  '.MuiGrid-root': {
    margin: 0,
    '.MuiGrid-item': {
      padding: 0,
      marginRight: '5px',
      '.container-price': {
        padding: 0,
      },
      '.MuiBox-root': {
        fontSize: 'unset',
        span: {
          fontWeight: 600,
          marginLeft: '0.8rem',
        },
      },
    },
  },
})

const RecliningOptionsButton = styled(Button)({
  justifyContent: 'left',
  margin: '0 0 16px 0',
})

function MovementOptions({
  title = 'Movement:',
  badgeNumber,
  subTitle,
  options = [],
  itemComponent,
  selectedProductSku,
  additionalTitle,
}) {
  const ItemComponent = itemComponent || Link
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(true)
  const { generateTestId } = useTest()
  // eslint-disable-next-line no-shadow
  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(open)
  }

  const recliningDescriptions = label => {
    switch (label) {
      case 'non-power':
        return 'Manual Control'

      case 'single power':
        return 'Power Adjustable Recliner'

      case 'dual power':
        return 'Power Adjustable Recliner and Headrest'

      case 'triple power':
        return 'Power Adjustable Recliner and Headrest & Lumbar Support'
      default:
        return label
    }
  }

  const handleChange = () => setExpanded(prev => !prev)

  return (
    <>
      <StyledAccordion
        handleChange={handleChange}
        selectionGroup="movement"
        disabled={options?.length <= 1}
        defaultExpanded
        badgeNumber={badgeNumber}
        title={title}
        additionalTitle={additionalTitle || `${options.length > 1 ? `${options.length} Options` : options[0].label}`}
        isMovement
        subTitle={subTitle}
        isExpanded={expanded}
      >
        <Stack direction="column" gap={1}>
          {options.map((option, index) => {
            const sku = option?.sku || option?._id
            const isSelected = sku === selectedProductSku
            return (
              options.length > 1 && (
                <ItemComponent
                  key={option}
                  onClick={() =>
                    addToDataLayer_nextgen('ee_click', {
                      ecommerce: {
                        click: {
                          products: [
                            {
                              ...option,
                              price: option?.pdp_price,
                              on_sale: productOnSale(option),
                              availability: productAvailability(option),
                            },
                          ],
                          list: 'movement',
                          position: index,
                        },
                      },
                      selection_group: 'movement',
                      selection_clicked: option.label,
                      current_selection: options.find(i => i.sku === selectedProductSku)?.label,
                      selection_count: options.length,
                    })
                  }
                  data={{
                    InternalUrl: productUrl(option?.route),
                    id: `product-tile-link:${sku}`,
                    Title: option?.label,
                  }}
                  style={{ ...(isSelected && { pointerEvents: 'none' }) }}
                >
                  <BoxButton
                    imageSrc={option?.icon_image}
                    label={option?.label === 'power reclining' ? `single ${option?.label}` : option?.label}
                    description={option?.description || recliningDescriptions(option?.label)}
                    boldDescription={false}
                    additionalText={currencyFormatUS(option?.pdp_price)}
                    selected={isSelected}
                  />
                </ItemComponent>
              )
            )
          })}
        </Stack>
      </StyledAccordion>
      {!expanded && (
        <>
          {options.map(option => {
            const isSelected = option.sku === selectedProductSku
            return isSelected ? (
              <Typography textTransform="capitalize" color="primary" mb="8px">
                {option.label === 'power reclining' ? `single ${option.label}` : option.label}
              </Typography>
            ) : (
              <></>
            )
          })}
        </>
      )}
      {options.length > 0 && options[0].label !== 'stationary' && (
        <>
          <RecliningOptionsButton
            onClick={toggleDrawer(true)}
            data-testid={generateTestId('RecliningOptions', 'Button')}
          >
            <InfoOutlinedIcon />
            <Typography
              textTransform="none"
              fontWeight={400}
              fontSize="0.875rem"
              color="primary"
              sx={{ textDecoration: 'underline', paddingLeft: '10px' }}
            >
              Learn more about reclining options
            </Typography>
          </RecliningOptionsButton>
          <LearnMoreDrawer
            type="Recliner"
            open={open}
            toggleDrawer={toggleDrawer}
            options={options}
            selectedProductSku={selectedProductSku}
          />
        </>
      )}
    </>
  )
}

MovementOptions.propTypes = {
  badgeNumber: PropTypes.number.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      imageSrc: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  itemComponent: PropTypes.any,
  selectedProductSku: PropTypes.string,
  additionalTitle: PropTypes.string,
}

export default MovementOptions
