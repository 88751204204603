import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Stack, Link, styled, Button, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { currencyFormatUS } from '@helpers/string-helper'
import {
  productPrice,
  productOnSale,
  productAvailability,
  calculateOptionPrice,
  consoleDescriptions,
} from '@helpers/product'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { productUrl } from '@helpers/route'
import { useTest } from '@hooks/useTest'
import Accordion from './Accordion'
import BoxButton from '../../atoms/BoxButton/BoxButton'
import LearnMoreDrawer from '../../molecules/PDP/LearnMoreDrawer'

const StyledAccordion = styled(Accordion)(({ isExpanded, defaultExpanded }) => ({
  margin: !isExpanded || !defaultExpanded ? '16px 0 16px 0' : '0',
}))

const ConsoleOptionsButton = styled(Button)({
  justifyContent: 'left',
  margin: '0 0 16px 0',
})

function ConsoleOptions({
  productTitle,
  title = 'Consoles:',
  basePrice,
  badgeNumber,
  subTitle,
  options = [],
  itemComponent,
  selectedProductSku,
  additionalTitle,
  collection,
}) {
  const ItemComponent = itemComponent || Link
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(true)
  const isModularTwo = collection === 'modulartwo'
  const showTwoConsoles = isModularTwo && options.find(op => op.label.includes('mixed'))

  const { generateTestId } = useTest()
  // eslint-disable-next-line no-shadow
  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(open)
  }

  const handleChange = () => setExpanded(prev => !prev)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <>
      <StyledAccordion
        handleChange={handleChange}
        selectionGroup="console"
        disabled={options?.length <= 1}
        defaultExpanded
        badgeNumber={badgeNumber}
        title={title}
        additionalTitle={additionalTitle || (options.length > 1 ? `${options.length} Options` : options[0].label)}
        subTitle={subTitle}
        isExpanded={expanded}
      >
        <Stack direction="column" gap={1}>
          {options.map((option, index) => {
            const sku = option?.sku || option?._id
            const isSelected = sku === selectedProductSku
            return (
              options.length > 1 && (
                <div key={`console_option_${option?.sku ?? ''}`} data-testid={generateTestId('console', 'option')}>
                  <ItemComponent
                    onClick={() =>
                      addToDataLayer_nextgen('ee_click', {
                        ecommerce: {
                          click: {
                            products: [
                              {
                                ...option,
                                price: option?.pdp_price,
                                on_sale: productOnSale(option),
                                availability: productAvailability(option),
                              },
                            ],
                            list: 'console',
                            position: index,
                          },
                        },
                        selection_group: 'console',
                        selection_clicked: option.label,
                        current_selection: options.find(i => i.sku === selectedProductSku)?.label,
                        selection_count: options.length,
                      })
                    }
                    data={{
                      InternalUrl: productUrl(option?.route),
                      id: `product-tile-link:${sku}`,
                      Title: option?.label,
                    }}
                    style={{ ...(isSelected && { pointerEvents: 'none' }) }}
                  >
                    <BoxButton
                      imageSrc={option?.icon_image}
                      label={showTwoConsoles ? `2 ${option.label.replace(/console$/, 'consoles')}` : option?.label}
                      description={
                        option?.description ||
                        consoleDescriptions(
                          option?.label,
                          option?.label.includes('mixed')
                            ? options?.find(op => op.label.includes('mixed')).title
                            : productTitle,
                        )
                      }
                      boldDescription={false}
                      additionalText={currencyFormatUS(calculateOptionPrice(option, basePrice))}
                      variant="console"
                      selected={isSelected}
                    />
                  </ItemComponent>
                </div>
              )
            )
          })}
        </Stack>
      </StyledAccordion>
      {!expanded && (
        <>
          {options.map(option => {
            const isSelected = option.sku === selectedProductSku
            return isSelected ? (
              <Typography textTransform="capitalize" color="primary" mb="8px">
                {option.label}
              </Typography>
            ) : (
              <></>
            )
          })}
        </>
      )}
      {options.length > 0 && options[0].label !== 'stationary' && !isModularTwo && (
        <>
          <ConsoleOptionsButton onClick={toggleDrawer(true)} data-testid={generateTestId('ConsoleOptions', 'Button')}>
            <InfoOutlinedIcon />
            <Typography
              textTransform="none"
              fontWeight={400}
              fontSize="0.875rem"
              color="primary"
              sx={{ textDecoration: 'underline', paddingLeft: '10px' }}
            >
              Learn more about console options
            </Typography>
          </ConsoleOptionsButton>
          <LearnMoreDrawer
            type="Console"
            open={open}
            toggleDrawer={toggleDrawer}
            options={options}
            selectedProductSku={selectedProductSku}
          />
        </>
      )}
    </>
  )
}

ConsoleOptions.propTypes = {
  productTitle: PropTypes.string.isRequired,
  badgeNumber: PropTypes.number.isRequired,
  basePrice: PropTypes.number.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      imageSrc: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  itemComponent: PropTypes.any,
  selectedProductSku: PropTypes.string,
  additionalTitle: PropTypes.string,
  collection: PropTypes.string,
}

export default ConsoleOptions
