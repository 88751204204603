import React from 'react'
import PropTypes from 'prop-types'
import {
  styled,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  Typography,
} from '@mui/material'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { Add, Remove } from '@mui/icons-material'
import { useTest } from '@hooks/useTest'
import ProductOptionHeader, { ProductOptionHeaderTypes } from '../../molecules/PDP/ProductOptionHeader'

const CustomExpandIcon = ({ dataTestid }) => (
  <Box
    sx={{
      '.Mui-expanded & > .collapsIconWrapper': {
        display: 'none',
      },
      '.expandIconWrapper': {
        display: 'none',
      },
      '.Mui-expanded & > .expandIconWrapper': {
        display: 'block',
      },
    }}
    data-testid={dataTestid}
  >
    <Remove className="expandIconWrapper" />
    <Add className="collapsIconWrapper" />
  </Box>
)

CustomExpandIcon.propTypes = {
  dataTestid: PropTypes.string,
}

const StyledAccordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(props => ({
  '&:before': {
    opacity: 0,
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-root': {
      opacity: 1,
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'none',
  },
  marginBottom: props.isExpanded || props.defaultExpanded ? '20px !important' : '0',
}))

const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: prop => !['isExpanded', 'defaultExpanded'].includes(prop),
})(({ theme, isExpanded, defaultExpanded }) => ({
  alignItems: 'flex-start',
  paddingLeft: 0,
  paddingRight: theme.spacing(1),
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginTop: '0.75rem',
  },
  '& .Mui-expanded .sub-title': {
    display: 'none',
    visibility: 'hidden',
  },
  '& .MuiAccordionSummary-content': {
    margin: !isExpanded || !defaultExpanded ? '20px 0 0 0' : '0 20px 0',
    '& > div:first-of-type': {
      marginBottom: !isExpanded || !defaultExpanded ? '15px' : '0',
    },
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'capitalize',
}))

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
  '& .MuiLink-underlineHover:hover': {
    textDecoration: 'none',
  },
}))

function Accordion({
  selectionGroup,
  selectionCount,
  className,
  children,
  defaultExpanded,
  disabled,
  isExpanded,
  handleChange,
  subTitle,
  ...productOptionHeaderProps
}) {
  const { generateTestId } = useTest()
  const { title } = productOptionHeaderProps
  return (
    <StyledAccordion
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      className={className}
      onChange={(_, expanded) => {
        handleChange?.()
        addToDataLayer_nextgen(`selections_${expanded ? 'open' : 'close'}`, {
          selection_group: selectionGroup,
          selection_count: selectionCount,
        })
      }}
      isExpanded={isExpanded}
      data-testid={generateTestId('AccordionContainer', title)}
    >
      <StyledAccordionSummary
        expandIcon={!disabled ? <CustomExpandIcon dataTestid={generateTestId('accordionexpandbutton', title)} /> : null}
      >
        <Stack direction="column" gap={2}>
          <ProductOptionHeader hasOptions={selectionCount > 1} {...productOptionHeaderProps} />
          {subTitle && (
            <StyledTypography className="sub-title" data-testid={generateTestId('selectedoption', title)}>
              {subTitle}
            </StyledTypography>
          )}
        </Stack>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  )
}

Accordion.propTypes = {
  selectionGroup: PropTypes.string,
  selectionCount: PropTypes.number,
  defaultExpanded: PropTypes.bool,
  disabled: PropTypes.bool,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  ...ProductOptionHeaderTypes,
}

export default Accordion
