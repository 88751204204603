import _last from 'lodash/last'
import { setLocation, setShippingAddress } from '@redux/modules/location'
import { getLocation } from '@services/location'
import { getURLParam } from '@helpers/general'
import { abbreviateState } from '@helpers/geo-location'
import { regionToZipCode } from '@templates/constants'
import { productUrl } from '@helpers/route'
import { setupAnalytics, analyticsProduct } from '@helpers/google-tag-manager'

export const checkPLALocation = async (dispatch, regionIdParam) => {
  const regionId = regionIdParam || getURLParam('region_id')
  if (regionId) {
    const { zipCode } = regionToZipCode[regionId]
    const { response } = await getLocation(zipCode)
    if (response) {
      dispatch(setLocation(response))
      dispatch(setShippingAddress(`${response.city}, ${abbreviateState(response.state)}`))
    }
  }
}

export const changeLocation = (location, dispatch) => {
  if (location && typeof dispatch === 'function') {
    dispatch(setLocation(location))
    dispatch(setShippingAddress(`${location.city}, ${abbreviateState(location.state)}`))
  }
}

export const getPLARoute = (productData, additionalQueryParams = {}) => {
  const lastBreadcrumb = _last(productData?.breadCrumbs)
  if (lastBreadcrumb?.url) {
    const queryParams = new URLSearchParams({
      ...additionalQueryParams,
      ...(productData?.sku && { campaign_sku: productData?.sku }),
    })
    return `${lastBreadcrumb?.url}?${queryParams}`
  }

  const sub_category = _last(productData?.sub_category)
  const color_family = _last(productData?.color_family)
  const queryParams = new URLSearchParams({
    ...additionalQueryParams,
    ...(productData?.sku && { campaign_sku: productData?.sku }),
    ...(productData?.category && { category: productData?.category }),
    ...(sub_category && { sub_category }),
    ...(color_family && { color_family }),
  })
  return `/search?page=1&query=%20&${queryParams}`
}

export const generateSeeInRoomObjects = see_in_room => {
  const image =
    see_in_room?.primary_image ||
    see_in_room?.grid_image ||
    see_in_room?.primary_image_item ||
    see_in_room?.grid_image_item

  return see_in_room && image
    ? {
        image,
        link: productUrl(see_in_room?.route, see_in_room?.sku),
        title: see_in_room?.title,
        product: see_in_room,
      }
    : {}
}

export const pageAnalytics = product => {
  if (window && product) {
    setupAnalytics({
      pageData: {
        type: 'product',
        title: product.title,
        path: productUrl(product.title, product.sku),
      },
    })
    window.dataLayer.push({
      event: 'ee_detail',
      ecommerce: { detail: { products: [analyticsProduct(product)] } },
    })
  }
}
