/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import RTGLink from '@shared/link'
import SimpleSlider from '@shared/slider'
import RecentlyViewed from '@shared/recently-viewed'
import Banner from '@templates/strapi-cms/content-types/Banner'
import SimilarItemsV2 from '@shared/similar-items-v2'
import { trackImpression } from '@helpers/google-tag-manager'
import { formatReviews } from '@helpers/reviews'
import { filter, isEmpty } from 'lodash'
import { InView } from 'react-intersection-observer'
import { getRegionSkuList, productAvailability, sortByPriceDescending } from '@helpers/product'
import { getRegionZone } from '@helpers/geo-location'
import ProductReviews from '../product-parts/product-reviews'
import ProductTile from '../product-tile'
import ProductJsonLd from '../product-json-ld'
import ProductTitlePricing from '../product-parts/product-title-pricing'
import { FETCH_PROPERTIES, useProduct } from '../hooks'

function BelowTheFold({
  product,
  bannerBySku,
  see_in_room,
  similarHeading,
  bannerPlacementMiddle,
  financeBanners,
  financeRegion,
}) {
  const { region, zone } = getRegionZone()
  const also_in_collection = getRegionSkuList(product.also_in_collection, region)

  const { sku, title, image, isMattressOnly, subCategory, reviews } = useProduct(
    { product },
    FETCH_PROPERTIES.PRODUCT_REVIEWS,
  )

  const formattedReviews = useMemo(() => formatReviews({ title, image, reviewsData: reviews }), [image, reviews, title])

  let available_complete_your_room_items = product.complete_your_room_items
    ? product.complete_your_room_items.filter(p => productAvailability(p))
    : null
  available_complete_your_room_items = sortByPriceDescending(available_complete_your_room_items)
  let available_you_may_also_like_items = product.you_may_also_like_items
    ? product.you_may_also_like_items.filter(p => productAvailability(p))
    : null
  available_you_may_also_like_items = sortByPriceDescending(available_you_may_also_like_items)

  // returns all banners associated with this sku from the graph query of all banners by sku
  const skuBanners = filter(bannerBySku, obj => obj?.node?.SKUs?.strapi_json_value?.includes(product.sku))
  // if there are multiple banners associated with this sku, fallback to use generic PDP banner
  const skuBanner = skuBanners.length === 1 ? skuBanners[0]?.node : undefined
  return (
    <div>
      {also_in_collection && also_in_collection.length > 0 && (
        <div className="grid-container">
          <div className="product-collection-slider grid-x grid-y grid-margin-x grid-margin-y grid-padding-y">
            <div className="cell small-12">
              <SimpleSlider
                data={{
                  heading: 'ALSO IN THIS COLLECTION',
                }}
                noExtraMargin
              >
                {also_in_collection.map((item, index) => (
                  <ProductTile
                    sku={item.sku}
                    key={`collection_items_${item.sku}`}
                    viewType="grid"
                    index={index}
                    showIncludes={false}
                    source="also-in-collection"
                  />
                ))}
              </SimpleSlider>
            </div>
          </div>
        </div>
      )}
      {available_complete_your_room_items && available_complete_your_room_items.length > 0 && (
        <div className="grid-container">
          <div className="product-collection-slider grid-x grid-y grid-margin-x grid-margin-y grid-padding-y">
            <div className="cell small-12">
              <SimpleSlider
                data={{
                  heading: 'Complete Your Room',
                }}
                noExtraMargin
              >
                {available_complete_your_room_items.map((item, index) => (
                  <ProductTile
                    sku={item.sku}
                    key={`available_complete_your_room_items_${item.sku}`}
                    viewType="grid"
                    index={index}
                    showIncludes={false}
                    source="complete-your-room"
                  />
                ))}
              </SimpleSlider>
            </div>
          </div>
        </div>
      )}
      {see_in_room && Object.keys(see_in_room).length > 0 && !product.single_item_room && product.see_in_room && (
        <div className="product-in-room cell small-12 grid-x grid-margin-y grid-padding-y">
          <div className="cell small-12">
            <div className="cell small-12 header">
              <h2>SEE THE ROOM</h2>
            </div>
            <div className="cell small-12 view-all-link">
              <RTGLink
                className=" "
                data={{
                  heading: 'SEE IN A ROOM',
                  slug: see_in_room.link,
                  text: 'BUY THE ROOM & SAVE >',
                }}
              />
            </div>
            <RTGLink
              data={{
                slug: see_in_room.link,
                title: 'See in Room',
                category: 'pdp',
                action: 'see in room click',
                label: 'see-in-room',
              }}
            >
              <img className="see-in-room-slider-img" src={see_in_room.image} alt={see_in_room.product.title} />
            </RTGLink>
            <ProductTitlePricing product={see_in_room.product} showFinance={false} moreInfo />
          </div>
        </div>
      )}
      {available_you_may_also_like_items && available_you_may_also_like_items.length > 0 && (
        <div className="grid-container">
          <div className="product-collection-slider grid-x grid-y grid-margin-x grid-margin-y grid-padding-y">
            <div className="cell small-12">
              <SimpleSlider
                data={{
                  heading: 'You May Also Like',
                }}
                noExtraMargin
              >
                {available_you_may_also_like_items.map((item, index) => (
                  <ProductTile
                    sku={item.sku}
                    key={`available_you_may_also_like_items_${item.sku}`}
                    viewType="grid"
                    index={index}
                    showIncludes={false}
                    source="may-also-like"
                    route={item.route}
                  />
                ))}
              </SimpleSlider>
            </div>
          </div>
        </div>
      )}
      {/* Similar Items for items PDP */}
      <SimilarItemsV2 heading={similarHeading} product={product} similarItems={product?.similarItems} />
      {/* Banner specific to this sku */}
      {skuBanner?.Banner && <Banner data={skuBanner?.Banner} />}
      {/* Shows default banner for PDP if no sku specific banner exists */}
      {!skuBanner && bannerPlacementMiddle && <Banner data={bannerPlacementMiddle} />}
      <InView onChange={inView => trackImpression(inView, 2, { title: 'product recently viewed' })} triggerOnce>
        <RecentlyViewed sku={product.sku} data={{ ShowRecentlyViewed: true }} />
      </InView>
      {financeBanners?.[financeRegion] && <Banner data={financeBanners?.[financeRegion]} />}
      <ProductJsonLd product={product} />
    </div>
  )
}

export default BelowTheFold
