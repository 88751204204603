import React from 'react'
import { string, shape, array, bool } from 'prop-types'
import { productPrice } from '@helpers/product'
import { stripHtml } from '@helpers/string-helper'
import { productUrl } from '@helpers/route'
import { useServerSideLocation } from '@context/ServerSideLocation'

export default function ProductJsonLd({ product }) {
  const location = useServerSideLocation()
  const priceLocation = location?.region === 'OOM' ? 'FL' : location?.region

  const data = `{
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": "${product?.seoTitle ?? product?.title}",
    "image": "${[].concat(product.primary_image).concat(product.alternate_images)}",
    "description": "${product.description ? stripHtml(product.description) : ''}",
    "sku": "${product.sku}",
    "brand": {
      "@type": "Thing",
      "name": "${product.brand ? product.brand : 'Rooms To Go'}"
    },
    "offers": {
      "@type": "Offer",
      "url": "${typeof window !== 'undefined' ? window.location.origin : 'https://www.roomstogo.com'}${productUrl(
    product.route,
  )}",
      "priceCurrency": "USD",
      "price": "${productPrice(product, false, priceLocation, null)}",
      "itemCondition": "http://schema.org/NewCondition",
      "seller": {
        "@type": "Organization",
        "name": "Rooms To Go"
      }
    }
  }`

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: data }} />
}

ProductJsonLd.propTypes = {
  product: shape({
    title: string,
    primary_image: string,
    alternate_images: array,
    description: string,
    sku: string,
    brand: string,
  }),
}
