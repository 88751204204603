import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { GROWTHBOOK_PROMO_FINANCING } from '@constants/growthbook-flags'
import { productPrice, productAvailability } from '@helpers/product'
import { currencyFormatUS } from '@helpers/string-helper'
import { getRegionZone } from '@helpers/geo-location'
import { fetchPromotions } from '@services/checkout'
import { productFinancing } from '@helpers/finance'
import { productUrl } from '@helpers/route'
import RTGLink from '../../shared/link'
import ProductAddToCartSection from './product-add-to-cart-section'
import ProductFinancing from './product-financing'
import PriceAtom from '../../../@rtg2022/components/atoms/Price/Price'

const ProductPromotion = props => {
  const { product, promo, promoDescription, qualifierQuantity, targetQuantity, promoType, addons, activeAddons } = props
  const [promotion, setPromotion] = useState(product?.promotionData)
  const [finance, setFinance] = useState(null)
  const promoFinancing = useFeatureValue(GROWTHBOOK_PROMO_FINANCING)

  const isBuyXAndYGetZ = useCallback(() => promoType === 'BUY X AND Y GET Z DISCOUNT', [promoType])
  const setupPromo = useCallback(() => {
    if (promo && targetQuantity && qualifierQuantity) {
      const requestItems = [
        {
          sku: product.sku,
          quantity: parseInt(qualifierQuantity),
        },
        {
          sku: promo.sku,
          quantity: isBuyXAndYGetZ() ? 2 : parseInt(targetQuantity),
        },
      ]

      fetchPromotions({
        requestItems,
        region: getRegionZone().region,
        zone: getRegionZone().zone,
      }).then(data => {
        setPromotion(data)
        const newFinance = productFinancing(data.cartTotal, product?.delivery_type, false, true, 0, promoFinancing)
        setFinance(newFinance)
      })
    }
  }, [isBuyXAndYGetZ, product.delivery_type, product.sku, promo, qualifierQuantity, targetQuantity, promoFinancing])

  useEffect(() => {
    setupPromo()
  }, [setupPromo, product])

  const promoRoute = promo?.route ? promo.route : null

  const targetProduct = promotion
    ? promotion.lineItems.find(item => !item.promotionStatus) ||
      promotion.lineItems.find(item => item.promotionStatus === 'UTILIZED')
    : null

  // const targetProduct = promotion ? promotion.lineItems.find(item => !item.promotionStatus) : null
  const promoProduct = promotion ? promotion.lineItems.find(item => item.promotionStatus === 'APPLIED') : null
  const minSavingsToShowSaveText = 50
  const shouldShowStrikethroughPrice = product?.strikethrough?.[getRegionZone().region] || true

  return (
    <>
      {promotion && promotion.totalSavings > 0 && (
        <div className="promotion-entry grid-x card">
          <div className="cell small-12 medium-6 large-9 grid-x">
            {/* Qualifier Content */}
            <div
              className={`cell card promotion-product small-12 medium-12 grid-x ${
                isBuyXAndYGetZ() ? 'large-3' : 'large-5'
              }`}
            >
              {qualifierQuantity > 1 && <div className="promo-count">qty: {qualifierQuantity}</div>}
              <div className="cell promotion-image-container small-3">
                <img
                  src={`${product.grid_image ? product.grid_image : product.primary_image}&h=150`}
                  alt={product.title ? product.title : product.sku}
                />
              </div>
              <div className="cell promotion-title-container small-9 large-9 grid-x">
                <div className="cell small-12">
                  <span className="promo-title" dangerouslySetInnerHTML={{ __html: product.title }} />
                </div>
                <div className="cell small-12">
                  <p className="product-price">
                    <PriceAtom price={currencyFormatUS(productPrice(product))} />
                  </p>
                </div>
              </div>
            </div>

            {/* Divider */}
            <span className="cell plus small-12 medium-1">+</span>

            {isBuyXAndYGetZ() && (
              <>
                <RTGLink
                  data={{
                    slug: productUrl(promoRoute),
                    altDesc: promo.title ? promo.title : '',
                  }}
                  className={`card pop-out promotion-product small-12 medium-12 grid-x ${
                    isBuyXAndYGetZ() ? 'large-3' : 'large-5'
                  }`}
                >
                  {targetQuantity > 1 && <div className="promo-count">qty: {targetQuantity}</div>}
                  <div className="promotion-image-container small-3">
                    <img src={`${promo.image}&h=150`} alt={promo.title ? promo.title : promo.sku} />
                  </div>
                  <div className="cell promotion-title-container small-9 grid-x">
                    <div className="cell small-12">
                      <span className="promo-title" dangerouslySetInnerHTML={{ __html: promo.title }} />
                    </div>
                    {promotion.lineItems && promotion.bonusBuyTotal >= 0 && (
                      <div className="cell small-12">
                        <p className="product-price">
                          <PriceAtom
                            price={currencyFormatUS(
                              promotion.lineItems.length > 2
                                ? promotion.lineItems[1].unitPrice
                                : promotion.lineItems[0].unitPrice,
                            )}
                          />
                        </p>
                      </div>
                    )}
                  </div>
                </RTGLink>
                <span className="cell plus small-12 medium-1">+</span>
              </>
            )}

            {/* Target Content */}
            <RTGLink
              data={{
                slug: productUrl(promoRoute),
                altDesc: promo.title ? promo.title : '',
              }}
              className={`card pop-out promotion-product small-12 medium-12 grid-x ${
                isBuyXAndYGetZ() ? 'large-3' : 'large-5'
              }`}
            >
              {targetQuantity > 1 && <div className="promo-count">qty: {targetQuantity}</div>}
              <div className="promotion-image-container small-3">
                <img src={`${promo.image}&h=150`} alt={promo.title ? promo.title : promo.sku} />
              </div>

              <div className="cell promotion-title-container small-9 grid-x">
                <div className="cell small-12">
                  <span className="promo-title" dangerouslySetInnerHTML={{ __html: promo.title }} />
                </div>
                {promotion.lineItems && promotion.bonusBuyTotal >= 0 && (
                  <div className="cell small-12">
                    <p className="product-price">
                      {shouldShowStrikethroughPrice && (
                        <PriceAtom price={currencyFormatUS(targetProduct.unitPrice)} strikeThrough />
                      )}
                      <PriceAtom className="strikethrough-sale" price={currencyFormatUS(promoProduct.bonusPrice)} />
                    </p>
                  </div>
                )}
              </div>
            </RTGLink>
          </div>

          {/* Summary Content */}
          <div className="cell small-12 medium-5 large-3 grid-x center promo-savings-container">
            <div className="cell small-12">
              {promotion.totalSavings >= minSavingsToShowSaveText && (
                <p className="promo-total-savings">
                  {'SAVE '}
                  <PriceAtom price={currencyFormatUS(promotion.totalSavings)} />
                </p>
              )}
              <p className="product-price">
                <PriceAtom className="strikethrough-sale" price={currencyFormatUS(promotion.cartTotal)} />
              </p>
              {finance && finance.showFinance && finance.financeAmount > 0 && (
                <ProductFinancing numberOfMonths={finance.financeNumMonths} financeAmount={finance.financeAmount} />
              )}
            </div>
            <div className="cell small-12">
              <ProductAddToCartSection
                availability={productAvailability(product)}
                product={product}
                price={productPrice(product)}
                hideShipping
                promoDescription={promoDescription}
                promoItems={[promo]}
                promoQualifierQuantity={qualifierQuantity}
                promoTargetQuantity={isBuyXAndYGetZ() ? 2 : targetQuantity}
                promoTargetPrice={promoProduct.bonusPrice}
                promoStrikethroughPrice={targetProduct.unitPrice}
                buttonText="add offer to cart"
                stockMessage="Bonus Buy"
                addons={addons}
                activeAddons={activeAddons}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

ProductPromotion.propTypes = {
  promoType: PropTypes.string,
  product: PropTypes.object,
  promo: PropTypes.object,
  promoDescription: PropTypes.string,
  qualifierQuantity: PropTypes.string,
  targetQuantity: PropTypes.string,
  addons: PropTypes.array,
  activeAddons: PropTypes.array,
}

export default ProductPromotion
