import React from 'react'
import PropTypes from 'prop-types'
import { productPrice } from '@helpers/product'
import { getStoreName } from '@helpers/store-locator'
import { getCurrentLocation } from '@helpers/geo-location'
import { addToDataLayer } from '@helpers/google-tag-manager'
import { saveLocalStorage } from '@helpers/storage'
import ProductJsonLd from '../product-json-ld'
import RTGLink from '../../shared/link'
import ProductLIAStoreInfo from './product-lia-store-info'
import '../../../assets/css/components/product/product-lia/product-lia.sass'

export default class ProductLIA extends React.Component {
  getStorePath = store => (store.StoreName ? `-${store.StoreName.toLowerCase().replace(' ', '-')}` : '')

  handleClick = async (event, store) => {
    event.preventDefault()
    const zip = store.Zip
    // const err = await geoLocation(zip, false)
    const err = false
    /* This is a just-in-case; all stores should have a valid zip code, so there shouldn't ever be an error */
    if (err) {
      // eslint-disable-next-line no-alert
      alert('We apologize, but some items are out of stock or unavailable for purchase in the specified zip code.')
    } else {
      saveLocalStorage('changeLocReturn', 'changeLocHeaderBtn')
      addToDataLayer('click', 'LIA', 'shipping change', `${zip},${getCurrentLocation().zip}`)
      window.location.assign(window.location.pathname)
    }
  }

  render() {
    const { product, store, region, zone, available } = this.props
    let storeName
    if (store) {
      storeName = getStoreName(store)
    }
    if (store) {
      return (
        <>
          <div className="product-lia grid-x">
            <div className="product-img small-12 large-6">
              {product && (
                <span className="product-image">
                  {(product.primary_image || product.grid_image) && (
                    <img
                      src={`${product.primary_image || product.grid_image}&h=385`}
                      className="small-image"
                      alt={product.title}
                    />
                  )}
                </span>
              )}
            </div>
            {product.description && (
              <div className="product-desc small-12 large-6">
                <ProductLIAStoreInfo
                  product={product}
                  store={store}
                  region={region}
                  zone={zone}
                  available={available}
                />
                <h1 className="product-title" dangerouslySetInnerHTML={{ __html: product.title }} />
                <span className="price">${productPrice(product, null, region, zone)}</span>
                <RTGLink
                  data={{
                    slug: `${typeof window !== 'undefined' && window.location.pathname}`,
                    category: 'product-lia',
                    action: 'click',
                    label: 'buy now',
                  }}
                  className="blue-action-btn"
                  onClick={e => this.handleClick(e, store)}
                >
                  Buy Now
                </RTGLink>
                <h2>product description</h2>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: product.description,
                    }}
                  />
                </div>
                <div className="small-12">
                  {product.dimensions && <div className=" product-dim-sku">{`Dimensions: ${product.dimensions}`}</div>}
                </div>
                <div className="small-12 product-dim-sku">SKU: {product.sku && product.sku.toUpperCase()}</div>
              </div>
            )}
          </div>
          <ProductJsonLd product={product} />
        </>
      )
    }
    return null
  }
}

ProductLIA.propTypes = {
  available: PropTypes.bool,
  product: PropTypes.object,
  region: PropTypes.string,
  store: PropTypes.object,
  zone: PropTypes.object,
}
