import React, { useEffect, useState } from 'react'
import { array, number, object } from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Box, styled } from '@mui/material'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import { getCurrentLocation } from '@helpers/geo-location'
import { productUrl } from '@helpers/route'
import { useTest } from '@hooks/useTest'
import { fetchProductWarehouseAvailability } from '@services/product'
import RTGLink from '@shared/link'
import ProductRoomCard from './product-room-card'

const SwiperContainer = styled(Box)(({ theme }) => ({
  '& .swiper-container': {
    height: '412px',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
    },
  },
  '& .swiper-slide': {
    width: '389px',
  },
  '& .swiper-button-disabled:focus': {
    outline: 'none',
  },
  '& .swiper-button-disabled svg': {
    color: theme.palette.secondary.light,
  },
  '& .swiper-slide-active': {
    '& .MuiPaper-outlined': {
      borderColor: theme.palette.common.white,
      borderWidth: '1px',
      transition: 'border-color 0.5s ease-in-out',
    },
  },
  '& .swiper-scrollbar': {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

const PaginationContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '10px',
})

const PaginationDiv = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  justifyContent: 'center',
  '& .swiper-pagination-bullet': {
    width: '24px',
    height: '24px',
    textAlign: 'center',
    lineHeight: '23px',
    fontSize: '20px',
    color: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'none',
    margin: '0px 7px',
    opacity: '1',
    outline: 'none',
  },
  '& .swiper-pagination-bullet-active': {
    color: theme.palette.primary.main,
    background: theme.palette.primary.main,
  },
}))

const NavBtn = styled(Box)({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
})

const RoomOptionTile = ({ index, upgrade = {} }) => {
  const { generateTestId } = useTest()
  const { analyticsProduct, availability_date, displayNamePDP, items, price, room_savings, route, sku, title } = upgrade
  const [availability, setAvailability] = useState(availability_date || '')
  const { distribution_index, state } = getCurrentLocation()

  useEffect(() => {
    fetchProductWarehouseAvailability(sku, distribution_index, state)
      .then(res => res.data.earliestAvailability)
      .then(date => setAvailability(date))
      .catch(err => console.error({ err }))
  }, [sku, distribution_index, state])

  return (
    <RTGLink
      className="cell"
      data={{
        slug: productUrl(route),
        title: sku,
        category: 'product-upgrade',
        action: 'upgrade click',
        label: sku,
      }}
      noTabIndex
      trackingData={{
        event: 'ee_click',
        ecommerce: {
          click: {
            list: `upgrade_other_room_options`,
            position: index + 1,
            products: [analyticsProduct],
          },
        },
      }}
      data-testid={generateTestId('otherroomoption', 'card')}
    >
      <ProductRoomCard
        availability_date={availability}
        items={items}
        price={price}
        room_savings={room_savings}
        title={displayNamePDP || title}
      />
    </RTGLink>
  )
}

RoomOptionTile.propTypes = {
  index: number,
  upgrade: object,
}

const ProductUpgradesSwiper = ({ upgrades = [] }) => (
  <SwiperContainer id="room-options-swiper">
    <Swiper
      slidesPerGroup={1}
      slidesPerView="auto"
      grabCursor
      centeredSlides={false}
      navigation={{
        nextEl: '.next-slide',
        prevEl: '.prev-slide',
      }}
      pagination={{
        el: '.custom-pagination',
        clickable: true,
        renderBullet: (index, className) => `<span class='${className}'></span>`,
      }}
      spaceBetween={20}
      scrollbar={{
        draggable: true,
        hide: false,
        snapOnRelease: true,
      }}
    >
      {upgrades?.map((upgrade, index) => (
        <SwiperSlide
          key={`upgrade_other_room_options_${upgrade?.sku}`}
          data-hash={`upgrade_other_room_options_${upgrade?.sku}`}
        >
          <RoomOptionTile index={index} upgrade={upgrade} />
        </SwiperSlide>
      ))}
    </Swiper>
    {upgrades.length > 2 && (
      <PaginationContainer>
        <NavBtn className="prev-slide" type="button">
          <ArrowLeftIcon htmlColor="#0053a0" fontSize="large" />
        </NavBtn>
        <PaginationDiv className="custom-pagination" />
        <NavBtn className="next-slide" type="button">
          <ArrowRightIcon htmlColor="#0053a0" fontSize="large" />
        </NavBtn>
      </PaginationContainer>
    )}
  </SwiperContainer>
)

ProductUpgradesSwiper.propTypes = {
  upgrades: array,
}

export default ProductUpgradesSwiper
