import React from 'react'
import { any, arrayOf, object } from 'prop-types'
import { Box, Grid, Typography, styled, useMediaQuery } from '@mui/material'
import { useTest } from '@hooks/useTest'
import ProductUpgradesSwiper from './product-upgrades-swiper'
import ProductRoomCard from './product-room-card'

const RoomOptionsContainer = styled(Grid)(({ theme }) => ({
  display: 'inline-block',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
}))

const ProductUpgradesV2 = props => {
  const { generateTestId } = useTest()
  const { upgrades = [], selectedUpgrade = null } = props
  const isTablet = useMediaQuery('(max-width:768px)')
  if (selectedUpgrade?.items.length > 0) {
    selectedUpgrade.items.map(item => {
      if (item?.image) {
        return {
          ...item,
          image: `${item.image}&h=52`,
        }
      }
      return item
    })
  }

  return (
    <Box pb={2} flexGrow={1}>
      <RoomOptionsContainer container>
        <Grid item md style={{ maxWidth: 406 }}>
          <Box
            mt={2}
            pr={isTablet ? 0 : 2}
            style={{ borderRight: isTablet ? 'none' : '1px solid #979797', height: '100%' }}
          >
            <Typography variant="h6" display="inline" data-testid={generateTestId('selectedroom', 'cardtitle')}>
              <strong>Selected Room</strong>
            </Typography>

            <Box pt={1} data-testid={generateTestId('selectedroom', 'card')}>
              <ProductRoomCard
                active
                items={selectedUpgrade?.items}
                price={
                  selectedUpgrade?.pdp_price && selectedUpgrade.pdp_price < selectedUpgrade?.price
                    ? selectedUpgrade.pdp_price
                    : selectedUpgrade?.price
                }
                room_savings={selectedUpgrade?.room_savings}
                title={selectedUpgrade?.displayNamePDP || selectedUpgrade?.title}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md style={{ overflow: 'hidden' }}>
          <Box mt={2} pl={isTablet ? 0 : 2}>
            <Typography variant="h6" display="inline">
              <div data-testid={generateTestId('otherroom', 'optionstitle')}>
                <strong>Other Room Options</strong>&nbsp;({upgrades?.length})
              </div>
            </Typography>
            <Box pt={1}>
              <ProductUpgradesSwiper upgrades={upgrades} />
            </Box>
          </Box>
        </Grid>
      </RoomOptionsContainer>
    </Box>
  )
}

ProductUpgradesV2.propTypes = {
  upgrades: arrayOf(any),
  selectedUpgrade: object,
}

export default ProductUpgradesV2
