import React from 'react'
import PropTypes from 'prop-types'
import { Box, css, styled, Typography } from '@mui/material'

const Wrapper = styled(Box)(
  ({ theme, selected }) => css`
    display: inline-block;
    border: 1px solid ${theme.palette.secondary.main};
    border-radius: 25px;
    padding: 10px;

    .Pill {
      &__label {
        line-height: 20px;
      }
    }

    ${selected && selectedStyles(theme)}

    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.secondary.main};
    }
  `,
)

const selectedStyles = theme => css`
  border-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
`

const Pill = ({ label, selected }) => (
  <Wrapper selected={selected}>
    <Typography className="Pill__label">{label}</Typography>
  </Wrapper>
)

Pill.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
}

export default Pill
