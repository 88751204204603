import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Link, useTheme } from '@mui/material'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { currencyFormatUS } from '@helpers/string-helper'
import { productAvailability, productOnSale, productPrice } from '@helpers/product'
import { productUrl } from '../../../../lib/helpers/route'
import Accordion from './Accordion'
import BoxButton from '../../atoms/BoxButton/BoxButton'

function SleeperOptions({ options = [], itemComponent, selectedProductSku, additionalTitle, subTitle, pdpPrice }) {
  const ItemComponent = itemComponent || Link
  const theme = useTheme()
  return (
    <Accordion
      selectionGroup="sleeper"
      selectionCount={options.length}
      title="Sleeper:"
      additionalTitle={additionalTitle || `${options.length} ${options.length > 1 ? 'Options' : 'Option'}`}
      subTitle={subTitle}
    >
      <Stack direction="column" gap={1}>
        {options.map((option, index) => {
          const sku = option?.sku || option?._id
          const isSelected = sku === selectedProductSku
          return (
            <ItemComponent
              onClick={() =>
                addToDataLayer_nextgen('ee_click', {
                  ecommerce: {
                    click: {
                      products: [
                        {
                          ...option,
                          price: option?.pdp_price,
                          on_sale: productOnSale(option),
                          availability: productAvailability(option),
                        },
                      ],
                      list: 'sleeper',
                      position: index,
                    },
                  },
                  selection_group: 'sleeper',
                  selection_clicked: option.label,
                  current_selection: options.find(i => i.sku === selectedProductSku)?.label,
                  selection_count: options.length,
                })
              }
              data={{
                InternalUrl: productUrl(option.route),
                id: `product-upgrade-link:${option.sku}`,
                Title: option?.label,
              }}
              key={option.sku}
              style={{ ...(isSelected && { pointerEvents: 'none' }) }}
            >
              <BoxButton
                label={option.label}
                selected={isSelected}
                additionalText={currencyFormatUS(
                  isSelected && pdpPrice && pdpPrice < option?.pdp_price ? pdpPrice : option?.pdp_price,
                )}
                additionalComponentStyles={{
                  ...(isSelected &&
                    pdpPrice &&
                    pdpPrice < option?.pdp_price && {
                      color: theme.palette.error.main,
                    }),
                }}
              />
            </ItemComponent>
          )
        })}
      </Stack>
    </Accordion>
  )
}

SleeperOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  itemComponent: PropTypes.any,
  selectedProductSku: PropTypes.string,
  additionalTitle: PropTypes.string,
  subTitle: PropTypes.string,
  pdpPrice: PropTypes.number,
}

export default SleeperOptions
