import React from 'react'
import { Helmet } from 'react-helmet'
import { object } from 'prop-types'
import { useProduct } from './hooks'
/*
  This component takes the product data and creates the open graph meta tags
  We will set the title, description, image, type, and url
  https://ogp.me/
*/

const ProductOpenGraph = ({ product }) => {
  const productData = useProduct({ product })
  const url = new URL(productData?.route, 'https://www.roomstogo.com')
  const ogImage = product?.app_image ?? productData?.image
  return (
    <Helmet>
      {productData?.title && <meta property="og:title" content={productData?.title} />}
      {productData?.image && <meta property="og:image" content={`${ogImage}&w=1200`} />}
      <meta property="og:type" content="website" />
      {productData?.route && <meta property="og:url" content={url.href} />}
      <meta property="og:site_name" content="Rooms to go" />
    </Helmet>
  )
}

ProductOpenGraph.propTypes = {
  product: object.isRequired,
}

export default ProductOpenGraph
