import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  styled,
  Grid,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { parseDescription } from '@helpers/product'
import { formatReviews } from '@helpers/reviews'
import _isEmpty from 'lodash/isEmpty'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { theme as themeStyles } from '@constants/styles'
import MattressDetails from './MattressDetails'
import ProductReviews from '../product-parts/product-reviews'
import { FETCH_PROPERTIES, useProduct } from '../hooks'

const StyledDescList = styled(`ul`)`
  list-style: disc;
`
const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '.MuiAccordionSummary-content': {
    color: '#333333',
    textTransform: 'uppercase',
  },
  '.MuiAccordion-root': {
    boxShadow: 'none',
    borderTop: '1px solid rgba(0,0,0,0.1)',
  },
  '.MuiAccordion-root:first-of-type': {
    borderTop: 'none',
  },

  '.MuiAccordionSummary-root.Mui-expanded': {
    margin: 0,
  },
  '.MuiAccordion-root.Mui-expanded': {
    margin: 0,
    '&::before': {
      position: 'absolute',
      content: '""',
      opacity: 1,
      left: 0,
      top: '-1px',
      right: 0,
      height: '1px',
      backgroundColor: 'rgba(0,0,0,0.12)',
    },
  },
}))

const StyledWarrantyLink = styled('a')(({ theme, multi }) => ({
  color: '#0053a0',
  border: '1px solid',
  borderRadius: '5px',
  padding: '3px 6px',
  fontSize: '14px',
  marginLeft: !multi && '10px',
}))

const WarrantyText = styled(Typography)(() => ({
  marginBottom: '16px',
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`product-description-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: { xs: 0, md: 3 } }}>
          <Typography sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: '1rem' }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `product-description-${index}`,
    'aria-controls': `product-description-tab-${index}`,
  }
}

/**
 *
 * @param {string} description
 * @param {string} dimensions
 * @param {string} comfort
 * @param {string} pressure_relief
 * @param {string} sleep_position
 * @param {string} support_level
 * @param {string} temperature_management
 * @param {string} size
 * @param {string} technology
 * @param {array} dimensionImage
 * @returns
 */
function DescriptionTab({
  dimensionImage,
  description,
  dimensions,
  comfort,
  pressure_relief,
  sleep_position,
  support_level,
  temperature_management,
  size,
  technology,
  warrantyDescription,
  warrantyLink,
  product,
}) {
  const [value, setValue] = React.useState(0)
  const { bullets, warranty } = parseDescription(description, warrantyDescription, true)
  const warrantyData = warranty || warrantyLink
  const { image, reviews, title } = useProduct({ product }, FETCH_PROPERTIES.PRODUCT_REVIEWS)
  const [index, setIndex] = useState([])

  useEffect(() => {
    const tabs = [dimensions, description, warrantyData, reviews]
    const tabArray = []
    tabs.forEach(tab => {
      if (tab || !_isEmpty) {
        tabArray.push(tab)
      }
    })
    setIndex(tabArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const descriptionHTML = (
    <Grid container>
      <Grid md={3} />
      <Grid xs={12} md={6}>
        <Typography sx={{ fontWeight: 700 }} variant="h6">
          Features
        </Typography>
        <StyledDescList sx={{ paddingLeft: '15px' }}>
          {bullets.map(b => (
            <li key={b}>{b}</li>
          ))}
        </StyledDescList>
      </Grid>
      <Grid md={3} />
    </Grid>
  )
  const warrantyHTML = (
    <Grid container>
      <Grid md={3} />
      <Grid xs={12} md={6}>
        {Array.isArray(warranty) && warranty.length > 1 ? (
          warranty.map(wty => <WarrantyText key={wty}>{wty}</WarrantyText>)
        ) : (
          <WarrantyText>{warranty}</WarrantyText>
        )}
        {warrantyLink && (
          <StyledWarrantyLink
            multi={Array.isArray(warranty) && warranty.length > 1}
            href={warrantyLink}
            target="_blank"
          >
            VIEW WARRANTY
            <span className="hide508">(opens in new window)</span>
          </StyledWarrantyLink>
        )}
      </Grid>
      <Grid md={3} />
    </Grid>
  )

  const productDetailsHTML = (
    <>
      <MattressDetails
        size={size}
        dimensions={dimensions}
        dimensionImage={dimensionImage}
        comfort={comfort}
        temperature_management={temperature_management}
        isMobile={isMobile}
        pressure_relief={pressure_relief}
        support_level={support_level}
        technology={technology}
        sleep_position={sleep_position}
      />
    </>
  )

  const formattedReviews = useMemo(() => formatReviews({ title, image, reviewsData: reviews }), [image, reviews, title])
  const reviewsHTML = (
    <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
      <Grid md={10}>
        <ProductReviews reviewsData={formattedReviews} />
      </Grid>
    </Grid>
  )

  const getIndex = tab => {
    if (index.includes(tab)) {
      return index.indexOf(tab)
    }
    return null
  }

  return isMobile ? (
    <StyledBox>
      {dimensions && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Product Details</AccordionSummary>
          <AccordionDetails>{productDetailsHTML}</AccordionDetails>
        </Accordion>
      )}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Description</AccordionSummary>
        <AccordionDetails>{descriptionHTML}</AccordionDetails>
      </Accordion>
      {warrantyData && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Warranty</AccordionSummary>
          <AccordionDetails sx={{ textWrap: 'balance' }}>{warrantyHTML}</AccordionDetails>
        </Accordion>
      )}
      {!_isEmpty(reviews) && (
        <Accordion>
          <AccordionSummary className="reviews-tab" expandIcon={<ExpandMoreIcon />}>
            Customer Reviews
          </AccordionSummary>
          <AccordionDetails>{reviewsHTML}</AccordionDetails>
        </Accordion>
      )}
    </StyledBox>
  ) : (
    <Box sx={{ width: '100%', backgroundColor: 'white' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="product overview"
          centered
          sx={{
            '.Mui-selected': { fontWeight: 'bold' },
            '.MuiTabs-indicator': { backgroundColor: themeStyles.accent },
          }}
        >
          {dimensions && <Tab label="Product Details" {...a11yProps(getIndex(dimensions))} />}
          <Tab label="Description" {...a11yProps(getIndex(description))} />
          {warrantyData && <Tab label="Warranty" {...a11yProps(getIndex(warrantyData))} />}
          {!_isEmpty(reviews) && (
            <Tab className="reviews-tab" label="Customer Reviews" {...a11yProps(getIndex(reviews))} />
          )}
        </Tabs>
      </Box>
      {dimensions && (
        <TabPanel value={value} index={getIndex(dimensions)}>
          {productDetailsHTML}
        </TabPanel>
      )}
      <TabPanel value={value} index={getIndex(description)}>
        {descriptionHTML}
      </TabPanel>
      {warrantyData && (
        <TabPanel value={value} index={getIndex(warrantyData)}>
          {warrantyHTML}
        </TabPanel>
      )}
      {!_isEmpty(reviews) && (
        <TabPanel value={value} index={getIndex(reviews)}>
          {reviewsHTML}
        </TabPanel>
      )}
    </Box>
  )
}

DescriptionTab.propTypes = {
  dimensionImage: PropTypes.array,
  description: PropTypes.string,
  dimensions: PropTypes.string,
  comfort: PropTypes.string,
  pressure_relief: PropTypes.string,
  sleep_position: PropTypes.string,
  support_level: PropTypes.string,
  temperature_management: PropTypes.string,
  size: PropTypes.string,
  technology: PropTypes.string,
  warrantyDescription: PropTypes.string,
  warrantyLink: PropTypes.string,
  product: PropTypes.object,
}

export default DescriptionTab
