import React from 'react'
import PropTypes from 'prop-types'
import { styled, Grid, Stack, Typography } from '@mui/material'

const GridStyled = styled(Grid)(({ theme }) => ({
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
  [theme.breakpoints.down('md')]: {
    lineHeight: '12px',
  },
  '&.uppercase': {
    color: theme.palette.primary.dark,
    fontWeight: '600',
    justifyContent: 'left',
  },
}))

const BasicDimensions = styled(Typography)(() => ({
  borderTop: '1px solid rgba(0,0,0,0.1)',
  padding: '1rem 0',
}))

const DimensionsTitle = styled(Grid)(() => ({
  border: 'none',
  padding: '0 0 5px 0',
}))

const StyledDimensionImage = styled('img')`
  max-width: 50%;
`

const StyledRoomImage = styled('img')`
  max-height: 100%;
  object-fit: contain;
`

const ItemName = styled(Typography)(() => ({
  textTransform: 'capitalize',
  textAlign: 'center',
  padding: '16px 0',
}))

const DimensionsTable = styled(Grid)(({ bluebg }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: bluebg % 2 === 0 && 'rgba(0, 83, 160, 0.04)',
  border: '1px solid rgba(128, 128, 128, .14)',
}))

const ItemContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '8rem',
  width: 'auto',
}))

const dimensionValues = ['width', 'depth', 'height', 'length', 'radius', 'diameter', 'adjustable length', 'square']

function parseDimensions(dimensions) {
  if (typeof dimensions === 'string') {
    return dimensions
  }

  // If itemizedDimension is an array of 1
  const dimensionItem = dimensions.length === 1 ? dimensions[0] : dimensions

  const reg = /[^0-9'".]/g
  const dimensionsObj = {}
  dimensionValues.map(val =>
    Object.defineProperty(dimensionsObj, val, {
      value: dimensionItem[val],
      // && `${dimensionItem[val].replace(reg, '')}`,
      enumerable: true,
    }),
  )

  return JSON.parse(JSON.stringify(dimensionsObj))
}

const RoomItemsGrid = ({ roomItems, isMobile }) => {
  const itemDimensions = roomItems.map(item => parseDimensions(item))
  const values = [...new Set(itemDimensions.flatMap(Object.keys))]

  return (
    <>
      <Grid container>
        <Grid item md={3} />
        <Grid item md={6}>
          <DimensionsTitle xs={12}>
            <Typography fontWeight={500} color="#757575" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
              Product Dimensions
            </Typography>
          </DimensionsTitle>
          {roomItems.map(item => (
            <Typography key="item">
              <span style={{ textTransform: 'capitalize' }}>{item.generic_name}: </span>
              {/* {`: ${values
                .map(value => parseDimensions(item)[value] && parseDimensions(item[value]))
                .filter(dim => dim !== undefined)
                .join(' x ')}`} */}
              {item.dimensions}
            </Typography>
          ))}
        </Grid>
        <Grid item md={3} />
      </Grid>
    </>
  )

  // isMobile ? (
  //   <>
  //     {roomItems.map(item => (
  //       <Grid container key="name" sx={{ padding: '16px 0' }}>
  //         <Grid item xs={12}>
  //           <ItemContainer>
  //             <StyledRoomImage src={`${item.image}&h=250`} />
  //           </ItemContainer>

  //           <ItemName>{item.genericName}</ItemName>
  //         </Grid>

  //         {Object.keys(parseDimensions(item)).map((dimension, index) => (
  //           <>
  //             <DimensionsTable bluebg={index} item xs={12}>
  //               <Grid container>
  //                 <GridStyled xs={6} className="uppercase">
  //                   {dimension}
  //                 </GridStyled>
  //                 <GridStyled xs={6}>{parseDimensions(item)[dimension]}</GridStyled>
  //               </Grid>
  //             </DimensionsTable>
  //           </>
  //         ))}
  //       </Grid>
  //     ))}
  //   </>
  // ) : (
  //   <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
  //     <div style={{ maxWidth: '60%' }}>
  //       <Grid
  //         container
  //         gap="0 4rem"
  //         sx={{
  //           display: 'flex',
  //           justifyContent: 'center',
  //           flexDirection: isMobile ? 'column-reverse' : 'row',
  //         }}
  //       >
  //         <Grid item xs />
  //         {roomItems.map(item => (
  //           <Grid item xs key="name">
  //             <ItemContainer>
  //               <StyledRoomImage src={`${item.image}&h=250`} />
  //             </ItemContainer>

  //             <ItemName>{item.genericName}</ItemName>
  //           </Grid>
  //         ))}
  //         <Grid item />
  //       </Grid>

  //       {values.map((value, index) => (
  //         <DimensionsTable container gap="0 4rem" bluebg={index} key="dimensionRow">
  //           <Grid item xs>
  //             <GridStyled className="uppercase">{value}</GridStyled>
  //           </Grid>
  //           {roomItems.map(item => (
  //             <Grid item key="dimensionValue" xs>
  //               <GridStyled>{parseDimensions(item)[value] || '-'}</GridStyled>
  //             </Grid>
  //           ))}
  //           <Grid item />
  //         </DimensionsTable>
  //       ))}
  //     </div>
  //   </div>
  // )
}

RoomItemsGrid.propTypes = {
  roomItems: PropTypes.array,
  isMobile: PropTypes.bool,
}

const Dimensions = ({ dimensions, dimensionImage, isMobile, roomItems }) => {
  // const hasRoomItems = Array.isArray(dimensions) && dimensions.length > 1

  // const explicitDimensions = !hasRoomItems && parseDimensions(dimensions)

  const showImage = !dimensionImage.includes(undefined)
  return (
    <>
      {/* {hasRoomItems ? ( */}
      {roomItems ? (
        <RoomItemsGrid roomItems={roomItems} isMobile={isMobile} />
      ) : (
        <Grid container sx={{ display: 'flex', justifyContent: 'center', flexDirection: isMobile && 'column-reverse' }}>
          <Grid xs={0} md={1} lg={2} />
          <Grid
            xs={12}
            md={showImage ? 4 : 6}
            lg={showImage ? 3 : 6}
            sx={{
              padding: { xs: '0', md: '0 30px 30px' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Grid container>
              <DimensionsTitle xs={12}>
                <Typography fontWeight={500} color="#757575">
                  Product Dimensions
                </Typography>
              </DimensionsTitle>
            </Grid>

            {typeof dimensions === 'string' ? (
              <BasicDimensions>{dimensions}</BasicDimensions>
            ) : (
              <></>
              // <Grid
              //   container
              //   sx={{
              //     '--Grid-borderWidth': '1px',
              //     border: 'var(--Grid-borderWidth) solid',
              //     borderBottom: 0,
              //     borderColor: 'divider',
              //     '& > div': {
              //       borderBottom: 'var(--Grid-borderWidth) solid',
              //       borderColor: 'divider',
              //       padding: '16px',
              //       '&.bluebg': {
              //         backgroundColor: 'rgba(0, 83, 160, 0.04)',
              //       },
              //       '&.text': {
              //         justifyContent: 'left',
              //       },
              //     },
              //     '& > div:not(.uppercase)': {
              //       textTransform: 'capitalize',
              //     },
              //   }}
              // >
              //   {Object.keys(explicitDimensions).map((dimension, index) => (
              //     <>
              //       <GridStyled xs={6} className={index % 2 === 0 ? 'uppercase text bluebg' : 'uppercase text'}>
              //         Total {dimension}
              //       </GridStyled>
              //       <GridStyled xs={6} className={index % 2 === 0 && 'bluebg'}>
              //         {dimension ? `${explicitDimensions[dimension]}` : '-'}
              //       </GridStyled>
              //     </>
              //   ))}
              // </Grid>
            )}
          </Grid>
          {showImage && (
            <Grid xs={12} md={6} lg={5} sx={{ padding: { xs: '0', md: '0 30px 30px' } }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ padding: { xs: '0 30px 20px', md: '30px' } }}>
                {dimensionImage.length > 1 ? (
                  <>
                    {dimensionImage[0] && <StyledDimensionImage src={dimensionImage[0]} alt={dimensions} />}
                    {dimensionImage[1] && <StyledDimensionImage src={dimensionImage[1]} alt={dimensions} />}
                  </>
                ) : (
                  dimensionImage[0] && <img src={dimensionImage[0]} alt={dimensions} />
                )}
              </Stack>
            </Grid>
          )}
          <Grid xs={0} md={1} lg={2} />
        </Grid>
      )}
    </>
  )
}

Dimensions.propTypes = {
  dimensions: PropTypes.string,
  dimensionImage: PropTypes.object,
  isMobile: PropTypes.bool,
  roomItems: PropTypes.array,
}

export default Dimensions
