import React from 'react'
import { array, string, object } from 'prop-types'
import { titleCase } from '@helpers/string-helper'
import { productUrl } from '@helpers/route'
import RTGLink from '@shared/link'
import { analyticsProduct } from '@helpers/google-tag-manager'
import SimpleSlider from '@shared/slider'
import './product-includes-slider.sass'

const ProductIncludesSlider = ({ items_in_room, heading, product }) => (
  <div className="includes-slider-container small-12">
    <SimpleSlider
      data={{
        heading,
        sliderSize: 'Small',
        label: 'Product Includes',
        headingLevel: '3',
      }}
      noExtraMargin
    >
      {items_in_room.map(item => (
        <div key={item.sku}>
          {item.image && item.generic_name && (
            <>
              {!item.title && (
                <div className="card">
                  <div className="slider-image-container">
                    <div className="slider-image">
                      <img src={`${item.image}&h=250`} alt="" role="presentation" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="slider-title">{`${titleCase(item.generic_name)}${
                    item.quantity > 1 ? ` (${item.quantity})` : ''
                  }`}</div>
                </div>
              )}
              {item.route && (
                <RTGLink
                  className="card"
                  noTabIndex
                  trackingData={{
                    event: 'ee_click',
                    action: 'click',
                    category: 'items in room',
                    label: product.sku,
                    ecommerce: {
                      click: {
                        list: 'items in room',
                        products: product,
                      },
                    },
                  }}
                  aria-describedby={`title${item.sku}`}
                  data={{
                    slug: productUrl(item.route),
                    title: 'Items In This Room',
                  }}
                >
                  <div className="slider-image-container">
                    <div className="slider-image">
                      <img src={`${item.image}&h=250`} alt="" role="presentation" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="slider-title">{`${titleCase(item.generic_name)}${
                    item.quantity > 1 ? ` (${item.quantity})` : ''
                  }`}</div>
                </RTGLink>
              )}
            </>
          )}
        </div>
      ))}
    </SimpleSlider>
  </div>
)

ProductIncludesSlider.propTypes = {
  items_in_room: array,
  heading: string,
  product: object,
}

export default ProductIncludesSlider
