import React, { useRef } from 'react'
import { string, number, bool, func, object } from 'prop-types'
import { Box, Typography, Stack, Fade, Slide, styled } from '@mui/material'
import { setOrderInfo } from '@helpers/checkout/global'
import ProductPrice from '../../../atoms/ProductPrice/ProductPrice'
import Button from '../../../atoms/Button/Button'
import ApplePayButton from '../../../../../components/checkout/checkout-parts/payment-section/apple-pay'
import { FAVORITE_BUTTON } from '../../../..'

SummaryPDP.propTypes = {
  availability: bool,
  name: string,
  price: string,
  sale: bool,
  strikeThrough: bool,
  strikeThroughPrice: string,
  showName: bool,
  financeAmount: number,
  numMonths: number,
  promotionalPrice: number,
  promotionalMonths: number,
  roomSavings: string,
  prequalifiedLink: string,
  freeShippingMessage: string,
  addToCartLabel: string,
  handleAddToCart: func,
  className: string,
  product: object,
  warrantyEnabled: bool,
  dataTestId: string,
  isFavorited: bool,
  handleFavoriteClick: func,
}

const ApplePayWrapper = styled('div')({
  display: 'flex',
  height: '40px',
})

export default function SummaryPDP({
  availability,
  name,
  price,
  sale,
  strikeThrough,
  strikeThroughPrice,
  showName = true,
  financeAmount,
  numMonths,
  promotionalPrice,
  promotionalMonths,
  roomSavings,
  prequalifiedLink,
  freeShippingMessage,
  addToCartLabel,
  handleAddToCart,
  className,
  product,
  warrantyEnabled,
  dataTestId,
  isFavorited,
  handleFavoriteClick,
}) {
  const containerNameRef = useRef(null)
  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      padding="16px"
      bgcolor="white"
      width="100vw"
      zIndex={2}
      borderTop="1px solid #dedede"
      className={className}
    >
      <Box ref={containerNameRef}>
        <Slide
          in={showName}
          direction="up"
          timeout={1000}
          mountOnEnter
          unmountOnExit
          container={containerNameRef.current}
        >
          <Box>
            <Fade in={showName} timeout={1000}>
              <Typography
                fontWeight={400}
                lineHeight="1.5"
                marginBottom="1rem"
                fontSize="16px"
                color="primary.dark"
                dangerouslySetInnerHTML={{ __html: name }}
              />
            </Fade>
          </Box>
        </Slide>
      </Box>
      {/* Product Price */}
      <ProductPrice
        availability={availability}
        price={price}
        sale={sale}
        strikeThrough={strikeThrough}
        strikeThroughPrice={strikeThroughPrice}
        financeAmount={financeAmount}
        promotionalPrice={promotionalPrice}
        promotionalMonths={promotionalMonths}
        monthly={numMonths}
        roomSavings={roomSavings}
        prequalifiedLink={prequalifiedLink}
        freeShippingMessage={freeShippingMessage}
        customFontSize={23}
      />
      <Stack spacing={2} marginTop="16px">
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            label={addToCartLabel}
            fullWidth
            disableElevation
            disableRipple
            onClick={handleAddToCart}
            data-testid={dataTestId || null}
          />
          <FAVORITE_BUTTON checked={isFavorited} handleClick={handleFavoriteClick} sku={product?.sku} />
        </Stack>
        {typeof window !== 'undefined' && window.safari !== undefined && (
          <ApplePayWrapper>
            <ApplePayButton
              data-testid="add-to-cart-button-apple"
              type="Buy"
              isExpress
              isPDP
              setOrderInfo={() => setOrderInfo('Apple Pay', 'selectedPaymentType')}
              product={product}
              price={price}
              warrantyEnabled={warrantyEnabled}
              componentPage="pdp"
              activeAddons={[]}
            />
          </ApplePayWrapper>
        )}
      </Stack>
    </Box>
  )
}
