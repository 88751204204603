import React, { useState, useEffect, useMemo } from 'react'
import { styled, Box } from '@mui/material'
import { productAvailability, sortByPriceDescending } from '@helpers/product'
import { fetchProductWarehouseAvailability } from '@services/product'
import { string, array, object } from 'prop-types'
import { getSimilarItems } from '@services/checkout'

import { getRegionZone } from '@helpers/geo-location'
import ProductTile from '../product/product-tile'
import { useProduct } from '../product/hooks'
import BaseSwiper from '../../@rtg2022/components/organisms/BaseSwiper/BaseSwiper'
import HeadingAndSeeAllLink from '../../@rtg2022/components/molecules/HeadingAndSeeAllLink/HeadingAndSeeAllLink'

const defaultComparisonFields = {
  colors: true,
  reviews: true,
  availability: true,
  price: true,
  addToCart: true,
  dimensions: true,
}

const comparisonFieldVariants = {
  default: {
    ...defaultComparisonFields,
  },
  'Sleeper Sofas': {
    ...defaultComparisonFields,
    productExtraLabel: true,
  },
  'Living Room Sets': {
    ...defaultComparisonFields,
    dimensions: false,
  },
  mattress: {
    brand: true,
    dimensions: true,
    technology: true,
    sleepPosition: true,
    reviews: true,
    availability: true,
    price: true,
  },
}

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  margin: '2rem 0 2rem 0 !important',
  padding: '20px 0',
  [theme.breakpoints.down('sm')]: {
    margin: '2rem -15px !important',
    padding: '40px 0 20px',
  },
}))

const StyledSwiperWrapper = styled('div')(({ theme }) => ({
  padding: '0 15px 5px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 0 15px',
  },
}))

const StyledHeaderWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: '0 15px',
  },
}))

function SimilarItemsV2({ product, heading = 'products', similarItems = [] }) {
  const { region, state, distribution_index } = getRegionZone()
  const [arrayItems, setArrayItems] = useState(similarItems)
  const { sku, isMattressOnly, subCategory } = useProduct({ product })

  useEffect(() => {
    getSimilarItems({
      sku,
      region,
    })
      .then(data => {
        let availableProduct = data.filter(p => productAvailability(p)) || null
        const skus = availableProduct.map(p => p.sku || p._id).join(',')
        fetchProductWarehouseAvailability(skus, distribution_index, state)
          .then(({ data: availabilitesData }) => {
            availableProduct = availableProduct.map(p => {
              const availabilityData = availabilitesData?.availabilities.find(item => item.sku === (p.sku || p._id))
              return {
                ...p,
                fetchedProductWarehouseAvailability: {
                  earliestAvailability: availabilityData?.availableOn,
                  availabilities: [availabilityData],
                },
              }
            })
          })
          .catch(() => setArrayItems([]))
          .finally(() => {
            setArrayItems(
              sortByPriceDescending(availableProduct).filter(item =>
                isMattressOnly
                  ? item?.category === 'mattress bedding' && item?.sub_category?.includes('Mattress Only')
                  : true,
              ),
            )
          })
      })
      .catch(() => setArrayItems([]))
  }, [sku, region, isMattressOnly, distribution_index, state])

  const slides = useMemo(() => {
    const slidesArray = [product, ...arrayItems]
    const comparisonFieldsKey = isMattressOnly ? 'mattress' : subCategory
    const comparisonFields = {
      fields: comparisonFieldVariants[comparisonFieldsKey] ?? comparisonFieldVariants.default,
      hasLabels: isMattressOnly,
    }
    const props = {
      comparisonFields,
    }
    return slidesArray.map((item, index) => (
      <ProductTile
        {...props}
        sku={item.sku || item._id}
        otherProductData={item}
        key={`similar_products_to_${item.sku || item._id}`}
        viewType="grid"
        index={index}
        showIncludes={false}
        source="similar-products-v2"
        route={item.route}
        currentSku={product?.sku}
        featureVariant="compare-similar-items"
      />
    ))
  }, [arrayItems, product, subCategory, isMattressOnly])

  const title = isMattressOnly ? 'Compare with Similar Items' : `Similar ${heading}`

  return (
    <>
      {arrayItems && arrayItems.length > 0 && (
        <StyledContainer className="grid-container">
          <div className="cell small-12">
            <StyledSwiperWrapper>
              <StyledHeaderWrapper>
                <HeadingAndSeeAllLink
                  title="Compare with Similar Items"
                  headingStyles={{ marginBottomMobile: '20px' }}
                />
              </StyledHeaderWrapper>
              <BaseSwiper
                slides={slides}
                spaceBetween={0}
                spaceBetweenMobile={0}
                gapToScrollbarDesktop="40px"
                gapToScrollbarMobile="10px"
                breakpoints={{
                  0: {
                    slidesPerView: 2.25,
                  },
                  680: {
                    slidesPerView: 2.6,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1250: {
                    slidesPerView: 4,
                  },
                  1440: {
                    slidesPerView: 5,
                  },
                }}
                fadeScrollbar
                dataTestId="slider:similar-items"
                slidesOffsetMobile={15}
                centered
              />
            </StyledSwiperWrapper>
          </div>
        </StyledContainer>
      )}
    </>
  )
}

SimilarItemsV2.propTypes = {
  product: object,
  heading: string,
  similarItems: array,
}

export default SimilarItemsV2
