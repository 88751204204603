import React from 'react'
import PropTypes from 'prop-types'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import { IconButton, Typography, Stack, useTheme } from '@mui/material'

function QuantityPicker({ quantity, quantityLimit = 10, onQuantityChange, className }) {
  const theme = useTheme()

  return (
    <Stack direction="row" alignItems="center" className={className}>
      <Typography color={theme.palette.primary.dark} fontWeight="bold" marginRight="20px">
        QUANTITY:
      </Typography>
      <IconButton
        aria-label="decrease"
        onClick={() => {
          addToDataLayer_nextgen('quantity_decrease')
          onQuantityChange(quantity - 1)
        }}
        disabled={quantity < 2}
      >
        <RemoveCircleOutlineOutlinedIcon fontSize="large" />
      </IconButton>
      <Typography
        color={theme.palette.primary.dark}
        fontWeight="bold"
        marginLeft="8px"
        marginRight="8px"
        fontSize="18px"
      >
        {quantity}
      </Typography>
      <IconButton
        aria-label="increase"
        onClick={() => {
          addToDataLayer_nextgen('quantity_increase')
          onQuantityChange(quantity + 1)
        }}
        disabled={quantity >= quantityLimit}
      >
        <AddCircleOutlineOutlinedIcon fontSize="large" />
      </IconButton>
    </Stack>
  )
}

QuantityPicker.propTypes = {
  quantity: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  quantityLimit: PropTypes.number,
}

export default QuantityPicker
